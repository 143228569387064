import { Injectable } from '@angular/core';
import { HttpEvent,  HttpInterceptor,  HttpHandler,  HttpRequest, HttpResponse } from '@angular/common/http'
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CONFIG } from './../config';


@Injectable()//{providedIn: 'root'}

export class NoAuthInterceptor  implements HttpInterceptor {

	constructor(private router: Router) {

	}

	// intercept response
	intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			tap(event => {}, error => {
				if (error.status === 401 && error.url.match(/token\-refresh/)){
					// console.log(error);
					localStorage.removeItem("LOGOPED_TOKEN");
					localStorage.removeItem("LOGOPED_TOKEN_REFRESH");
					CONFIG.token = "";
					this.router.navigateByUrl('/login');
				}
			})
		)
	};
}
