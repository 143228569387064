import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { DepartmentsService } from './../../services/departments';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields
} from './../../utils';

@Component({
	selector: 'form-room-add',
	templateUrl: './template.html',
	providers: [ DepartmentsService ]
})
export class FormRoomAdd extends AbstractForm {

	config: any = {
		title: ["", "r"],
		department: ["", "r"],
		type: ["", "r"]
	}
	departments: any[] = [];

	@Input() hideButton: boolean;

	constructor(private departmentsService: DepartmentsService){
		super();

		this.departmentsService.getDepartments().subscribe((res: any)=> {
			this.departments = res.map((d: any)=> {
				return toCamelKeys(d);
			}).sort((a: any, b: any)=> {
				return new Date(b.changed.createdOn).getTime() - new Date(a.changed.createdOn).getTime();
			});
		});
	}

	submit(){
		validateAllFields(this.form);
		if (this.form.valid){
			this.onSubmit.emit(Object.assign(this.form.value, {
				files: (this.files || [null])[0],
				formIsEditing: this.mode === AbstractFormModes.Edit
			}));
			this.afterSubmit();
			this.reset();
		}
	}

	openWindow(id: string | number){
		this.id = id;
		this.modal.title = `${this.id ? "Редактировать" : "Добавить"} комнату`;
		this.mode = this.id ? AbstractFormModes.Edit : AbstractFormModes.Default;
		this.modal.show();
		setTimeout(()=> {
			this.form.controls['department'].setValue('');
			this.firstFocus.nativeElement.focus();
		});
	}

	openWindowWithDepartmentSelected(departmentId: number){
		this.openWindow(departmentId);
	}

}