import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AbstractForm, AbstractFormModes } from './../forms/abstract-form';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, createForm, ifSuccess, ifError
} from './../utils';
import { Calendar, Schedule } from './../components';
import { SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService } from './../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Modal } from './../components/modal';
import { CONFIG } from './../config';

let z = (v)=> { return v < 10 ? "0"+v : v };

@Component({
	selector: 'schedule-teacher-page',
	templateUrl: './schedule-teacher-page.html',
	providers: [ SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService ]
})
export class ScheduleTeacherPage {

	filter: FormGroup;
	filterConfig: any = {
		dates: ["", "r"],
		type: ["", "r"],
		hourFrom: ["", "r"],
		hourTo: ["", "r"],
		clients: ["", "r"],
		teachers: ["", "r"]
	}
	ifError: Function;
	ifSuccess: Function;

	teachers: any[] = [];
	teachersKeys: any = {};
	teachersFiltered: any[] = [];

	occupations: any[] = [];
	occupationsKeys: any = {};
	teacherOccupations: any = {};

	teachersOptions: any[] = [];
	durationOptions: any[] = [];

	rooms: any[] = [];
	roomsKeys: any = {};
	roomsOptions: any[] = [];

	types: any[] = [];
	typesKeys: any = {};
	subtypes: any[] = [];

	clients: any[] = [];
	clientsOptions: any[] = [];
	clientsKeys: any = {};

	activities: any[] = [];

	type: string;

	dates: Date[] = [];

	lastFilterValue: any = null;

	startTime: number = 8;
	endTime: number = 21;
	hours: number[] = [];
	width: number = 50;

	smallRange: number = 15;
	mediumRange: number = 60;
	largeRange: number = 90;

	activities_keys: string[] = ["meeting", "teacher_council", "dinner", "sick", "business_trip", "vacation", "day_off"];
	activities_names: any = {
		"meeting": "Совещание",
		"teacher_council": "Совещание",
		"dinner": "Обед",
		"sick": "Больничный",
		"business_trip": "Командровка",
		"vacation": "Отпуск",
		"day_off": "Выходной"
	};
	activities_icons: any = {
		"meeting": "comment",
		"teacher_council": "comment",
		"dinner": "timer",
		"sick": "user-card",
		"business_trip": "trip",
		"vacation": "vacation",
		"day_off": "calendar"
	};

	@ViewChild('calendar') calendar: Calendar;
	@ViewChild("addGerenalActivityModal") addGerenalActivityModal: Modal;
	@ViewChild("formActivityAdd") formActivityAdd: AbstractForm;
	// @ViewChild('schedule') schedule: Schedule;

	constructor(
		private settingsService: SettingsService,
		private usersService: UsersService,
		private departmentsService: DepartmentsService,
		private occupationsService: OccupationsService,
		private notificatorService: NotificatorService,
		private route: ActivatedRoute
	){

		// this.type = route.data['value'].type;
		this.type = "week";

		for (let i = this.startTime; i <= this.endTime; i++) this.hours.push(i);

		/*
			fetch activities
		*/
		this.occupationsService.getActivities().subscribe((res: any[])=> {
			this.activities = res;
			console.log("activities", this.activities);

			// fetch users and process
			this.usersService.getUsers().subscribe((res: any[])=> {
				this.teachers = res.filter(u => u.role === "TEACHER").sort((a, b)=> a.pk - b.pk);
				this.teachers.map((t: any, i: number)=> {
					let teacherActivities: any[] = this.activities.filter((a)=> a.users.indexOf(t.pk) >= 0);
					t.working_start = 830 + i * 100;
					t.working_end = 2000 - i * 100;
					// t.day_off = this.activities.
					// t.weekend = Math.random() > 0.66 ? [5,6] : Math.random() > 0.33 ? [Math.floor(Math.random() * 6)] : [];
				});
				this.teachersOptions = this.teachers.map((t: any)=> {
					this.teachersKeys[t.pk] = t;
					this.teacherOccupations[t.pk] = {
						teacher: t,
						days: [0,1,2,3,4,5,6].map((i)=> {
							return {day: this.dates[i], occupations: [], freeTime: []};
						})
					};
					return { value: t.pk, label: this.getName(t), image: t.image || 'assets/image.png' }
				});
				// console.log(this.teacherOccupations);
				this.filterTeachers();
				this.clients = res.filter(u => u.role === "CLIENT");
				this.clientsOptions = this.clients.map((c: any)=> {
					this.clientsKeys[c.pk] = c;
					return { value: c.pk, label: this.getName(c), image: c.image || 'assets/image.png' }
				});
				// console.log('teachers\n', this.teachers);
				// console.log('clients\n', this.clients);
			});
		});



		this.settingsService.getOccupationTypes().subscribe((res: any[])=> {
			// console.log('types', res);
			this.types = res;
			res.map(t => this.typesKeys[t.pk] = t);
		});

		this.departmentsService.getRooms().subscribe((res: any[])=> {
			// console.log('rooms', res);
			this.rooms = res;
			this.roomsOptions = res.map((r: any)=> {
				this.roomsKeys[r.pk] = r;
				return { value: r.pk, label: r.title, image: r.image || 'assets/image.png' }
			});
			// console.log('rooms\n', this.rooms);
		});

		// this.occupationsService.getOccupations().subscribe((res: any[])=> {
		// 	// console.log('occupations', res);
		// 	this.occupations = this.occupationsService.removeDoubleOccupations(
		// 		this.occupationsService.unwrapOccupations(res)
		// 	);
		// 	this.occupations.map(o => this.occupations[o.pk] = o);
		// 	console.log('occupations', this.occupations);
		// });
	}

	ngOnInit(){
		// this.schedule.dates = this.dates;
		this.filter = createForm(this.filterConfig);
		this.ifError = ifError.bind(this.filter);
		this.ifSuccess = ifSuccess.bind(this.filter);
		this.filter.addControl('realRooms', new FormControl(true));
		this.filter.addControl('virtualRooms', new FormControl(true));
		this.filter.controls['type'].setValue('0');
		this.filter.controls['hourFrom'].setValue('08:00');
		this.filter.controls['hourTo'].setValue('21:00');

		this.calendar.setDay(new Date().getDayStart());

		this.filter.valueChanges.subscribe((val: any) => {
			// console.log(val);
			window.waitForFinalEvent(()=> {
				this.fetchOccupations();
			}, 1000, 'filter_occupations');
		});

		this.setDates();

	}

	setDates(){
		this.dates = [];
		if (this.type === "day"){
			this.dates.push(this.calendar.selection.start.clone());
			// this.schedule.dates = this.dates;
		}
		if (this.type === "week"){
			let startOfWeek = this.calendar.selection.start.clone().getWeekStart();
			for (let i = 0; i < 7; i++){
				this.dates.push(startOfWeek.addDays(i));
			}
			// this.schedule.dates = this.dates;
			let weekStart = this.calendar.selection.start.clone().getWeekStart(),
					weekEnd = this.calendar.selection.start.clone().getWeekEnd();
			this.calendar.selection.start = null;
			this.calendar.selection.end = null;
			this.calendar.multiple = true;
			this.calendar.setDay(weekStart);
			this.calendar.setDay(weekEnd);
		}
		if (this.type === "month"){
			let startOfMonth = this.calendar.selection.start.clone().getMonthStart(),
					monthLength = startOfMonth.getMonthLength();
			for (let i = 0; i < monthLength; i++){
				this.dates.push(startOfMonth.addDays(i));
			}
			// this.schedule.dates = this.dates;
			let monthStart = this.calendar.selection.start.clone().getMonthStart(),
					monthEnd = this.calendar.selection.start.clone().getMonthEnd();
			this.calendar.selection.start = null;
			this.calendar.selection.end = null;
			this.calendar.multiple = true;
			this.calendar.setDay(monthStart);
			this.calendar.setDay(monthEnd);
		}
	}

	getName(teacher: any): string {
		return this.usersService.getUserName(teacher);
	}
	getPhone(teacher: any): string {
		return this.usersService.getUserPhone(teacher);
	}
	getEmail(teacher: any): string {
		return this.usersService.getUserEmail(teacher);
	}
	getAddress(teacher: any): string {
		return this.usersService.getUserAddress(teacher);
	}
	getContacts(teacher: any): string{
		return this.usersService.getUserContacts(teacher);
	}

	onFilterReset(){
		// console.log('reset');
	}


	getCalendarTitle(){
		if (!this.calendar.selection.start || (this.calendar.multiple && !this.calendar.selection.end)){
			return "";
		}
		let title: string = "",
				start = this.calendar.selection.start.clone().parseDate(this.calendar.multiple ? 'DD.MM' : 'DD.MM.YYYY'),
				end = (this.calendar.selection.end || new Date()).clone().parseDate(this.calendar.multiple ? 'DD.MM' : 'DD.MM.YYYY');
		if (this.type === "day"){
			title = `${start}`;
		} else if (this.type === "week" || this.type === "month"){
			title = `${start} &mdash; ${end}`;
		}
		return title;
	}

	onDayChange(event?: any){
		window.waitForFinalEvent(()=> {
			// this.fetchOccupations();
			let dates: string = '';
			if (this.calendar.selection.start){
				dates += this.calendar.selection.start.clone().parseDate('YYYY-MM-DD');
			}
			if (this.calendar.selection.end){
				dates += ','+this.calendar.selection.end.clone().parseDate('YYYY-MM-DD');
			}
			this.filter.controls['dates'].setValue(dates);
			this.setDates();

			this.fetchOccupations();
			// this.schedule.fetchOccupations(this.type);
		}, 1000, 'calendar_occupations');
	}

	fetchOccupations(){
		// if (this.lastFilterValue === this.filter.value) return;
		this.filterTeachers();
		// console.log(this.filter.value);
		this.lastFilterValue = this.filter.value;
		this.occupationsService.getOccupationsForWeek(this.filter.value).subscribe((res: any[])=> {
			// console.log(res);
			this.occupations = this.occupationsService.removeDoubleOccupations(
				this.occupationsService.unwrapOccupations(res)
			);
			this.occupationsKeys = {};
			this.occupations.map(o => this.occupationsKeys[o.pk] = o);
			this.fillTeachersSchedule();
			// console.log('occupations\n', this.occupations);
		});
		// this.schedule.filter = this.filter.value;

		// this.notificatorService.push({
		// 	title: 'Фильтр занятий',
		// 	text: `Пошел запрос на календарик ${new Date().toLocaleString()}`
		// });
	}

	resetFilters(){
		// this.schedule.filter = this.filter.value;
	}

	prevSchedule(){
		if (this.type === "day") this.calendar.prevDay('start');
		if (this.type === "week") this.calendar.prevWeek();
		if (this.type === "month") this.calendar.prevMonth();
	}

	nextSchedule(){
		if (this.type === "day") this.calendar.nextDay('start');
		if (this.type === "week") this.calendar.nextWeek();
		if (this.type === "month") this.calendar.nextMonth();
	}

	getDayName(index: number){
		return CONFIG.dayNames[index];
	}

	filterTeachers(){
		if (!this.filter.value.teachers) {
			this.teachersFiltered = this.teachers;
			return;
		}
		let indexes: any[] = this.filter.value.teachers.split(',');
		this.teachersFiltered = this.teachers.filter((t)=> {
			return indexes.findIndex(i => i == t.pk) >= 0 ? true : false;
		});
	}

	fillTeachersSchedule(){

		/*
			activities
		*/
		this.teachersFiltered.map((teacher: any, index: number)=> {
			this.resetTeacher(teacher);

			// this.computeActivities(teacher);
			for (let i = 0; i < 7; i++){
				this.activities_keys.map((_a: any)=> {
					let teacherDay = this.teacherOccupations[teacher.pk].days[i],
							teacherDate: Date = teacherDay.day;
					teacherDay[_a] = this.activities.filter((a: any)=> {
						let dateStart: Date = new Date(a.date_start),
								dateEnd: Date = new Date(a.date_end);
						// && (teacherDate.isGreaterEqual(dateStart.getDayStart()) && teacherDate.isLessEqual(dateEnd.getDayEnd()))
						if (
							a.users.indexOf(teacher.pk) >= 0 &&
							(teacherDate.isGreaterEqual(dateStart.getDayStart()) && teacherDate.isLessEqual(dateEnd.getDayEnd())) &&
							a.type === _a
						) {
							// console.log(_a, teacherDate.toLocaleDateString(), dateStart.toLocaleDateString(), dateEnd.toLocaleDateString());
							return true;
						} else {
							return false;
						}
					}).map((a: any)=> {
						let dateTime: any = this.computeActivityDate(a, teacherDate, teacher);
						return this.createAction(_a, dateTime.date, dateTime.from_hour, dateTime.to_hour, a);
					});
				});
			}
		});


		this.teachersFiltered.map((teacher: any, index: number)=> {
			// reset
			// this.teacherOccupations[teacher.pk].days = [0,1,2,3,4,5,6].map((d, i)=> {
			// 	return {day: this.dates[i], occupations: [], freeTime: []};
			// });
			// console.log(this.teacherOccupations[teacher.pk].days);

			// console.log(`teacker activities [id:${t.pk}]`, teacherActivities);

			// set new ones
			this.occupations.map((occupation: any, occupationIndex: number)=> {
				// loop through days
				for (let i = 0; i < 7; i++){
					let day = this.teacherOccupations[teacher.pk].days[i].day;
					// console.log("o", occupation, new Date(occupation.date).isSameDay(day));
					if (new Date(occupation.date).isSameDay(day) && occupation.performers.indexOf(teacher.pk) >= 0){
						this.teacherOccupations[teacher.pk].days[i].occupations.push(occupation);
					}
					this.teacherOccupations[teacher.pk].days[i].occupations = this.teacherOccupations[teacher.pk].days[i].occupations.sort((a, b)=> a.from_hour - b.from_hour );
				}
				// this.teacherOccupations[teacher.pk].occupations.push(0);
			});

			/*
				calculate free time
			*/
			// each DAY
			for (let i = 0; i < 7; i++){
				let day = this.teacherOccupations[teacher.pk].days[i].day;
				let date = `${day.getUTCFullYear()}-${z(day.getUTCMonth() + 1)}-${z(day.getUTCDate())}`;
				let freeTime: any = null;
				let occupations: any[] = this.teacherOccupations[teacher.pk].days[i].occupations;
				let freeTimes: any[] = this.teacherOccupations[teacher.pk].days[i].freeTime;
				let notWorkingTimes: any[] = this.teacherOccupations[teacher.pk].days[i].notWorkingTime;

				let allPossibleActions: any[] = [].concat(occupations);

				this.activities_keys.map((k: any)=> {
					allPossibleActions = allPossibleActions.concat(this.teacherOccupations[teacher.pk].days[i][k]);
				});

				// each OCCUPATION
				allPossibleActions.map((o: any, index: number)=> {
					let _o = allPossibleActions[index+1]; // next occupation
					let o_ = allPossibleActions[index-1]; // prev occupation
					// create new
					let freeTime: any = {date: date};
					if (index === 0){
						// if free time before first lesson
						if (o.from_hour > this.startTime){
							freeTime.from_hour = Math.max(this.startTime*100, teacher.working_start);
							freeTime.to_hour = o.from_hour;
						// if it's only one, then we should set end as end of day
						} else {
							freeTime.from_hour = o.to_hour;
							freeTime.to_hour = Math.min((this.endTime+1)*100, teacher.working_end);
						}

					// middle of calendar
					} else {
						// check overlaping
						if (o_.to_hour < o.from_hour){
							freeTime.from_hour = o_.to_hour;
							freeTime.to_hour = o.from_hour;
						}
					}

					if (freeTime.from_hour && freeTime.to_hour){
						if (Math.abs(freeTime.from_hour - freeTime.to_hour) >= 15){
							freeTime.duration = this.occupationsService.getLessonDuration(freeTime);
							freeTimes.push(freeTime);
						}
					}
					freeTime = null;

					// if it's only one day - then add free time till the end of day
					if (!_o){
						let freeTime: any = {date: date};
						freeTime.from_hour = o.to_hour;
						freeTime.to_hour = Math.min((this.endTime+1)*100, teacher.working_end);
						freeTime.duration = this.occupationsService.getLessonDuration(freeTime);
						if (Math.abs(freeTime.from_hour - freeTime.to_hour) >= 15){
							freeTimes.push(freeTime);
							freeTime = null;
						}
					}
				});
				// if all day is empty
				if (!allPossibleActions.length){
					let freeTime: any = {date: date};
					freeTime.from_hour = Math.max(this.startTime*100, teacher.working_start);
					freeTime.to_hour = Math.min((this.endTime+1)*100, teacher.working_end);
					freeTime.duration = this.occupationsService.getLessonDuration(freeTime);
					if (Math.abs(freeTime.from_hour - freeTime.to_hour) >= 15){
						freeTimes.push(freeTime);
						freeTime = null;
					}
				}
			}

			/*
				calculate when teacher is actually working
				there is no guaranty that he's starting to work at 8:00 and ending at 23:00
			*/
			for (let i = 0; i < 7; i++){
				let day = this.teacherOccupations[teacher.pk].days[i].day;
				let date = `${day.getUTCFullYear()}-${z(day.getUTCMonth() + 1)}-${z(day.getUTCDate())}`;
				let notWorkingTimes: any[] = this.teacherOccupations[teacher.pk].days[i].notWorkingTime;
				// before day start
				let action = this.createAction('not-working-time', date,
					Math.min(this.startTime*100, teacher.working_start),
					Math.max(this.startTime*100, teacher.working_start)
				);
				notWorkingTimes.push(action);
				// after day end
				action = this.createAction('not-working-time', date,
					Math.min((this.endTime+1)*100, teacher.working_end),
					Math.max((this.endTime+1)*100, teacher.working_end)
				);
				notWorkingTimes.push(action);
			}
			console.log(this.teacherOccupations[teacher.pk]);

		});
	}

	private resetTeacher(teacher: any){
		for (let i = 0; i < 7; i++){
			this.teacherOccupations[teacher.pk].days[i].day = this.dates[i];
			this.teacherOccupations[teacher.pk].days[i].occupations = [];
			this.teacherOccupations[teacher.pk].days[i].freeTime = [];
			this.teacherOccupations[teacher.pk].days[i].notWorkingTime = [];
			// activities
			// this.teacherOccupations[teacher.pk].days[i].teacher_council = [];
			this.activities_keys.map(_a => this.teacherOccupations[teacher.pk].days[i][_a] = []);
		}
	}

	private computeActivities(teacher: any){

	}

	getLessonOffset(lesson: any): number {
		let startHour: number = Math.floor(lesson.from_hour / 100) - this.startTime;
		return (startHour * (this.width * 4) + (lesson.from_hour % 100) / 15 * this.width) + 1;
	}

	getLessonWidth(lesson: any): number {
		let hours: number = Math.floor((lesson.to_hour - lesson.from_hour) / 100);
		let minutes: number = Math.floor((lesson.duration - hours*60) / 15);
		return hours * this.width * 4 + minutes * this.width - 2;
	}

	getTimeString(lesson: any){
		return this.occupationsService.getLessonTimeString(lesson);
	}

	isWorkingDay(teacher, dayIndex): boolean {
		if (!teacher.weekend || (teacher.weekend && !teacher.weekend.length)) return true;
		// console.log(teacher.days[dayIndex]);
		// if day belongs to holidays or something
		for (let i = 0; i < teacher.weekend.length; i++){
			if (teacher.weekend[i] === dayIndex){
				return false;
			}
		}
		return true;
	}

	private computeActivityDate(activity: any, currentDay: Date, teacher: any): {date: string, from_hour: number, to_hour: number} {
		let startDay: Date = new Date(activity.date_start),
				endDay: Date = new Date(activity.date_end),
				startTime: number = parseInt(startDay.parseDate("h:m").replace(/\:/gim, '')),
				endTime: number = parseInt(endDay.parseDate("h:m").replace(/\:/gim, ''));

		let resultActivity: any = {
			date: `${currentDay.getUTCFullYear()}-${z(currentDay.getUTCMonth() + 1)}-${z(currentDay.getUTCDate())}`,
			from_hour: Math.max(startTime, Math.max(this.startTime*100, teacher.working_start)),
			to_hour: Math.min(endTime, Math.min(this.endTime*100, teacher.working_end))
		};

		// console.log(activity.type, startDay, currentDay);
		if (startDay.isLess(currentDay.getDayStart())){
			resultActivity.from_hour = Math.max(this.startTime*100, teacher.working_start);
		}
		if (endDay.isGreater(currentDay.getDayEnd())){
			resultActivity.to_hour = Math.min(this.endTime*100, teacher.working_end);
		}

		return resultActivity;
	}

	private createAction(key: string, date: any, from_hour: number, to_hour: number, data?: any){
		let action: any = {
			date: date,
			from_hour: from_hour,
			to_hour: to_hour,
			data: data
		};
		action.duration = this.occupationsService.getLessonDuration(action);
		return action;
	}

	createActivity(data: any) {
		if (this.addGerenalActivityModal) this.addGerenalActivityModal.hide();
		this.occupationsService.createActivity(data).subscribe((res)=> {
			this.notificatorService.push({
				text: "Активность добавлена"
			});
			this.activities.push(res);
			this.fetchOccupations();
		});
	}

	updateActivity(data: any){
		if (this.addGerenalActivityModal) this.addGerenalActivityModal.hide();
		this.occupationsService.updateActivity(data, (this.formActivityAdd.id as string)).subscribe((res)=> {
			this.notificatorService.push({
				text: "Активность сохранена"
			});
			this.activities.push(res);
			this.fetchOccupations();
		});
	}

	getFlatActivitiesArray(day: any): any[] {
		let result: any[] = [];
		for (let i = 0; i < this.activities_keys.length; i++){
			let key = this.activities_keys[i];
			if (day[key]){
				for (let j = 0; j < day[key].length; j++){
					result.push(day[key][j]);
				}
			}
		}
		return result;
	}

	getActivityDates(a: any): string {
		return `${new Date(a.data.date_start).parseDate("DD.MM")} - ${new Date(a.data.date_end).parseDate("DD.MM")}`;
	}

	editActivity(teacher: any, day: any, dayIndex: number, activity: any){
		// console.log(activity);
		this.addGerenalActivityModal.show();
		this.formActivityAdd.mode = AbstractFormModes.Edit;
		this.formActivityAdd.patch(activity);
		this.formActivityAdd.id = activity.data.url;
	}

	deleteActivity(teacher: any, day: any, dayIndex: number, activity: any){
		if (!activity) return;
		this.occupationsService.deleteActivity(activity.data).subscribe((res: any)=> {
			let activityIndex: number = this.activities.findIndex(a => a.url === activity.data.url);
			// console.log(this.activities, activity, activityIndex);
			this.activities.splice(activityIndex, 1);
			this.notificatorService.push({
				text: "Активность удалена"
			});
			this.fetchOccupations();
			console.log(res);
		});
	}

}
