<div class="container-fluid">

	<form [formGroup]="filter" (onreset)="onFilterReset()">
		<div class="row">
			<div class="col-xs-3">
				<input type="hidden" formControlName="dates">
				<calendar class="small" [class.readonly]="type !== 'day'" #calendar (onchange)="onDayChange($event)" [months]="4" [showDay]="true"></calendar>
			</div>
			<div class="col-xs-9">
				<div class="card">
					<div class="row collapsed-row">
						<div class="col-xs-6">
							<div class="h3-title collapsed">Фильтр занятий</div>
						</div>
						<div class="col-xs-6 align-right">
							<!-- <button type="button" class="btn btn-transparent" (click)="resetFilters()">Сбросить</button> -->
						</div>
					</div>
					<hr class="light">
					<div class="row paddless-row bottom-xs">
						<div class="col-xs-2">
							<div class="form-group collapsed">
								<label for="filterOccupationType">Тип занятия</label>
								<i class="icon icon-drop-down input-icon"></i>
								<select id="filterOccupationType" class="input" formControlName="type">
									<option value="0" selected>Все</option>
									<option *ngFor="let type of types" [value]="type.pk">{{type.title}}</option>
								</select>
							</div>
						</div>
						<div class="col-xs-1">
							<div class="form-group collapsed">
								<label for="filterOccupationTimeFrom">Время (от)</label>
								<input type="text" id="filterOccupationTimeFrom" class="input paddless" maxlength="5" #hoursFrom formControlName="hourFrom" readonly value="08:00">
								<timepicker [node]="hoursFrom" [control]="filter.controls['hourFrom']"></timepicker>
							</div>
						</div>
						<div class="col-xs-1">
							<div class="form-group collapsed">
								<label for="filterOccupationTimeTo">Время (до)</label>
								<input type="text" id="filterOccupationTimeTo" class="input paddless" maxlength="5" #hoursTo formControlName="hourTo" readonly value="21:00">
								<timepicker [node]="hoursTo" [control]="filter.controls['hourTo']"></timepicker>
							</div>
						</div>
						<div class="col-xs-4">
							<div class="form-group collapsed">
								<label>Клиенты</label>
								<input type="hidden" formControlName="clients">
								<tags-select [options]="clientsOptions" [output]="filter.controls['clients']"></tags-select>
							</div>
						</div>
						<div class="col-xs-4">
							<div class="form-group collapsed">
								<label>Преподаватели</label>
								<input type="hidden" formControlName="teachers">
								<tags-select [options]="teachersOptions" [output]="filter.controls['teachers']"></tags-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="row middle-xs offset-bottom offset-top">
		<div class="col-xs-2">
			<div class="h2-title collapsed">Расписание</div>
		</div>
		<div class="col-xs-2">
			<div class="row collapsed-row middle-xs">
				<div class="col-xs-2">
					<button type="button" class="btn small btn-transparent btn-round" (click)="prevSchedule()">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="col-xs-8 align-center schedule-title" [innerHTML]="getCalendarTitle()"></div>
				<div class="col-xs-2 align-right">
					<button type="button" class="btn small btn-transparent btn-round" (click)="nextSchedule()">
						<i class="icon icon-arrow-right"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6">
			<ul class="clear action-type-list">
				<li class="item">
					<div class="teacher-action-type not-working">
						<span class="text">Нерабочее</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type occupation">
						<span class="text">Занятие</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type free">
						<span class="text">Свободное</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type meeting">
						<span class="text">Совещение</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type dinner">
						<span class="text">Обед</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type healthcare">
						<span class="text">Больничный</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type trip">
						<span class="text">Командировка</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type vacation">
						<span class="text">Отпуск</span>
					</div>
				</li>
				<li class="item">
					<div class="teacher-action-type weekend">
						<span class="text">Выходной</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="col-xs-2 align-right">
			<button type="button" class="btn btn-success" (click)="formActivityAdd.reset(); addGerenalActivityModal.show()">
				Добавить активность
				<i class="icon icon-plus"></i>
			</button>
		</div>
	</div>


	<div class="card" style="padding: 0">
		<div class="preloader" [class.active]="!teachersFiltered.length"><span></span></div>
		<div class="teachers-schedule" *ngIf="teachersFiltered.length">
			<div class="teachers-list">
				<ul class="clear teachers">
					<li class="teacher" *ngFor="let teacher of teachersFiltered; let teacherIndex = i">
						<figure class="image-cover">
							<img [src]="teacher.image || 'assets/image.png'">
						</figure>
						<div class="name">{{getName(teacher)}}</div>
						<tabs [anchorsIcons]="['phone','mail','building','headphones']" #tabs></tabs>
						<div class="info" *ngIf="tabs.index === 0">{{getPhone(teacher)}}</div>
						<div class="info" *ngIf="tabs.index === 1">{{getEmail(teacher)}}</div>
						<div class="info" *ngIf="tabs.index === 2">{{getAddress(teacher)}}</div>
						<div class="info" *ngIf="tabs.index === 3">{{getContacts(teacher)}}</div>
						<ul class="clear days">
							<li *ngFor="let date of dates; let i = index"
								[class.today]="date.isToday()"
								[class.holiday]="!isWorkingDay(teacher, i)"
							>
								{{getDayName(i)}}<br>
								{{date.parseDate('DD.MM')}}<br>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="schedule-list" *ngIf="teachersFiltered.length">
				<ul class="clear time">
					<li *ngFor="let h of hours">
						<div class="hour">{{h}}:00</div>
						<div class="minutes">
							<span *ngFor="let m of ['00','15','30','45']">{{m}}</span>
						</div>
					</li>
				</ul>
				<ul class="clear day-list" *ngFor="let teacher of teachersFiltered; let teacherIndex = index" [style.width]="(hours.length * 200)+'px'">
					<li *ngFor="let day of teacherOccupations[teacher.pk].days; let dayIndex = index"
						[class.today]="day.day.isToday()"
						[class.holiday]="!isWorkingDay(teacher, dayIndex)"
					>
						<div *ngIf="isWorkingDay(teacher, dayIndex)" class="working-day">

							<!-- free time -->
							<div *ngFor="let f of day.freeTime; let fIndex = index" class="free-time"
								[style.left]="getLessonOffset(f)+'px'"
								[style.width]="getLessonWidth(f)+'px'"
								[class.small]="f.duration <= mediumRange"
								[class.large]="f.duration > mediumRange"
								[class.extralarge]="f.duration > largeRange"
							>
								<div>
									<span *ngIf="f.duration > mediumRange">Свободное время</span> <br *ngIf="f.duration > mediumRange && f.duration <= largeRange">
									<span *ngIf="f.duration > largeRange">&nbsp;&nbsp;&nbsp;</span>
									{{getTimeString(f)}}
									<span *ngIf="f.duration > largeRange" class="timeline"></span>
								</div>
								<i class="icon icon-clock"></i>
								<i class="icon icon-plus"></i>
							</div>

							<!-- not working times -->
							<div *ngFor="let t of day.notWorkingTime; let tIndex = index" class="not-working-time"
								[style.left]="getLessonOffset(t)+'px'"
								[style.width]="getLessonWidth(t)+'px'"
								[class.small]="t.duration <= mediumRange"
								[class.large]="t.duration > mediumRange"
								[class.extralarge]="t.duration > largeRange"
							>
								<div>
									<span *ngIf="t.duration > mediumRange">Нерабочее время</span> <br *ngIf="t.duration > mediumRange && t.duration <= largeRange">
									<span *ngIf="t.duration > largeRange">&nbsp;&nbsp;&nbsp;</span>
									{{getTimeString(t)}}
									<span *ngIf="t.duration > largeRange" class="timeline"></span>
								</div>
							</div>

							<!-- activities -->
							<!-- захуярить одним махом -->
							<div *ngFor="let a of getFlatActivitiesArray(day)" class="teacher-activity {{a.data.type}}"
								[style.left]="getLessonOffset(a)+'px'"
								[style.width]="getLessonWidth(a)+'px'"
							>
								<i class="icon icon-{{activities_icons[a.data.type]}} indicator"></i>
								<div>
									{{activities_names[a.data.type]}}&nbsp;&nbsp;&nbsp;&nbsp;{{getActivityDates(a)}}<br>
									{{getTimeString(a)}}
									<div class="controls">
										<button type="button" class="btn btn-round btn-transparent" (click)="editActivity(teacher, day, dayIndex, a)">
											<i class="icon icon-pencil"></i>
										</button>
										<button type="button" class="btn btn-round btn-transparent" (click)="deleteActivity(teacher, day, dayIndex, a)">
											<i class="icon icon-trash"></i>
										</button>
									</div>
								</div>
							</div>
							<!-- закончил захуяривать -->

							<!-- occupation -->
							<div *ngFor="let o of day.occupations; let occupationIndex = index" class="occupation"
								[style.left]="getLessonOffset(o)+'px'"
								[style.width]="getLessonWidth(o)+'px'"
							>
								<div class="room">[{{o.pk}}] {{roomsKeys[o.room].title}}</div>
								<div class="title">{{o.title}}</div>
								<div class="time">{{getTimeString(o)}}</div>
							</div>
						</div>
						<div *ngIf="!isWorkingDay(teacher, dayIndex)" class="day-off">
							<div class="inner">
								<span>Выходной день</span>
							</div>
							<i class="icon icon-clock"></i>
							<i class="icon icon-plus"></i>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>


<modal #addGerenalActivityModal [title]="'Добавить новую активность для сотрудников'" [large]="true">
	<form-activity-add (onSubmit)="formActivityAdd.mode === 'edit' ? updateActivity($event) : createActivity($event)" #formActivityAdd></form-activity-add>
</modal>