<div class="container-fluid">
	<div class="row">
		<a routerLink="/login" routerLinkActive="active">login</a>
		<div class="col-xs-8">
			<!-- <calendar [showDay]="true"></calendar> -->
			<br>
		</div>
		<div class="col-xs-8">
			<!-- <calendar [multiple]="true" [months]="12" [showDay]="true"></calendar> -->
		</div>
	</div>
	<div class="row">
		<div class="col-xs-4">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
		<div class="col-xs-4">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
	</div>
	<br>

	<div class="row">
		<div class="col-xs-5">
			{{tags.getSelectedLength()}}
			<tags-select [options]="options" (onchange)="showTags($event)" #tags></tags-select>
		</div>
	</div>



	<div class="row">
		<div class="col-xs-2">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
		<div class="col-xs-2">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
		<div class="col-xs-2">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
	</div>
	<br>

	<!-- chart -->
	<div class="row">
		<div class="col-xs-4">
			<svg-chart #chart></svg-chart>
		</div>
	</div>
	<br>
	<!-- / chart -->

	<div class="row">
		<div class="col-xs-4">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
		<div class="col-xs-4">
			Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet ipsam nihil labore dolorem et quos maiores, numquam quaerat iusto autem exercitationem illum quas natus. Excepturi totam iusto placeat nisi, perspiciatis exercitationem accusamus doloribus illo harum dolor sit inventore. Reiciendis corporis, suscipit optio nostrum itaque asperiores omnis, magni illum maxime doloremque repudiandae ullam! Ipsum, soluta commodi ab aspernatur nisi modi nesciunt, consequuntur omnis, architecto magnam corporis facere, ratione ut aliquid culpa. Ab ut velit architecto minus veritatis tempora facilis accusantium nam aliquam a, culpa, laboriosam sapiente quae quam rem eius aliquid. Sapiente delectus ad neque, inventore officia omnis odit deserunt modi!
		</div>
	</div>
</div>