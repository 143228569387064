export * from './departments-detail';
export * from './departments';
export * from './login';
export * from './main';
export * from './occupations-cost';
export * from './occupations';
export * from './password-change';
export * from './password-reset';
export * from './registration';
export * from './rooms';
export * from './schedule';
export * from './schedule-teacher';
export * from './users-detail';
export * from './users-balance';
export * from './users-info';
export * from './users-lessons';
export * from './users-reviews';
export * from './users-list';
export * from './quiz-constructor';
export * from './user-survey';