<div class="verticaly-middle">
	<div class="inner">
		<form class="static-form small" [formGroup]="form">
			<div class="h1-title align-center">Сброс пароля</div>
			<div class="form-group"
				*ngFor="let field of formFields"
				[class.error]="ifError(field)"
				[class.success]="ifSuccess(field)"
			>
				<input [attr.type]="fieldType(field)" class="input" [formControlName]="field" [placeholder]="formConfig[field][0]">
				<i class="icon icon-cross" data-validation></i>
				<i class="icon icon-checked" data-validation></i>
				<div class="tooltip" [class.disabled]="ifSuccess(field)" [innerHTML]="createTooltip(formConfig[field]).parseBytecodes()"></div>
			</div>
			<button type="submit" (click)="submit()" class="btn btn-primary btn-block">Выслать</button>
		</form>
	</div>
</div>