import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectOption } from './../interfaces/selectOption';
import { Notification, NotificationType } from './../interfaces/notification';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	parseJwt
} from './../utils';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from './../services/users';
import { CONFIG } from './../config';
import { NotificatorService } from './../services/notificator';

@Component({
	selector: 'login-page',
	templateUrl: './login-page.html',
	providers: [ UsersService, NotificatorService ]
})
export class LoginPage {

	form: FormGroup;
	formConfig: any = {
		email: ["Email", "r", "e", "m|5", "M|30"],
		password: ["Пароль", "r", "m|8", "M|24"]
	}
	formFields: string[] = ['email', 'password'];
	ifError: Function;
	ifSuccess: Function;
	createTooltip: Function = createTooltip;
	fieldType: Function = fieldType;

	constructor(
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private router: Router,
		private location: Location
	){

		// console.log(this.form);

	}

	ngOnInit(){
		this.form = createForm(this.formConfig);
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
	}

	submit(){
		validateAllFields(this.form);
		if (this.form.valid){
			// нормально
			this.usersService.login(this.form.value).subscribe((res: any)=> {
				let token = parseJwt(res.access);
				localStorage.setItem('LOGOPED_TOKEN', res.access);
				localStorage.setItem('LOGOPED_TOKEN_REFRESH', res.refresh);
				// this.router.navigateByUrl('/');
				this.location.back();
				this.notificatorService.push({
					title: "Ключ безопасности",
					text: `Ключ будет действителен до [br] ${new Date(token.exp*1000).toLocaleString()}`.parseBytecodes()
				});
			}, (err: any)=> {
				this.notificatorService.push({
					title: "Ошибка",
					text: createErrorText(err.error),
					type: NotificationType.Error
				});
			});
		}
	}

}