import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Component({
	selector: 'svg-chart',
	templateUrl: './template.html'
})
export class SvgChart {

	config: any = {
		title: 'Обработка задач по статусам',
		dateFrom: '25/05/2018',
		dateTo: '31/05/2018',
		labels: ['25.05', '26.05', '27.05', '28.05', '29.05', '30.05', '31.05'],
		colors: ["#41a85f", "#155bd1", "#fac51c", "#57007b"],
		pointRadius: 4,
		height: null,
		width: null,
		offset: 20,
		endPoints: []
	}

	coordinates: any = [];
	verticalData: any = [];
	hoverPath = false;

	dataset: any = [
	{
		"name": "Выполнено",
		"value": [455, 248, 123, 655, 455, 541, 122]
	},
	{
		"name": "Активно",
		"value": [555, 204, 555, 455, 540, 540, 540]
	},
	{
		"name": "В ожидании",
		"value": [300, 530, 420, 530, 207, 108, 544]
	},
	{
		"name": "Отменено",
		"value": [500, 466, 455, 545, 647, 680, 750]
	}
	];

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
		){
	}


	// @HostListener('mouseenter', ['$event'])
	// onMouseEnter(event: any) {
	// 	console.log(event)
	// }

	// @HostListener('mouseleave')
	// onMouseLeave() {
	// 	console.log('leave')
	// }

	// @HostListener('mousemove', ['$event'])
	// handleMouseOver(event: any){
	// 	console.log('move')
	// }

	// @HostListener('click')
	// onClick() {
	// 	console.log('click')
	// }

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.drawGraph();
	}

	ngOnInit(){
		this.drawGraph();
	}

	drawGraph() {
		this.setSize();
		this.calculateSVGData();
		this.getTotalMinMax();
	}
	setSize() {
		let hostElem = this.el.nativeElement;
		let svgEl = hostElem.querySelector('svg');

		this.config.width = svgEl.parentNode.clientWidth;
		this.config.height = svgEl.parentNode.clientHeight;
	}
	calculateSVGData() {
		let height = this.config.height - this.config.pointRadius*2;
		this.coordinates = [];

		for (var i = 0; i < this.dataset.length; i++) {
			this.coordinates.push(this.getCoordinates(this.dataset[i].value, height, this.config.width, this.config))
		}
	}

	getCoordinates( values, height, width, config ) {
		// минимум и максимум из массива
		var min = Math.floor(Math.min.apply( null, values ))
		var max = Math.ceil(Math.max.apply( null, values ))

		// коэфициет движения по оси
		var yRatio = (max - min) / (height - config.offset*2);
		var xRatio = width / values.length;

		return values.map( function( value, i ) {
			var y = height - ( ( value - min ) / yRatio );
			var x = (config.width / config.labels.length * i + config.offset) - config.pointRadius;
			return [x + config.pointRadius, y + config.pointRadius*2 - config.offset]
		})
	}
	getTotalMinMax() {
		var arr = [];
		this.verticalData = [];

		this.dataset.forEach( function( dataObj, i ) {
			dataObj.value.map( function( coord, i ) {
				arr.push(coord);
			})
		})
		// округлене до ближайшого десятка
		var totalMin = Math.round(Math.min.apply( null, arr ) / 10) * 10;
		var totalMax = Math.round(Math.max.apply( null, arr ) / 10) * 10;

		this.config.endPoints.push(totalMin);
		this.config.endPoints.push(totalMax);

		var pointData: number = 0;
		var maxPoints: number = 10;

		// значение и координаты
		for (var i = 0; i < maxPoints; i++) {
			pointData = ( i < maxPoints - 1) ? (totalMax / maxPoints * i ) : totalMax;
			var pointCoord = (this.config.height - this.config.offset) / maxPoints * i;

			this.verticalData.push({
				data: pointData,
				coord: pointCoord
			});
		}
	}
	getPathCoordinates(points: any, closePath: boolean) {
		var total = points.length - 1;
		var lineHeight = 4;
		var path = 'M ';
		points.map( function( point, i ) {
			var pathInner = '';
			if (i < 1) {
				pathInner = point[0] + ' ' + point[1];
			} else {
				pathInner = 'L ' + point[0] + ' ' + point[1];
			}
			path += pathInner
		});
		if (closePath) {
			path += 'L ' + points[total][0] + ' ' + (this.config.height - this.config.offset/2 - lineHeight) + 'L ' + this.config.offset + ' ' + (this.config.height - this.config.offset/2 - lineHeight) + 'z';
		}
		return path;
	}
	onPathHover(event: any, color: string, pointsId: number) {
		this.hoverPath = !this.hoverPath;
		var points =document.querySelectorAll('[data-point="'+pointsId+'"]');
		if (this.hoverPath) {
			color = this.hexToRgb(color);
			event.target.style.strokeDasharray = 0;
			var opacity = 1;
		} else {
			event.target.style.strokeDasharray = 4;
			var opacity = 0;
		}
		event.target.style.fill = color;
			[].forEach.call(points, function(point) {
				point.style.opacity = opacity;
			});
	}
	hexToRgb(hex) {
		var c;
		if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			c = hex.substring(1).split('');
			if(c.length== 3){
				c= [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c= '0x'+c.join('');
			return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.3)';
		}
	}
}

