<div class="container-fluid">

	<form class="card" [formGroup]="form">
		<div class="heading">
			<div class="h2-title collapsed">
				<i class="icon icon-bag primary offset-right"></i>
				{{id ? "Редактирование занятия" : "Новое занятие"}}
			</div>
		</div>

		<div>
			<div class="row">
				<div class="col-xs-3">
					<div class="form-group" [class.error]="ifError('title')" [class.success]="ifSuccess('title')">
						<label for="lessonTitle">Название занятия</label>
						<input type="text" class="input" id="lessonTitle" formControlName="title">
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group" [class.error]="ifError('lesson')" [class.success]="ifSuccess('lesson')">
						<label for="lessonSelect">Вид занятия</label>
						<i class="icon icon-drop-down input-icon"></i>
						<select class="input" id="lessonSelect" formControlName="lesson">
							<option value="G">Групповое</option>
							<option value="I">Индивидуальное</option>
						</select>
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group" [class.error]="ifError('type')" [class.success]="ifSuccess('type')">
						<label for="typeSelect">Тип занятия</label>
						<i class="icon icon-drop-down input-icon"></i>
						<select class="input" id="typeSelect" formControlName="type" (change)="getSubtypes()">
							<option *ngFor="let type of types" [value]="type.pk">{{type.title}}</option>
						</select>
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group" [class.error]="ifError('subtype')" [class.success]="ifSuccess('subtype')">
						<label for="subtypeSelect">Подтип занятия</label>
						<i class="icon icon-drop-down input-icon"></i>
						<select class="input" id="subtypeSelect" formControlName="subtype">
							<option *ngFor="let subtype of subtypes" [value]="subtype.pk">{{subtype.subtitle}}</option>
						</select>
					</div>
				</div>
				<div class="col-xs-3">
					<div class="form-group" [class.error]="ifError('room')" [class.success]="ifSuccess('room')">
						<label for="roomSelect">Комната</label>
						<i class="icon icon-drop-down input-icon"></i>
						<input type="hidden" class="input" id="roomSelect" formControlName="room" (focus)="roomsTags.show()">
						<tags-select #pseudoInput [options]="roomsOptions" [output]="form.controls['room']" [max]="1" #roomsTags></tags-select>
					</div>
				</div>

			</div>

			<div class="h3-title lined offset-top"><span>Дата / Время</span></div>
			<div class="row bottom-xs">
				<div class="col-xs-3">
					<div class="form-group collapsed" [class.error]="ifError('loop')" [class.success]="ifSuccess('loop')">
						<label for="loopSelect">Цикличность</label>
						<i class="icon icon-drop-down input-icon"></i>
						<select class="input" id="loopSelect" formControlName="loop">
							<option value="ONE">Одноразовое занятие</option>
							<option value="MWF">Понедельник-Среда-Пятница</option>
							<option value="TT">Вторник-Четверг</option>
							<option value="TTS">Вторник-Четверг-Суббота</option>
						</select>
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group collapsed" [class.error]="ifError('startDate')" [class.success]="ifSuccess('startDate')">
						<label for="dateStartSelect">Дата</label>
						<i class="icon icon-calendar input-icon"></i>
						<input type="text" readonly="true" class="input" id="dateStartSelect" formControlName="startDate" (mousedown)="startDateDropdown.show()" data-dropdown-toggler>
						<dropdown data-origin="right" class="big" [hideButton]="true" #startDateDropdown>
							<calendar [showDay]="true" #calendar (onchange)="setStartDate($event)"></calendar>
						</dropdown>
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group collapsed" [class.error]="ifError('startTime')" [class.success]="ifSuccess('startTime')">
						<label for="timeStartSelect">Старт занятия</label>
						<i class="icon icon-clock input-icon"></i>
						<input type="text" readonly="true" class="input" id="timeStartSelect" formControlName="startTime" (click)="lessonTimeframe.show()">
					</div>
				</div>
				<div class="col-xs-2">
					<div class="form-group collapsed" [class.error]="ifError('duration')" [class.success]="ifSuccess('duration')">
						<label for="durationSelect">Длительность</label>
						<i class="icon icon-drop-down input-icon"></i>
						<select class="input" id="durationSelect" formControlName="duration">
							<option *ngFor="let duration of durationOptions" [value]="duration.value">{{duration.label}}</option>
						</select>
					</div>
				</div>
				<div class="col-xs-2">
					<button type="button" class="btn btn-transparent btn-block"
						(click)="lessonTimeframe.show()"
					>
						<i class="icon icon-clock offset-right"></i>
						Расписание
					</button>
				</div>
				<div class="col-xs-1">
					<div class="form-group collapsed" [class.error]="ifError('repeats')" [class.success]="ifSuccess('repeats')">
						<label for="repeatsCount">Кол-во</label>
						<input type="number" class="input" id="repeatsCount" formControlName="repeats" min="1" max="20" step="1">
					</div>
				</div>
			</div>

			<div class="h3-title lined offset-top"><span>Участники</span></div>
			<div class="row">
				<div class="col-xs-3">
					<div class="form-group" [class.error]="ifError('teacher')" [class.success]="ifSuccess('teacher')">
						<label for="teacherSelect">Педагоги ({{teachersTags.getSelectedLength()}})</label>
						<i class="icon icon-drop-down input-icon"></i>
						<input type="hidden" class="input" id="teacherSelect" formControlName="teacher" (focus)="teachersTags.show()">
						<tags-select #pseudoInput [options]="teachersOptions" [size]="'large'" [output]="form.controls['teacher']" [max]="3" #teachersTags></tags-select>
					</div>
					<div class="alert" *ngIf="!teachersTags.getSelectedLength()">Выберите преподавателей</div>
					<ul class="clear users-mini-list" *ngIf="teachersTags.getSelectedLength()">
						<li *ngFor="let teacher of getSelectedTeachers(teachersTags.getValues())">
							<img [src]="teacher.image || 'assets/image.png'">
							{{getName(teacher)}}
						</li>
					</ul>
				</div>
				<div class="col-xs-9">
					<div class="form-group" [class.error]="ifError('clients')" [class.success]="ifSuccess('clients')">
						<label for="clientsSelect">Клиенты ({{clientsTags.getSelectedLength()}})</label>
						<input type="hidden" class="input" id="clientsSelect" formControlName="clients" (focus)="clientsTags.show()">
						<tags-select #pseudoInput [options]="clientsOptions" [size]="'large'" [output]="form.controls['clients']" #clientsTags></tags-select>
					</div>
					<div class="alert" *ngIf="!clientsTags.getSelectedLength()">Выберите клиентов</div>
					<table class="table stripped" *ngIf="clientsTags.getSelectedLength()">
						<thead>
							<tr>
								<td style="width: 50px;">ID</td>
								<td>ФИО</td>
								<td style="width: 70px;">Цена</td>
								<td style="width: 70px;">Скидка</td>
								<td style="width: 80px;">Стоимость</td>
								<td style="width: 100px;">Активность</td>
								<td style="width: 100px;">Комментарий</td>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let client of getSelectedClients(clientsTags.getValues())">
								<td>{{client.pk}}</td>
								<td>{{getName(client)}}</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</div>

		<hr class="light">

		<div class="row">
			<div class="col-xs-3">
				<div class="btn-group">
					<button class="btn" (click)="goBack()">
						<i class="icon icon-undo offset-right"></i>
						Назад
					</button>
				</div>
			</div>
			<div class="col-xs-9 align-right">
				<div class="btn-group">
					<button class="btn btn-primary" (click)="submit()">
						<i class="icon icon-checked offset-right"></i>
						{{id ? "Сохранить" : "Добавить"}}
					</button>
					<button class="btn" (click)="reset()">
						<i class="icon icon-trash offset-right"></i>
						Очистить
					</button>
				</div>
			</div>
		</div>
	</form>

</div>

<lesson-timeframe
	[calendar]="calendar"
	[rooms]="rooms"
	[model]="form"
	[occupations]="occupations"
	[roomsTags]="roomsTags"
	#lessonTimeframe
></lesson-timeframe>