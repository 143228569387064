export * from './forms';
export * from './global-event';

export function toCamelKeys(obj: Object){
	let newObject = {};
	for (let key in obj){
		let camelKey = key.toString().toCamel();
		newObject[camelKey] = obj[key] instanceof Object && !obj[key].hasOwnProperty('length') ? toCamelKeys(obj[key]) : obj[key];
	}
	return newObject;
};

export function toDashKeys(obj: Object){
	let newObject = {};
	for (let key in obj){
		let camelKey = key.toString().toDash();
		newObject[camelKey] = obj[key] instanceof Object && !obj[key].hasOwnProperty('length') ? toDashKeys(obj[key]) : obj[key];
	}
	return newObject;
};

export function parseJwt(token){
	var base64Url = token.split('.')[1];
	var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	return JSON.parse(base64);
};

export function renderImagePreview(input: any, preview: any){
	if (input.files && input.files[0]) {
		var reader = new FileReader();
		reader.onload = function(e: any) {
			// console.log(preview, e.target.result);
			preview.setAttribute('src', e.target.result)
			// $('#blah').attr('src', e.target.result);
		}
		reader.readAsDataURL(input.files[0]);
	}
};

export function getElementOffset(offsetTrail: any) {
	let offsetLeft = 0;
	let offsetTop = 0;
	while (offsetTrail) {
		offsetLeft += offsetTrail.offsetLeft;
		offsetTop += offsetTrail.offsetTop;
		offsetTrail = offsetTrail.offsetParent;
	}
	return {
		left: offsetLeft,
		top: offsetTop
	};
}

export function getScrollTop(){
	if(typeof pageYOffset!= 'undefined'){
		return pageYOffset;
	}
	else{
		var B = document.body;
		var D = document.documentElement;
		D = (D.clientHeight) ? D: B;
		return D.scrollTop;
	}
}