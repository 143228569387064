import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { UsersService } from './../services';

@Component({
	selector: 'users-list-page',
	templateUrl: './users-list.html',
	providers: [ UsersService ]
})
export class UsersListPage {

	// users | teachers | admins
	group: string = "users";
	loading: boolean = false;

	constructor(
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute
	){


	}

	ngOnInit(){
		this.group = this.route.snapshot.params["group"] || "users";

		this.router.events.subscribe((event)=> {
			if (event instanceof NavigationStart){
				this.loading = true;
			}
			if (event instanceof NavigationEnd){
				setTimeout(()=> {
					this.group = this.router.routerState.snapshot.root.firstChild.params["group"];
					this.loading = false;
				})
			}
		});
	}

}