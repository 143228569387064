import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'search'
})
export class SearchPipe implements PipeTransform {

	transform(array: any[], req: string, keys: string, output?: Function): any[] {
		if (!array || !req || !keys){
			if (output) output(array);
			return array;
		}
		let res: any[] = [];
		array.map((item: any)=> {
			keys.split(',').forEach((key: string)=> {
				if (item[key].toString().match(new RegExp(req, "gim"))){
					res.push(item);
				}
			});
		});
		if (output) output(res);
		return res;
	}

}