import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BirthdaysService, DebtsService, UsersService, NotificatorService, DepartmentsService } from './../../services';
import { NotificationType } from './../../interfaces/notification';
import { createErrorText, GlobalEvent, GlobalEventInterface } from './../../utils';
import { Router, UrlTree } from '@angular/router';
import { Modal } from './../../components/modal';

@Component({
	selector: 'main-header',
	templateUrl: './template.html',
	host: {
		"[class.collapsed]": "collapsed"
	},
	providers: [
		BirthdaysService,
		DebtsService,
		UsersService,
		DepartmentsService
	]
})
export class MainHeader {

	collapsed: boolean = localStorage.getItem("LOGOPED_HEADER_COLLAPSED") === "true" ? true : false;
	birthdays: any[] = [];
	debts: any[] = [];
	tasks: any[] = [];

	// for modal window, where get the user, new one or select from list
	getUserFrom: string = "new";
	allUsers: any[] = [];
	userSearch: string = "";

	@ViewChild("surveySelectUserModal") surveySelectUserModal: Modal;
	@Output('onUiCollapse') onUiCollapse: EventEmitter<any> = new EventEmitter();

	birthdaysKeys: any[] = [
		{key: 'time', value: "Дата"},
		{key: 'name', value: "ФИО"},
		{key: 'age', value: "Возраст"},
		{key: 'checked', value: "Поздравить"}
	];
	debtsKeys: any[] = [
		{key: 'id', value: "ID"},
		{key: 'name', value: "ФИО"},
		{key: 'debt', value: "Баланс"},
	];

	departmentsListOptions: any = [];
	selectedDepartment: any = {id: 0, title: ""};

	globalEvent: GlobalEvent;


	constructor(
		private birthdaysService: BirthdaysService,
		private debtsService: DebtsService,
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private router: Router,
		private departmentsService: DepartmentsService
	){
		// console.log(localStorage.getItem("LOGOPED_HEADER_COLLAPSED"))
		setTimeout(()=> {
			birthdaysService.getBirthdays().subscribe((res: any)=> {
				this.birthdays = res;
			});
			debtsService.getDebts().subscribe((res: any)=> {
				this.debts = res;
			});
		}, 1000);

		this.globalEvent = new GlobalEvent();
		// this.globalEvent.subscribe((event: GlobalEventInterface)=> {
		// 	console.log(event);
		// });
	}

	ngOnInit(){
		this.departmentsService.getDepartmentsListOptions().subscribe((res: any)=> {
			this.departmentsListOptions = res.Options;
			// console.log(this.departmentsListOptions);
			this.selectedDepartment = this.departmentsListOptions.find(option => option.id === res.Current.id);
		});
	}

	selectDepartment(id: string){
		this.departmentsService.selectDepartment(id).subscribe((res: any)=> {
			// console.log(res);
			this.selectedDepartment = this.departmentsListOptions.find(option => option.id === id);
			this.globalEvent.trigger("department.change", {department: this.selectedDepartment});
		});
	}

	collapse(){
		this.collapsed = true;
		localStorage.setItem("LOGOPED_HEADER_COLLAPSED", "true");
		this.onUiCollapse.emit(this);
	}
	expand(){
		this.collapsed = false;
		localStorage.setItem("LOGOPED_HEADER_COLLAPSED", "false");
		this.onUiCollapse.emit(this);
	}
	toggle(){
		this.collapsed ? this.expand() : this.collapse();
	}

	getBirthdaysCounter(){
		return this.birthdays.length;
	}

	sendBirthdays(){
		this.birthdays = [];
	}

	getDebtsCounter(){
		return this.debts.length;
	}

	sendDebts(){
		this.debts = [];
	}

	getTasksCounter(){
		return this.tasks.length;
	}

	// survey
	loadAllUsers(){
		this.allUsers = [];
		this.usersService.getUsers().subscribe((res: any[])=> {
			this.allUsers = res.filter(u => u.role === "CLIENT");
		});
	}
	getFilteredUsers(){
		if (!this.userSearch) return this.allUsers;
		return this.allUsers.filter((u: any)=> {
			let name = this.usersService.getUserName(u);
			return name && name.match(new RegExp(this.userSearch, "gim")) ? true : false;
		});
	}
	onSubmit(data: any){
		this.usersService.registrateUser(data).subscribe((res: any)=> {
			console.log(res);
			this.surveySelectUserModal.hide();
			this.goToSurvey(res.user);
			this.notificatorService.push({
				title: "Отлично!",
				text: "Пользователь добавлен",
				type: NotificationType.Success
			});
		}, (err: any)=> {
			this.notificatorService.push({
				title: "Ошибка",
				text: createErrorText(err.error),
				type: NotificationType.Error
			});
		});
	}

	goToSurvey(user: any){
		if (!user.pk){
			console.warn("No user PK!", user);
			return;
		}
		this.surveySelectUserModal.hide();
		this.router.navigateByUrl("/survey/"+user.pk);
	}

}
