<div class="container-fluid">
	<div class="row collapsed-row">
		<div class="col-xs-1">
			<button class="btn btn-transparent" type="button" (click)="goBack()"><i class="icon icon-angle-left small margin-right"></i> Назад</button>
		</div>
		<div class="col-xs-11">
			<nav class="tabs">
				<a [routerLink]="['info']" routerLinkActive="active" draggable="false">Карта клиента</a>
				<a [routerLink]="['balance']" routerLinkActive="active" draggable="false">Баланс</a>
				<a [routerLink]="['reviews']" routerLinkActive="active" draggable="false">Отзывы</a>
				<a [routerLink]="['lessons']" routerLinkActive="active" draggable="false">Проведенные занятия</a>
			</nav>
		</div>
	</div>
	<router-outlet></router-outlet>
</div>