<div class="container-fluid">
	<div class="h1-title">Конструктор опроса</div>

	<div class="row">
		<div class="col-xs-4 col-lg-3">
			<div class="h2-title">Структура</div>
			<div class="card" style="height: auto;">
				<div class="body">
					<ul class="quiz">
						<li *ngFor="let question of questions; let i = index"
							ngDraggable="quiz" ngDroppable="quiz" [externalData]="{index: i, sort: question.question_sort}"
							(onDrop)="onDrop($event, i)"
						>
							<div class="text">{{question.subject}}</div>
						</li>
					</ul>
					<hr class="light">
					<div class="align-center">
						<button type="button" class="btn btn-success" (click)="openAddModal()">Добавить</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-8 col-lg-9">
			<div class="row collapsed-row">
				<div class="col-xs-9"><div class="h2-title">Вопросы ({{questions.length}})</div></div>
				<div class="col-xs-3 align-right"><button type="button" class="btn btn-success" (click)="openAddModal()">Добавить</button></div>
			</div>
			<div class="card offset-bottom" style="height: auto;" *ngFor="let question of questions | orderBy:'question_sort':'asc'; let i = index">
				<div class="body">
					<div class="quiz-question">
						<div class="counter">{{i+1}}</div>
						<div class="row collapsed-row">
							<div class="col-xs-9">
								<div class="grey">{{question.content_prefix}}</div>
								<div class="h3-title collapsed">{{question.subject}}</div>
								<div class="grey">{{question.content_postfix}}</div>
							</div>
							<div class="col-xs-3 align-right">
								<span class="badge">{{question.answers_type || "Степа проебался"}}</span> &nbsp; 
								<button type="button" class="btn btn-round btn-small btn-transparent" (click)="openEditModal(question, i)"><i class="icon icon-pencil"></i></button> &nbsp; 
								<button type="button" class="btn btn-error btn-small" (click)="checkQuestionDelete(question, i)">Удалить</button>
							</div>
						</div>
						<hr class="light">
						<input-group #inputGroup
							[config]="answersConfig" [placeholder]="'Ответ на вопрос'"
							[min]="0" [max]="question.answers_type === 'TEXT' ? 1 : 10"
							[externalData]="{question: question}"
							*ngIf="question.answers_type !== 'TEXT' && question.answers_type !== 'BOOL'"
							(onChange)="onAnswersChange($event)"
							(onChangeSpecial)="onAnswerChange($event)"
							(onAdd)="onAnswersAdd($event, question)"
							(onInit)="onInputGroupInit($event)"
							(onDelete)="onAnswerDelete($event, inputGroup)"
						></input-group>
						<div class="alert" *ngIf="question.answers_type === 'BOOL'">Отвечать тут нечего, просто либо галочка стоит либо нет</div>
						<div class="alert" *ngIf="question.answers_type === 'TEXT'">Ответ на вопрос текстовый, чтобы ответить - надо ручками ввести ответ при заполнении анкеты</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>


<modal #addModal>
	<div class="h3-title">Быстро как молния добавить вопрос</div>
	<form-quiz-add (onSubmit)="onQuestionAdd($event)" #formQuizAdd></form-quiz-add>

</modal>