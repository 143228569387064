import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { CONFIG } from './../config';

@Injectable()
export class DepartmentsService {

	subcategories: any = {
		list: "list/",
		rooms: "rooms/",
		current: "current/",
		openingHours: "opening_hours/",
		timetable: "timetable/"
	}

	headers: HttpHeaders = new HttpHeaders();

	constructor(private http: HttpClient){
		this.headers.append('Content-Type', 'application/json');
	}

	getTime(object: any){
		return new Date(object.changed.createdOn).toLocaleString();
	}

	createDepartment(data: any){
		// console.log(data);
		return this.http.post(CONFIG.api.departments+this.subcategories.list, {
			title: data.name,
			address: {
				address_line: data.address
			},
			contacts: {
				department_phones: data.phones.map((phone: string) => { return {number: phone}; }),
				department_emails: data.emails.map((email: string) => { return {address: email}; })
			},
			timetable: {
				uniform_working_days: data.allDaysSame ? true : false,
				timetable_hours: data.days.map((day: any, index: number)=> {
					return day.selected ? {
						checked: true,
						weekday: index+1,
						from_hour: day.from || "",
						to_hour: day.to || ""
					} : null;
				}).filter(d => d !== null)
			}
		});
	}

	editDepartment(data: any, id: string){
		// console.log(data);
		return this.http.patch(CONFIG.api.departments+this.subcategories.list+id+"/", {
			title: data.name,
			address: {
				address_line: data.address
			},
			contacts: {
				department_phones: data.phones.map((phone: string) => { return {number: phone}; }),
				department_emails: data.emails.map((email: string) => { return {address: email}; })
			},
			timetable: {
				uniform_working_days: data.allDaysSame ? true : false,
				timetable_hours: data.days.map((day: any, index: number)=> {
					return day.selected ? {
						checked: true,
						weekday: index+1,
						from_hour: day.from || "",
						to_hour: day.to || ""
					} : null;
				}).filter(d => d !== null)
			}
		});
	}

	getDepartmentsListOptions(){
		return this.http.get(CONFIG.api.departments+'current/');		
	}

	selectDepartment(id: string){
		return this.http.post(CONFIG.api.departments+'current/', {
			current: id
		});
	}

	getDepartments(){
		return this.http.get(CONFIG.api.departments+this.subcategories.list);
	}

	getDepartment(id: string){
		return this.http.get(CONFIG.api.departments+this.subcategories.list+id+"/");
	}

	deleteDepartment(id: string){
		return this.http.delete(CONFIG.api.departments+this.subcategories.list+id+"/");
	}

	setDepartmentImage(id: string, file: any){
		let formData: FormData = new FormData();
		formData.append('image', file);
		return this.http.patch(CONFIG.api.departments+this.subcategories.list+id+"/", formData);
	}

	/* rooms */
	createRoom(data: any){
		return this.http.post(CONFIG.api.departments+this.subcategories.rooms, {
			title: data.title,
			type: data.type,
			department: data.department
		});
	}

	setRoomImage(id: string, file: any){
		let formData: FormData = new FormData();
		formData.append('image', file);
		return this.http.patch(CONFIG.api.departments+this.subcategories.rooms+id+"/", formData);
	}

	deleteRoom(id: string){
		return this.http.delete(CONFIG.api.departments+this.subcategories.rooms+id+"/");
	}

	editRoom(id: string, data: any){
		return this.http.patch(CONFIG.api.departments+this.subcategories.rooms+id+"/", {
			title: data.title,
			type: data.type,
			department: data.department
		});
	}

	getRooms(){
		return this.http.get(CONFIG.api.departments+this.subcategories.rooms);
	}

}