<form class="static-form small" [formGroup]="form" autocomplete="new-password">
	<div class="alert transparent">Email или номер телефона</div>
	<div class="form-group"
		*ngFor="let field of fields"
		[class.error]="ifError(field)"
		[class.success]="ifSuccess(field)"
	>
		<input [attr.type]="fieldType(field)" class="input" [formControlName]="field" [placeholder]="config[field][0]" autocomplete="new-password">
		<i class="icon icon-cross" data-validation></i>
		<i class="icon icon-checked" data-validation></i>
		<div class="tooltip" [class.disabled]="ifSuccess(field)" [innerHTML]="createTooltip(config[field]).parseBytecodes()"></div>
	</div>

	<div class="">
		<button type="submit" (click)="submit()" class="btn btn-primary btn-block">Далее</button>
	</div>
	<hr>
	<a routerLink="/login" routerLinkActive="active">Войти</a> если Вы уже зарегистрированы в системе
</form>