<header class="main-header">
	<div class="layout">

		<div class="column logo-column">
			<a href="#" class="logo">
				<img src="assets/logo.svg" alt="">
			</a>
			<button type="button" class="menu-toggle" (click)="toggle()">
				<i></i><i></i><i></i>
			</button>
		</div>

		<div class="column location-column">
			<dropdown
				[text]="selectedDepartment.title"
				[icon]="'map'"
				[btn]="'btn-transparent'"
				class="inline"
				#departmentDropdown
			>
				<div class="scrolled">
					<ul class="clear links-list">
						<li *ngFor="let option of departmentsListOptions"><a href="#" (click)="selectDepartment(option.id); departmentDropdown.hide(); $event.preventDefault()">{{option.title}}</a></li>
					</ul>
				</div>
			</dropdown>
		</div>

<!-- 		<div class="column search-column">
			<div class="form-group">
				<i class="icon icon-search input-icon"></i>
				<input type="text" class="input grey" placeholder="Поиск">
			</div>
		</div> -->

<!-- 		<div class="column logout-column">
			<button type="button" class="btn btn-block">
				<i class="icon icon-power error"></i>
				<span class="sd-hide">  Блокировка системы</span>
			</button>
		</div> -->

		<!-- Именинники -->
<!-- 		<div class="column birthdays-column">
			<dropdown
				[text]="'<span class=\'d-hide\'>Именинники</span>'"
				[icon]="'cake primary'"
				[btn]="'btn-block btn-transparent'"
				[counter]="getBirthdaysCounter()"
				class="large"
				data-origin="right"
			>
				<sortable
					*ngIf="birthdays.length"
					[data]="birthdays"
					[keys]="birthdaysKeys"
					[widths]="['80','','90','110']"
					[small]="true"
					[stripped]="true"
				></sortable>
				<div class="btn-wrapper align-right" *ngIf="birthdays.length">
					<button type="button" class="btn btn-small btn-primary" (click)="sendBirthdays()">Поздравить</button>
					<button type="button" class="btn btn-small" (click)="sendBirthdays()">Поздравить всех</button>
				</div>
				<div *ngIf="!birthdays.length" class="alert spaced align-center">Нет ближайших дней рождения, некого поздравлять.</div>
			</dropdown>
		</div> -->

		<!-- Должники -->
<!-- 		<div class="column debt-column">
			<dropdown
				[text]="'<span class=\'d-hide\'>Должники</span>'"
				[icon]="'wallet primary'"
				[btn]="'btn-block btn-transparent'"
				[counter]="getDebtsCounter()"
				class="large"
				data-origin="right"
			>
				<sortable
					*ngIf="debts.length"
					[data]="debts"
					[keys]="debtsKeys"
					[widths]="['80','','110']"
					[small]="true"
					[stripped]="true"
				></sortable>
				<div class="btn-wrapper align-right" *ngIf="debts.length">
					<button type="button" class="btn btn-small btn-primary" (click)="sendDebts()">Простить всех грешников</button>
				</div>
				<div *ngIf="!debts.length" class="alert spaced align-center">Должников нет.</div>
			</dropdown>
		</div> -->

<!-- 		<div class="column tasks-column">
			<dropdown
				[text]="'<span class=\'d-hide\'>Задачи</span>'"
				[icon]="'folder primary'"
				[btn]="'btn-block btn-transparent'"
				[counter]="getTasksCounter()"
				class="large"
				data-origin="right"
			>
				<div *ngIf="!tasks.length" class="alert spaced align-center">Должников нет.</div>
				<div class="btn-wrapper" *ngIf="tasks.length">
					<div class="form-group">
						<i class="icon icon-search input-icon small"></i>
						<input type="text" class="input grey small">
					</div>
				</div>
				<hr class="collapsed" *ngIf="tasks.length">
			</dropdown>
		</div> -->

<!-- 		<div class="column queue-column">
			<button type="button" class="btn btn-block btn-transparent">
				<i class="icon icon-users primary faded"></i>
				<span class="d-hide">Очередь</span>
				<i class="icon icon-drop-down"></i>
				<span class="notifications"><span class="counter">200</span></span>
			</button>
		</div> -->

		<div class="column task-column">
			<button type="button" class="btn btn-block" (click)="surveySelectUserModal.show()">
				<i class="icon icon-user-card"></i>
				Отчет о приеме
			</button>
		</div>


		<div class="column task-column align-right">
			<a routerLink="/lessons/add" type="button" class="btn btn-success">
				Новое занятие
			</a>
		</div>

	</div>
</header>

<modal #surveySelectUserModal [title]="'Выберите клиента'">
	<div class="row collapsed-row">
		<div class="col-xs-6">
			<div class="checkbox radio">
				<input type="radio" name="surveySelectUser" [(ngModel)]="getUserFrom" value="new" id="surveySelectUserNew" checked>
				<label for="surveySelectUserNew">Новый клиент</label>
			</div>
		</div>
		<div class="col-xs-6">
			<div class="checkbox radio">
				<input type="radio" name="surveySelectUser" [(ngModel)]="getUserFrom" value="list" id="surveySelectUserList" (click)="loadAllUsers()">
				<label for="surveySelectUserList">Выбрать из списка</label>
			</div>
		</div>
	</div>
	<hr class="light">
	<form-registration (onSubmit)="onSubmit($event)" *ngIf="getUserFrom === 'new'"></form-registration>
	<div *ngIf="getUserFrom === 'list'" style="height: 374px; position: relative;">
		<div class="preloader absolute" [class.active]="!allUsers.length"></div>
		<div class="form-group" *ngIf="allUsers.length">
			<input type="text" class="input" [(ngModel)]="userSearch" placeholder="Начните вводить имя...">
		</div>
		<div class="modal-scroll" style="overflow-x: hidden; overflow-y: auto; height: 354px" *ngIf="allUsers.length">
			<ul class="clear users-mini-list">
				<li *ngFor="let user of getFilteredUsers(); let i = index"
					(click)="goToSurvey(user)"
				>
					<img [src]="user.image || 'assets/image.png'">
					<span [innerHTML]="usersService.getHighlihgtedUserName(user, userSearch)"></span>
				</li>
			</ul>
		</div>
	</div>
</modal>