import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { DepartmentsService } from './../../services/departments';
import { InputGroup } from './../../components/input-group';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, createForm, ifSuccess, ifError
} from './../../utils';
import { CONFIG } from './../../config';

@Component({
	selector: 'form-department-add',
	templateUrl: './template.html',
	providers: [ DepartmentsService ]
})
export class FormDepartmentAdd extends AbstractForm {

	form: FormGroup;
	phones: FormGroup;
	emails: FormGroup;
	formFields: string[] = [];

	departmentsGroupConfig: any = {
		name: ["Название", "r", "m|5", "M|50"],
		address: ["Адрес", "r", "m|5", "M|50"]
	}
	contactsGroupConfig: any = {
		phone: ["Телефон", "r", "m|9", "M|11"]
	}
	emailsGroupConfig: any = {
		email: ["Email", "r", "e", "m|2", "M|30"]
	}
	daysConfig: any = {
		selected: ["", "i"],
		from: ['С'],
		to: ['До']
	}

	currentDepartment: any;

	@ViewChild('contactsGroup') contactsGroup: InputGroup;
	@ViewChild('emailsGroup') emailsGroup: InputGroup;

	daysNames: string[] = CONFIG.dayNamesFull;
	allDaysSame: boolean = false;
	daysSelected: boolean[] = [];

	constructor(){
		super();
	}

	ngOnInit(){
		this.form = createForm(this.departmentsGroupConfig);
		if (this.phones){
			this.form.addControl('phones', this.phones);
		}
		if (this.emails){
			this.form.addControl('emails', this.emails);
		}
		this.form.addControl('days', new FormArray([
			createForm(this.daysConfig),
			createForm(this.daysConfig),
			createForm(this.daysConfig),
			createForm(this.daysConfig),
			createForm(this.daysConfig),
			createForm(this.daysConfig),
			createForm(this.daysConfig),
		]));
		this.form.addControl('allDaysSame', new FormControl(false));
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
		// console.log(this.form);

	}

	handleContactsGroup(group: any){
		this.phones = group.form;
		this.form.setControl('phones', this.phones);
	}
	handleEmailsGroup(group: any){
		this.emails = group.form;
		this.form.setControl('emails', this.emails);
	}

	availableDays(){
		// console.log(this.form['controls'].days['controls'][0])
		if (this.form.controls.allDaysSame.value){
			return [this.form['controls'].days['controls'][0], this.form['controls'].days['controls'][5], this.form['controls'].days['controls'][6]];
		}
		return this.form['controls'].days['controls'];
	}

	selectAllDaysSame(){
		setTimeout(()=> {
			let newValue = this.form.controls.allDaysSame.value;
			this.form['controls'].days['controls'][0]['controls'].selected.setValue(newValue);
		});
	}

	deselectAllDaysSame(index: number){
		if (index) return;
		setTimeout(()=> {
			let newValue = this.form['controls'].days['controls'][0]['controls'].selected.value;
			if (newValue === false){
				this.form.controls.allDaysSame.setValue(newValue);
			}
		});
	}

	openWindow(id?: string | number){
		this.id = id;
		this.modal.title = `${this.id ? "Редактировать" : "Добавить"} филиал`;
		this.mode = this.id ? AbstractFormModes.Edit : AbstractFormModes.Default;
		this.modal.show();
		setTimeout(()=> {
			this.firstFocus.nativeElement.focus();
		});
	}

	getDayIndex(index: number){
		// console.log(this.allDaysSame, index)
		return this.form.controls.allDaysSame.value ? (index === 0 ? 0 : index + 4) : index;
	}

}