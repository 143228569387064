<button type="button" class="btn btn-large" (click)="openWindow()">
	<i class="icon icon-plus primary"></i>
	Создать филиал
</button>

<modal #modal [title]="'Филиалы'" [large]="true" (onClose)="reset(true)">
	<form action="#" [formGroup]="form">

		<div class="row paddless-row">
			<div class="col-xs-2">
				<figure class="image-cover round smaller">
					<img src="/assets/image.png" alt="" #imagePreview>
				</figure>
				<div class="form-group">
					<input type="file" id="departmentImage" #imageInput
						(change)="renderImagePreview(imageInput, imagePreview); setFiles($event.target.files, $event)"
						accept=".jpg,.jpeg,.png"
					>
					<label for="departmentImage" class="align-center">
						{{imageInput.files[0] ? 'Выбрать другое' : 'Загрузить фото'}}
					</label>
				</div>
			</div>
			<div class="col-xs-4">
				<div class="form-group" 
					[class.error]="ifError('name')"
					[class.success]="ifSuccess('name')"
				>
					<label for="depName">Название</label>
					<input type="text" class="input small" formControlName="name" id="depName"
						placeholder="Логопед-Волшебник" #firstFocus
					>
				</div>
				<div class="form-group"
					[class.error]="ifError('address')"
					[class.success]="ifSuccess('address')"
				>
					<label for="depAddress">Адрес</label>
					<input type="text" class="input small" formControlName="address" id="depAddress"
						placeholder="г. Киев, ул. Пеячерская 99"
					>
				</div>
			</div>
		</div>

		<div class="row offset-top">
			<div class="col-xs-4 col-xs-offset-2">
				<div class="h3-title">
					Контакты
				</div>
				<input-group #contactsGroup [config]="contactsGroupConfig" [max]="5"
					(onChange)="handleContactsGroup($event)"
					[placeholder]="'80991234567'"
				></input-group>
				<input-group #emailsGroup [config]="emailsGroupConfig" [max]="3"
					(onChange)="handleEmailsGroup($event)"
					[placeholder]="'Email'"
				></input-group>
				<div class="h3-title offset-top" *ngIf="currentDepartment">Комнаты</div>
				<div class="alert align-center" *ngIf="currentDepartment">Пока что нет комнат. Нажмите "добавить" чтобы создать</div>
				<div class="btn-group offset-top" *ngIf="currentDepartment">
					<div class="relative inline">
						<button type="button" class="btn btn-small btn-transparent"
							(click)="addRoom.show()"
						>
							<i class="icon icon-plus small offset-right primary"></i>Добавить комнату
						</button>
						<popover #addRoom [title]="'Создать комнату'" [origin]="'top'">
							<div class="row paddless-row">
								<div class="col-xs-4">
									<figure class="image-cover round smaller">
										<img src="/assets/image.png" alt="">
									</figure>
									<div class="form-group">
										<input type="file" id="roomImage">
										<label for="roomImage" class="align-center">Загрузить фото</label>
									</div>
								</div>
								<div class="col-xs-8">
									<div class="form-group">
										<label for="roomDep">Филиал</label>
										<select name="" id="roomDep" class="input small">
											<option value="">Первый </option>
											<option value="">Второй</option>
											<option value="">Третий</option>
										</select>
									</div>
									<div class="form-group">
										<label for="depAddress">Название</label>
										<input type="text" class="input small" formControlName="address" id="depAddress"
											placeholder="г. Киев, ул. Пеячерская 99"
										>
									</div>
									<hr class="light">
									<button type="button" class="btn btn-transparent btn-small"><i class="icon icon-checked small offset-right success"></i>Применить</button>
									<button type="button" class="btn btn-transparent btn-small" (click)="addRoom.hide()"><i class="icon icon-undo small offset-right"></i>Отмена</button>
								</div>
							</div>
						</popover>
					</div>
				</div>
			</div>

			<div class="col-xs-6">
				<div class="h3-title">График работы</div>
				<div class="checkbox round">
					<input type="checkbox" id="allDaysSame" formControlName="allDaysSame" (click)="selectAllDaysSame()">
					<label for="allDaysSame">Будние дни по одному графику</label>
				</div>
				<div>
					<div class="row paddless-row offset-top" *ngFor="let day of availableDays(); let i = index">
						<div class="col-xs-5">
							<div class="checkbox min-height">
								<input type="checkbox" id="day_{{getDayIndex(i)}}"
									[formControl]="day.controls.selected"
									(click)="deselectAllDaysSame(getDayIndex(i))"
								>
								<label for="day_{{getDayIndex(i)}}">{{daysNames[getDayIndex(i)]}}</label>
							</div>
						</div>
						<div class="col-xs-7 align-right" *ngIf="day.controls.selected.value">
							<div class="input-caption">C</div>
							<timepicker class="inline" style="width: 50px; min-width: 45px" [node]="timeModel1" [control]="day.controls.from">
								<input type="text" readonly placeholder="чч:мм" class="input small paddless" [formControl]="day.controls.from" maxlength="5" #timeModel1>
							</timepicker>
							<div class="input-caption">До</div>
							<timepicker class="inline" style="width: 50px; min-width: 45px" [node]="timeModel2" [control]="day.controls.to">
								<input type="text" readonly placeholder="чч:мм" class="input small paddless" [formControl]="day.controls.to" maxlength="5" #timeModel2>
							</timepicker>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row paddless-row offset-top">
			<div class="col-xs-10 col-xs-offset-2">
				<hr class="light">
				<div class="btn-group">
					<button type="button" class="btn btn-transparent btn-small" (click)="submit()"><i class="icon icon-checked small offset-right success"></i>Применить</button>
					<button type="button" class="btn btn-transparent btn-small" (click)="modal.hide()"><i class="icon icon-undo small offset-right"></i>Отмена</button>
					<button type="button" class="btn btn-transparent btn-small"><i class="icon icon-trash small offset-right error"></i>Удалить</button>
				</div>
			</div>
		</div>
	</form>
</modal>