import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { DepartmentsService } from './../../services/departments';
import { InputGroup } from './../../components/input-group';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, createForm, ifSuccess, ifError
} from './../../utils';
import { CONFIG } from './../../config';

@Component({
	selector: 'form-occupation-add',
	templateUrl: './template.html',
	providers: [ DepartmentsService ]
})
export class FormOccupationAdd extends AbstractForm {

	config: any = {
		title: ["", "r", "m|5", "M|50"]
	}

	constructor(){
		super();
	}

	openWindow(id?: string | number){
		this.id = id;
		this.modal.title = `${this.id ? "Редактировать" : "Добавить"} тип занятия`;
		this.mode = this.id ? AbstractFormModes.Edit : AbstractFormModes.Default;
		this.modal.show();
		setTimeout(()=> {
			this.firstFocus.nativeElement.focus();
		});
	}

	submit(){
		validateAllFields(this.form);
		if (this.form.valid){
			this.onSubmit.emit(Object.assign(this.form.value, {
				files: (this.files || [null])[0],
				formIsEditing: this.mode === AbstractFormModes.Edit
			}));
			this.afterSubmit();
			this.reset();
		}
	}

}