import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { SelectOption } from './../../interfaces/selectOption';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'tags-select',
	templateUrl: './template.html',
	host: {
		"[class.open]": "open",
		"[attr.did]": "did"
	}
})
export class TagsSelect {

	@Input('open') open: boolean = false;
	@Input('max') max: number = 10;
	@Input('size') size: string = "normal";
	@Input('search') search: boolean = true;
	@Input('options') options: SelectOption[] = [];
	@Input('output') output: FormControl;
	@ViewChild('searchInput') searchInput: ElementRef;

	optionsObject: any = {};
	selectedOptions: SelectOption[] = [];

	selected: string = "";
	sorted: SelectOption[] = [];

	static instances: TagsSelect[] = [];
	static hostListener: boolean = false;
	static did: number = 0;
	private did: number = 0;

	searchRequest: string = "";

	constructor(private host: ElementRef){
		TagsSelect.instances.push(this);
		this.did = TagsSelect.did;
		TagsSelect.did++;
		if (!TagsSelect.hostListener){
			document.addEventListener('mousedown', (e: Event | any)=> {
				let closest = e.target.closest('tags-select');
				for (let i = 0; i < TagsSelect.instances.length; i++){
					if (!closest || closest.getAttribute('did') !== TagsSelect.instances[i].did.toString()){
						TagsSelect.instances[i].hide();
					}
				}
			});
			TagsSelect.hostListener = true;
		}
	}

	show(event?: any){
		this.open = true;
		if (this.searchInput){
			setTimeout(()=> {
				this.searchInput.nativeElement.focus();
			});
		}
	}

	hide(event?: any){
		this.open = false;
	}

	toggle(event?: any){
		if (event && this.open){
			let closest = event.target.closest('.selected-list .remove');
			if (closest && this.host.nativeElement) {
				return;
			}
		}
		this.open ? this.hide() : this.show();
	}

	ngOnChanges(changes: SimpleChanges){
		if (changes.options){
			changes.options.currentValue.map((option: SelectOption)=> {
				// if (Math.random() > 0.5){
				// 	this.addSelected(option);
				// }
				this.optionsObject[option.value] = option;
			});
		}
	}

	selectOption(option: SelectOption, index?: number){
		if (!option){
			option = this.options[index];
		}
		if (!this.hasOption(option)){
			this.addSelected(option);
		} else {
			this.removeSelected(option);
		}
	}

	selectOptionByValue(value: string){
		this.options.map((o: SelectOption)=> {
			if (o.value == value){
				this.selectOption(o);
			}
		});
	}

	hasOption(option: SelectOption): boolean {
		let value: string = option.value.toString();
		let array = this.selected.split(',');
		for (let i = 0; i < array.length; i++){
			if (array[i] === value){
				return true;
			}
		}
		return false;
		// console.log(this.selected, v);
		// return this.selected.match(new RegExp(v+"?=($|\,)", "gim")) ? true : false;
	}

	addSelected(option: SelectOption){
		if (this.selected === "" || this.getSelectedLength() >= this.max){
			this.selected = option.value.toString();
			this.makeOutput();
			return;
		}
		option.selected = true;
		this.selected += ","+option.value.toString();
		this.makeOutput();
	}

	removeSelected(option: SelectOption){
		let exp: RegExp = new RegExp("\,?"+option.value.toString(), "gim");
		this.selected = this.selected.replace(exp, "");
		this.selected = this.selected.replace("^\,", "");
		option.selected = false;
		if (!this.getSelectedLength()) this.hide();
		this.makeOutput();
	}

	getSelectedLength(){
		return this.selected ? this.selected.split(',').length : 0;
	}

	getSelected(){
		let result: SelectOption[] = [];
		if (!this.selected) return result;
		let keys: string[] = this.selected.split(',');
		for (let i = 0; i < keys.length; i++){
			if (keys[i] && this.optionsObject[keys[i]]){
				result.push(this.optionsObject[keys[i]]);
			}
		}
		return result;
	}

	getValues(){
		if (!this.selected) return [];
		let keys: any[] = this.selected.split(',');
		return keys;
	}

	reset(){
		this.getSelected().map((option: SelectOption)=> {
			this.removeSelected(option);
		});
		this.selected = "";
	}

	higlightSearchRequest(label: string){
		return this.searchRequest ? label.replace(new RegExp(`(${this.searchRequest})`, "gim"), "<mark>$1</mark>") : label;
	}

	setSorted(array: SelectOption[]){
		this.sorted = array;
	}

	makeOutput(){
		if (this.output){
			this.output.setValue(this.selected);
		}
	}

}