import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CONFIG } from './config';
import { NotificatorService } from './services';
import { NotificationType } from './interfaces';
import { parseJwt } from './utils';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	providers: [ NotificatorService ]
})
export class AppComponent {

	currentPath: string = location.hash || "";
	uiCollapsed: boolean = localStorage.getItem("LOGOPED_HEADER_COLLAPSED") === "true" ? true : false;

	constructor(
		private route: Router,
		private titleService: Title,
		private notificatorService: NotificatorService
	){

		this.routeEvent(this.route);

	}

	ngOnInit(){
		let token = localStorage.getItem("LOGOPED_TOKEN");
		let expiredTime = token ? new Date(parseJwt(token).exp*1000).getTime() : new Date().getTime();
		if (!token){
			CONFIG.token = "";
			this.route.navigateByUrl('/login');
			this.notificatorService.push({
				title: "Ключ безопасности",
				text: "Срок дейстия ключа истек, авторизируйтесь заново",
				type: NotificationType.Error
			});
		}
	}

	routeEvent(router: Router){
		router.events.subscribe(e => {
			if (e instanceof NavigationEnd){
				this.currentPath = e.url;
			}
			if (e instanceof ActivationStart){
				// console.log(e)
				if (e.snapshot.data && e.snapshot.data.title){
					this.titleService.setTitle(e.snapshot.data.title);
				} else {
					this.titleService.setTitle("Logoped");
				}
			}
		});
	}

	uiAvailable(): boolean {
		return this.currentPath.match(/login|registration|password/gim) ? false : true;
	}

	onUiCollapse(e: any){
		this.uiCollapsed = e.collapsed;
	}

}
