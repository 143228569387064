<div class="container-fluid">

	<div class="h1-title">Результат консультации</div>

	<div class="card">
		<div class="heading" *ngIf="loaded">
			<div style="max-width: 500px">
				<div class="row paddless-row middle-xs">
					<div class="col-xs-1">
						<figure class="image-cover round">
							<img [src]="user.image || 'assets/image.png'">
						</figure>
					</div>
					<div class="col-xs-5">
						<div class="h3-title collapsed">{{usersService.getUserName(user)}}</div>
					</div>
					<div class="col-xs-4" *ngIf="user.profile">
						<span class="badge">Profile: {{user.profile.pk}}</span>
					</div>
					<div class="col-xs-2">
						Quiz:
						<div class="form-group collapsed inline">
							<select class="input small" disabled>
								<option value="4" selected disabled>4</option>
							</select>
							<i class="icon icon-drop-down input-icon small"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="body">
			<div *ngIf="!loaded">
				Сбор информации о пользователе...
				<div class="preloader active"></div>
			</div>
			<div *ngIf="loaded">
				<div class="alert error" *ngIf="!user.profile">Профиль пользователя недоступен, невозможно отправить отчет</div>
				<div *ngIf="!questions.length && user.profile" class="alert">Нет вопросов, добавьте их в конструктор, ради бога</div>

				<form [formGroup]="form">

					<ul class="clear" *ngIf="questions.length && user.profile">
						<li *ngFor="let question of questions; let i = index" class="quiz-form-control"
							[class.error]="ifError('question_'+question.pk)"
						>
							{{getQuestionTitle(question)}}
							<!-- TEXT -->
							<div class="form-group collapsed inline" *ngIf="question.answers_type === 'TEXT'">
								<input type="text" class="input small" formControlName="question_{{question.pk}}">
							</div>
							<!-- SELECT -->
							<div class="form-group collapsed inline" *ngIf="question.answers_type === 'SELECT'">
								<select class="input small" formControlName="question_{{question.pk}}">
									<option value="" selected disabled=""></option>
									<option [value]="answer.pk" *ngFor="let answer of question.answers; let answerIndex = index">{{answer.content}}</option>
								</select>
								<i class="icon icon-drop-down input-icon small" *ngIf="question.answers_type === 'SELECT'"></i>
							</div>
							<!-- CHECKBOX -->
							<div class="form-group collapsed" *ngIf="question.answers_type === 'CHECKBOX'">
								<ul class="clear quiz-checkboxes">
									<li *ngFor="let answer of question.answers; let answerIndex = index">
										<div class="checkbox">
											<input type="checkbox" id="{{question.pk}}_answer_{{answerIndex}}" [value]="answer.pk" (input)="addOrRemoveCheckboxValue($event.target, 'question_'+question.pk, question.pk, answerIndex, true)">
											<label for="{{question.pk}}_answer_{{answerIndex}}">{{answer.content}}</label>
										</div>
									</li>
								</ul>
							</div>
							<!-- RADIO -->
							<div class="form-group collapsed" *ngIf="question.answers_type === 'RADIO'">
								<ul class="clear quiz-checkboxes">
									<li *ngFor="let answer of question.answers; let answerIndex = index">
										<div class="checkbox radio">
											<input type="radio" id="{{question.pk}}_answer_{{answerIndex}}" [value]="answer.pk" name="{{question.pk}}_answers_group" (input)="setRadioValue($event.target, 'question_'+question.pk, question.pk, answerIndex)">
											<label for="{{question.pk}}_answer_{{answerIndex}}">{{answer.content}}</label>
										</div>
									</li>
								</ul>
							</div>
							<!-- TRUE | FALSE -->
							<div class="form-group collapsed inline" *ngIf="question.answers_type === 'BOOL'">
								<div class="checkbox round">
									<input type="checkbox" id="{{question.pk}}_answer" formControlName="question_{{question.pk}}">
									<label for="{{question.pk}}_answer"></label>
								</div>
							</div>

						</li>
					</ul>

				</form>

				<hr class="light">

				<div class="alert error offset-bottom" *ngIf="error">Заполните все ответы</div>

				<button type="button" class="btn btn-primary btn-large" (click)="submit()" *ngIf="user.profile">
					<i class="icon icon-checked margin-right"></i>
					Отправить
				</button>

			</div>
		</div>
	</div>

</div>