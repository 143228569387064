<div class="scrollable-content" #scrollableContent
	[class.inner-space]="innerSpace"
	[class.inner-space-vertical]="innerSpaceVertical"
	[style.max-height]="maxHeight"
>
	<ng-content></ng-content>
</div>

<!-- vertical -->
<div class="scrollbar" #scrollbar
	[class.active]="active"
	[attr.data-origin]="origin"
	*ngIf="options.orientation === 'vertical'"
>
	<div class="thumb"
		[style.height]="(thumbSize*100)+'%'"
		[style.top]="thumbTop+'%'"
		(mousedown)="bindDraggind($event)"
	></div>
	<button class="prev" #prev *ngIf="options.buttons"
		(mousedown)="movePrev()"
		[class.disabled]="noMorePrev"
	><span class="chevron up"></span></button>
	<button class="next" #next *ngIf="options.buttons"
		(mousedown)="moveNext()"
		[class.disabled]="noMoreNext"
	><span class="chevron down"></span></button>
</div>

<!-- horizontal -->
<div class="scrollbar horizontal" #scrollbar
	[class.active]="active"
	[style.width]="width ? width : ''"
	[attr.data-origin]="origin"
	*ngIf="options.orientation === 'horizontal'"
>
	<div class="thumb"
		[style.width]="(thumbSize*100)+'%'"
		[style.left]="thumbLeft+'%'"
		(mousedown)="bindDraggind($event)"
	></div>
	<button class="prev" #prev *ngIf="options.buttons"
		(mousedown)="movePrev()"
		[class.disabled]="noMorePrev"
	><span class="chevron left"></span></button>
	<button class="next" #next *ngIf="options.buttons"
		(mousedown)="moveNext()"
		[class.disabled]="noMoreNext"
	><span class="chevron right"></span></button>
</div>