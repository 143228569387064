import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UsersService } from './../services';

@Component({
	selector: 'users-lessons',
	templateUrl: './users-lessons.html'
})
export class UsersLessonsPage {

	user: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService
	){
		let id = parseInt(location.hash.replace(/\D/gim, ''));
		this.usersService.getUser(id).subscribe((user: any)=> {
			this.user = user;
			console.log("Detail user: ", user);
		});
	}

}