<div class="card">
	<div class="heading">
		<div class="h1-title collapsed">Карта клиента</div>
	</div>
	<div class="body" *ngIf="user">
		<div class="row">
			<div class="col-xs-3 col-sm-2 col-lg-2">
				<figure class="image-cover round">
					<img [src]="user.image || 'assets/image.png'">
				</figure>
			</div>
			<div class="col-xs-4">
				<div class="h2-title">{{usersService.getUserName(user)}}</div>
				<dl class="dl"><dt>ID</dt><dd>{{user.pk}}</dd></dl>
				<dl class="dl"><dt>Email</dt><dd>{{user.email || 'Нет'}}</dd></dl>
				<dl class="dl"><dt>Дата рождения</dt><dd>{{usersService.getUserDate(user.date_of_birth)}}</dd></dl>
				<dl class="dl"><dt>Зарегистрирован</dt><dd>{{usersService.getUserDate(user.date_joined)}}</dd></dl>
				<dl class="dl"><dt>Ссылка</dt><dd><a href="{{usersService.getUserLink(user)}}">{{usersService.getUserLink(user)}}</a></dd></dl>
			</div>
			<div class="col-xs-3 col-lg-2">
				<a [routerLink]="['/survey/'+user.pk]" class="btn btn-success">Отчет о консультации</a>
				<hr class="light">
				<div class="alert">Заполните форму о проведенном занятии и постановке диагноза</div>
			</div>
		</div>
	</div>
	<div class="body" *ngIf="!user">
		<div class="alert">Загружается...</div>
	</div>
</div>