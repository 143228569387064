import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview
} from './../utils';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CONFIG } from './../config';
import { NotificatorService, DepartmentsService } from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';


@Component({
	selector: 'rooms-page',
	templateUrl: './rooms-page.html',
	providers: [ NotificatorService, DepartmentsService ]
})
export class RoomsPage {

	// data
	departments: any[] = [];
	rooms: any[] = [];
	roomsLoading: boolean = true;

	addFormGroup: FormGroup;
	addFormGroupConfig: any = {
		title: ["", "r"],
		department: ["", "r"],
		type: ["", "r"]
	}
	addFormImage: any;

	renderImagePreview: Function = renderImagePreview;
	ifError: Function;
	ifSuccess: Function;

	constructor(
		private notificatorService: NotificatorService,
		private departmentsService: DepartmentsService
	){

		this.departmentsService.getDepartments().subscribe((res: any)=> {
			this.departments = res.map((d: any)=> {
				return toCamelKeys(d);
			}).sort((a: any, b: any)=> {
				return new Date(b.changed.createdOn).getTime() - new Date(a.changed.createdOn).getTime();
			});
			// console.log(this.departments);
		});

		this.fetchRooms();

	}

	ngOnInit(){
		this.addFormGroup = createForm(this.addFormGroupConfig);
		this.ifError = ifError.bind(this.addFormGroup);
		this.ifSuccess = ifSuccess.bind(this.addFormGroup);
	}

	fetchRooms(){
		this.roomsLoading = true;
		this.departmentsService.getRooms().subscribe((res: any)=> {
			this.roomsLoading = false;
			this.rooms = res.map((d: any)=> {
				return toCamelKeys(d);
			}).sort((a: any, b: any)=> {
				return new Date(b.changed.createdOn).getTime() - new Date(a.changed.createdOn).getTime();
			});
			// console.log(this.rooms);
		});
	}

	save(){
		validateAllFields(this.addFormGroup);

		// console.log(this.addFormGroup.controls['image']);
		// makeFlatValue(this.form.value, ['phones', 'emails'])
	}

	setRoomImage(file: any){
		this.addFormImage = file;
		// console.log(file);
	}

	handleDeleteRoom(room: any){
		this.notificatorService.push({
			title: "Rooms",
			text: `Комната "${room.title}" удалена`,
			type: NotificationType.Error
		});
		this.fetchRooms();
	}

	handleAddRoom(data: any){
		this.departmentsService.createRoom(data).subscribe((res: any)=> {
			if (data.files){
				this.departmentsService.setRoomImage(res.pk, data.files).subscribe((res: any)=> {
					this.fetchRooms();
				});
			} else {
				this.fetchRooms();
			}
			this.notificatorService.push({
				title: "Rooms",
				text: `Комната "${data.title}" добавлена`,
				type: NotificationType.Success
			});
		});
	}

	handleEditRoom(data: any, id: string){
		this.departmentsService.editRoom(id, data).subscribe((res: any)=> {
			if (data.files){
				this.departmentsService.setRoomImage(res.pk, data.files).subscribe((res: any)=> {
					this.fetchRooms();
				});
			} else {
				this.fetchRooms();
			}
			this.notificatorService.push({
				title: "Rooms",
				text: `Комната "${data.title}" изменена`
			});
		});
	}

}