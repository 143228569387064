import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NotificatorService } from './../../services/notificator';
import { Notification } from './../../interfaces/notification';
import { CONFIG } from './../../config';

@Component({
	selector: 'notificator',
	templateUrl: './template.html',
	providers: [ NotificatorService ]
})
export class Notificator {

	notifications: Notification[] = [];

	constructor(private notificatorService: NotificatorService){

		this.notificatorService.notifications.subscribe((n: Notification)=> {
			this.notifications.unshift(n);
			setTimeout(()=> {
				this.hide(n);
			}, CONFIG.timeouts.notificationActive);
		});

	}

	hide(n: Notification){
		for (let i = 0; i < this.notifications.length; i++){
			if (this.notifications[i] === n){
				this.notifications[i].active = false;
				setTimeout(()=> {
					this.notifications.splice(i, 1);
				}, 200);
			} else {
				continue;
			}
		}
	}

}