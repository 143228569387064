export interface Notification {
	title?: string;
	text?: string;
	active?: boolean;
	type?: NotificationType;
}

export enum NotificationType {
	Default = "default",
	Success = "success",
	Error = "error"
}
