<div class="spaced-block person-block">
	<div class="row collapsed-row middle-xs">
		<div class="col-xs-3">
			<figure class="image-cover" (click)="personMenu.toggle()">
				<img src="assets/man.svg" alt="">
			</figure>
		</div>
		<div class="col-xs-9">
			<div class="name">{{user.firstName}} {{user.lastName}}</div>
			<div class="role">{{user.email}}</div>
		</div>
	</div>
	<spoiler #personMenu [hideButton]="true">
		<ul class="clear links-list">
			<li><a (click)="logout()"><i class="icon icon-undo"></i>Выйти</a></li>
		</ul>
	</spoiler>
</div>

<scrollable [height]="'calc(100% - 90px)'" [maxHeight]="'calc(100%)'">

	<ul class="clear menu static">
		<li>
			<a href="#">
				<i class="icon icon-wallet"></i>Дневная касса: 
				<span class="ignore-collapse">10 000<span class="uah">₴</span></span>
			</a>
		</li>
	</ul>

	<div class="spaced-block collapsed">
		<calendar [showDay]="true" [multiple]="true" #calendar [collapsed]="collapsed"></calendar>
	</div>

	<ul class="clear menu">
		<li [class.active]="currentPath === '/'"><a href="#"><i class="icon icon-building"></i>Главная</a></li>
		<li [class.active]="routeIs('schedule') && !routeIs('schedule-teacher')"><a routerLink="/schedule"><i class="icon icon-file"></i>Расписания</a></li>
		<li [class.active]="routeIs('schedule-teacher')"><a href="" routerLink="/schedule-teacher"><i class="icon icon-users"></i>График сотрудников</a></li>
		<li [class.active]="routeIs('admins')"><a href="#" routerLink="/users/admins"><i class="icon icon-user-book"></i>Список админов</a></li>
		<li [class.active]="routeIs('teachers')"><a href="#" routerLink="/users/teachers"><i class="icon icon-user-book"></i>Список преподавателей</a></li>
		<li [class.active]="routeIs('clients')"><a routerLink="/users/clients"><i class="icon icon-user-card"></i>Список клиентов</a></li>
		<li><a href="#"><i class="icon icon-graph"></i>Отчеты</a></li>
		<li [class.open]="spoilerSettings.open">
			<a href="#"><i class="icon icon-settings"></i>Настройки</a>
			<spoiler [open]="true" #spoilerSettings>
				<ul class="clear submenu">
					<li [class.active]="routeIs('departments')"><a routerLink="/departments">Филиалы</a></li>
					<li [class.active]="routeIs('rooms')"><a routerLink="/rooms">Комнаты</a></li>
					<li [class.active]="routeIs('occupations')"><a routerLink="/occupations">Типы занятий</a></li>
					<li [class.active]="routeIs('occupations-cost')"><a routerLink="/occupations-cost">Стоимость занятий</a></li>
					<li [class.active]="routeIs('quiz-constructor')"><a routerLink="/quiz-constructor">Конструктор опроса</a></li>
				</ul>
			</spoiler>
		</li>
	</ul>

</scrollable>