<div class="card">
	<div class="heading">
		<div class="table-layout">
			<div class="column" style="width: 70px">
				<figure class="image-cover round">
					<img [src]="data.image || 'assets/image.png'">
				</figure>
			</div>
			<div class="column">
				<div class="h3-title collapsed">{{data.title}}</div>
				<div class="grey">{{data.type === "L" ? "В здании" : "Виртуальная"}}</div>
			</div>
			<div class="column" style="width: 40px;">
				<dropdown [btn]="'btn-round btn-block btn-transparent'" [icon]="'dots-vertical'" [hideArrow]="true" class="inline small" data-origin="right">
					<ul class="clear links-list">
						<li><div class="link" (click)="edit()"><i class="icon icon-pencil"></i>Редактировать</div></li>
						<li><div class="link" (click)="delete()"><i class="icon icon-trash"></i>Удалить</div></li>
					</ul>
				</dropdown>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-6">
			<div class="h3-title">Преподаватели</div>
			<small class="grey">Не назначены</small>
		</div>
		<div class="col-xs-6">
			<div class="h3-title">Занятия</div>
			<small class="grey">Не созданы</small>
		</div>
	</div>
</div>