<button type="button" class="btn btn-large" (click)="openWindow()" *ngIf="!hideButton">
	<i class="icon icon-plus small offset-right primary"></i>
	Добавить комнату
</button>

<modal #modal [title]="'Создать комнату'" (onClose)="reset(true)">
	<form action="#" [formGroup]="form">

		<div class="row paddless-row">
			<div class="col-xs-4">
				<figure class="image-cover round smaller">
					<img src="/assets/image.png" alt="" #imagePreview>
				</figure>
				<div class="form-group">
					<input type="file" id="roomImage" #imageInput
						(change)="renderImagePreview(imageInput, imagePreview); setFiles($event.target.files, $event)"
						accept=".jpg,.jpeg,.png"
					>
					<label for="roomImage" class="align-center">
						{{imageInput.files[0] ? 'Выбрать другое' : 'Загрузить фото'}}
					</label>
				</div>
			</div>
			<div class="col-xs-8">
				<div class="form-group"
					[class.error]="ifError('title')"
					[class.success]="ifSuccess('title')"
				>
					<label for="roomAddress">Название</label>
					<input type="text" class="input small" formControlName="title" id="roomAddress"
						placeholder="г. Киев, ул. Пеячерская 99" #firstFocus
					>
				</div>
				<div class="form-group"
					[class.error]="ifError('department')"
					[class.success]="ifSuccess('department')"
				>
					<label for="roomDep">Филиал</label>
					<select id="roomDep" class="input small" formControlName="department">
						<option [value]="option.pk" *ngFor="let option of departments">{{option.title}}</option>
					</select>
				</div>
				<div class="form-group"
					[class.error]="ifError('type')"
					[class.success]="ifSuccess('type')"
				>
					<label for="roomType">Тип</label>
					<select id="roomType" class="input small" formControlName="type">
						<option value="L">В здании</option>
						<option value="V">Виртуальная</option>
					</select>
				</div>
				<hr class="light">
				<button type="button" class="btn btn-transparent btn-small" (click)="submit()">
					<i class="icon icon-checked small offset-right success"></i>
					Применить
				</button>
				<button type="button" class="btn btn-transparent btn-small" (click)="modal.hide()">
					<i class="icon icon-undo small offset-right"></i>
					Отмена
				</button>
			</div>
		</div>

	</form>
</modal>