import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable, from, of } from "rxjs";
import { map, filter, mergeMap, catchError, delay } from "rxjs/operators";
import { CONFIG } from './../config';
import { parseJwt } from './../utils';
import { NotificatorService } from './notificator';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	updating: boolean = false;

	constructor(private http: HttpClient, private router: Router){

	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token = localStorage.getItem("LOGOPED_TOKEN");
		if (token){
			const authReq = req.clone({
				headers: req.headers.set('Authorization', "Bearer "+token)
			});
			let expiredTime = token ? new Date(parseJwt(token).exp*1000).getTime() : new Date().getTime();
			// update token
			if (new Date().getTime()+(20*1000) > expiredTime && !this.updating){
				this.updating = true;
				console.log('[SECURITY] :: getting new token...');
				return this.updateToken(req, next);
			}
			return next.handle(authReq);
		} else {
			return next.handle(req);
		}
	}

	updateToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token = localStorage.getItem("LOGOPED_TOKEN");
		let refreshToken = localStorage.getItem("LOGOPED_TOKEN_REFRESH");
		return this.http.post(CONFIG.api.auth+'token-refresh/', {
			refresh: refreshToken
		}).pipe(mergeMap((res: any)=> {
			console.log(`[SECURITY] :: new token received. next time at ${new Date(parseJwt(res.access).exp*1000).toLocaleString()}`);
			localStorage.setItem("LOGOPED_TOKEN", res.access);
			let newToken = parseJwt(res.access);
			this.updating = false;
			const authReq = req.clone({
				headers: req.headers.set('Authorization', "Bearer "+res.access)
			});
			return next.handle(authReq).pipe(catchError(err => {
				return this.updateToken(req, next);
			}));
		}));
	}
}