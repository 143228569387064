import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SettingsService, NotificatorService } from './../services';
import { NotificationType } from './../interfaces/notification';
import { getString } from './../utils/lorem';
import { Draggable } from './../directives/draggable';
import { Droppable } from './../directives/droppable';
import { Dialog } from './../forms/dialog';
import { InputGroup } from './../components/input-group';
import { Modal } from './../components/modal';
import { FormQuizAdd } from './../forms/quiz-add';
import { AbstractFormModes } from './../forms/abstract-form';

@Component({
	selector: 'quiz-constructor',
	templateUrl: './quiz-constructor.html',
	providers: [ SettingsService, NotificatorService ]
})
export class QuizConstructorPage {

	questions: any[] = [];
	answers: any = {};
	editingQuestionIndex: number = -1;

	answersInputGroups: any = {};

	deleteDialog: Dialog = new Dialog();

	answersConfig: any = {answer: ["Ответ", "r", "m|2", "M|100"]};

	@ViewChild('addModal') addModal: Modal;
	@ViewChild('formQuizAdd') formQuizAdd: FormQuizAdd;

	constructor(private settingsService: SettingsService, private notificatorService: NotificatorService){

		// for (let i = 0; i < 10; i++){
		// 	this.questions.push({
		// 		question_sort: Math.floor(Math.random() * 100),
		// 		quiz: Math.floor(Math.random() * 100),
		// 		subject: getString(1 + Math.floor(Math.random() * 5)),
		// 		content_prefix: Math.random() > 0.5 ? getString(1 + Math.floor(Math.random() * 10)) : "",
		// 		content_postfix: Math.random() > 0.5 ? getString(1 + Math.floor(Math.random() * 10)) : ""
		// 	});
		// }

		this.settingsService.getQuizQuestions().subscribe((res: any[])=> {
			this.questions = res;
			res.map((q)=> {
				if (!this.answers[q.pk]) this.answers[q.pk] = [];
			});
			this.sortQuestions();
			console.log("questions: ", res);

			this.settingsService.getQuizAnswers().subscribe((res: any[])=> {
				res.map((a)=> {
					if (!this.answers[a.question]){
						this.answers[a.question] = [];
					}
					this.answers[a.question].push(a);
				});
				for (let key in this.answers){
					this.answers[key].sort((a, b)=> a.pk - b.pk);
				}
				console.log("answers: ", this.answers);
				this.insertAnswers();
			});
		});

	}

	onDrop(event: any, index: number){
		let drag = Draggable.activeDraggable.externalData,
				drop = Droppable.activeDroppable.externalData;
		if (drag.index === drop.index) return;

		this.questions = this.arrayMove(this.questions, drag.index, drop.index);
		this.sortQuestions();

		// console.log(Draggable.activeDraggable, Droppable.activeDroppable, event, index);
	}

	sortQuestions(){
		for (let i = 0; i < this.questions.length; i++) this.questions[i].question_sort = i;
		this.saveQuestionsSorting();
	}

	saveQuestionsSorting(){
		this.settingsService.setQuestionsSorting(this.questions.slice().map((q)=> { return {"pk": q.pk, "id": q.pk, "question_sort": q.question_sort}; })).subscribe(()=> {
			this.notificatorService.push({
				title: "QUIZ",
				text: "Вопросы отсортированы"
			});
		}, ()=> {
			this.notificatorService.push({
				title: "QUIZ",
				text: "Вопросы не удалось отсортировать, Степа наебал меня",
				type: NotificationType.Error
			});
		});
	}

	private arrayMove(arr, old_index, new_index) {
		if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
						arr.push(undefined);
				}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr;
	};

	onQuestionAdd(data: any){
		// if we should update question
		if (this.editingQuestionIndex >= 0 && this.questions[this.editingQuestionIndex]){
			this.settingsService.updateQuizQuestion(this.questions[this.editingQuestionIndex], data).subscribe((res: any)=> {
				this.questions[this.editingQuestionIndex].subject = data.title;
				this.questions[this.editingQuestionIndex].content_prefix = data.prefix;
				this.questions[this.editingQuestionIndex].content_postfix = data.postfix;
				this.questions[this.editingQuestionIndex].answers_type = data.type;
				this.sortQuestions();
				this.addModal.hide();
				this.notificatorService.push({
					title: "QUIZ",
					text: "Вопрос обновлен"
				});
			});
		// otherwise - add
		} else {
			data.sort = this.questions.length;
			this.settingsService.createQuizQuestion(data).subscribe((res: any)=> {
				res.question_sort = this.questions.length;
				this.questions.push(res);
				this.sortQuestions();
				this.addModal.hide();
				this.notificatorService.push({
					title: "QUIZ",
					text: "Вопрос добавлен"
				});
			});
		}
	}

	checkQuestionDelete(question: any, index?: number){
		if (!question) return;
		this.deleteDialog.show("Точно удалить вопрос? Подумай прям очень хорошенечко!", ()=> {
			this.settingsService.deleteQuizQuestion(question).subscribe((res)=> {
				this.notificatorService.push({
					title: "QUIZ",
					text: "Вопрос удален"
				});
				this.questions.splice(index, 1);
			});
		}, "error");
	}

	onInputGroupInit(inputGroup: InputGroup){
		this.answersInputGroups[inputGroup.externalData.question.pk] = inputGroup;
	}

	// a whole form [formGroup]
	onAnswersChange(event: any){
		// console.log(event);
	}

	// one particular answer [input]
	onAnswerChange(event: any){
		let answer = this.answers[event.externalData.question.pk][event.index];
		answer.content = event.value;
		this.settingsService.updateQuizAnswer(answer).subscribe();
	}

	onAnswerDelete(index: number, inputGroup: InputGroup){
		let answer = this.answers[inputGroup.externalData.question.pk][index];
		this.settingsService.deleteQuizAnswer(answer).subscribe(()=> {
			this.answers[inputGroup.externalData.question.pk].splice(index, 1);
			this.notificatorService.push({title: "QUIZ", text: "Ответ удален"});
		});
		// console.log(index, inputGroup);
	}

	onAnswersAdd(formControl: any, question: any){
		// console.log(formControl, question);
		if (!formControl.value){
			if (!this.answers[question.pk]){
				this.answers[question.pk] = [];
			}
			this.settingsService.createQuizAnswer({
				answer_sort: this.answers[question.pk].length,
				question: question.pk
			}).subscribe((res: any)=> {
				this.notificatorService.push({title: "QUIZ", text: "Создан пустой ответ. Не оставляйте его пустым."});
				this.answers[question.pk].push(res);
			});
		}
	}

	private insertAnswers(){
		this.questions.map((q: any)=> {
			let inputGroup: InputGroup = this.answersInputGroups[q.pk];
			let answers = this.answers[q.pk];
			if (inputGroup && answers && answers.length){
				answers.map((a)=> {
					inputGroup.add(a.content);
				});
			}
		});
	}

	openAddModal(){
		this.formQuizAdd.mode = AbstractFormModes.Default;
		this.editingQuestionIndex = -1;
		this.addModal.show();
	}

	openEditModal(question: any, index: number){
		this.addModal.show();
		this.editingQuestionIndex = index;
		this.formQuizAdd.mode = AbstractFormModes.Edit;
		this.formQuizAdd.patch({
			title: question.subject,
			prefix: question.content_prefix,
			postfix: question.content_postfix,
			type: question.answers_type
		});
	}

}