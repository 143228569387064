<button type="button" class="btn {{btn}}"
	*ngIf="!hideButton"
	[class.active]="open"
	(click)="toggle()"
>
	<i class="icon icon-{{icon}}" *ngIf="icon" [class.faded]="!counter"></i>
	<span [innerHTML]="text"></span>
	<i class="icon icon-drop-down" *ngIf="!hideArrow"></i>
	<span class="notifications" *ngIf="counter">
		<span class="counter">{{counter}}</span>
	</span>
</button>
<div class="dropdown-menu">
	<div class="content">
		<ng-content></ng-content>
	</div>
</div>