<form action="#" [formGroup]="form">
	<div class="form-group"
		*ngFor="let field of fields; let i = index"
		[class.error]="ifError(field)"
		[class.success]="ifSuccess(field)"
	>
		<label for="{{field}}">{{config[field][0]}}</label>
		<input type="text" class="input small" formControlName="{{field}}" id="{{field}}" placeholder="{{placeholders[i]}}"
		>
		<div class="tooltip" [class.disabled]="ifSuccess(field)" [innerHTML]="createTooltip(config[field]).parseBytecodes()"></div>
	</div>
	<label>Тип вопроса</label>
	<div class="row collapsed-row">
		<div class="col-xs-3" *ngFor="let variant of variants; let i = index">
			<div class="form-group" [class.error]="ifError('type')">
				<div class="checkbox" [class.error]="ifError('type')">
					<input type="radio" id="variant{{i}}" formControlName="type" [value]="variant.value">
					<label for="variant{{i}}">{{variant.label}}</label>
				</div>
			</div>
		</div>
	</div>
	<hr class="light">
	<div class="align-center">
		<button type="button" class="btn btn-success" (click)="submit()">
		<i class="icon icon-plus offset-right"></i>
		{{mode === "edit" ? "Сохранить" : "Добавить"}}
	</button>
	</div>
</form>




