import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { SettingsService } from './../../services/settings';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, ifSuccess, ifError, createForm
} from './../../utils';

@Component({
	selector: 'form-class-cost-add',
	templateUrl: './template.html',
	providers: [  ]
})
export class FormClassCostAdd extends AbstractForm {

	config: any = {
		teacher: ["", "r"],
		room: ["", "r"],
		type: ["", "r"],
		subtype: ["", "r"]
	}

	@Input() rooms: any [] = [];
	@Input() teachers: any [] = [];
	@Input() roomsKeys: any = {};
	@Input() types: any [] = [];
	@Input() typesKeys: any = {};
	@Input() kind: string = "";
	@Output() onCreate: EventEmitter<any> = new EventEmitter();
	subtypes: any[] = [];

	constructor(private settingsService: SettingsService){
		super();
	}

	ngOnInit(){
		this.form = createForm(this.config);
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
	}

	updateSubtypesOptions(){
		let key = this.form.controls['type'].value;
		if (!key){
			this.subtypes = [];
			return;
		}
		this.subtypes = this.typesKeys[key].occupationsubtype_set || [];
	}

	afterSubmit(){
		this.form.value.department = this.roomsKeys[this.form.value.room].department;
		this.form.value.kind = this.kind;
		this.settingsService.createCost(this.form.value).subscribe((res: any)=> {
			this.onCreate.emit(res);
			if (this.modal){
				this.modal.hide();
			}
		});
	}

	getTeacherName(teacher: any){
		return teacher.first_name && teacher.last_name ? `${teacher.first_name} ${teacher.last_name}` : teacher.email;
	}

}