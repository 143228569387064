import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelectOption } from './../interfaces/selectOption';
import { NotificationType } from './../interfaces/notification';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType
} from './../utils/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from './../services/users';
import { CONFIG } from './../config';
import { NotificatorService } from './../services/notificator';

@Component({
	selector: 'registration-page',
	templateUrl: './registration-page.html',
	providers: [ UsersService, NotificatorService ]
})
export class RegistrationPage {


	constructor(
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private router: Router
	){

		// console.log(this.router);

	}

	onSubmit(data: any){
		this.usersService.registrateUser(data).subscribe((res: any)=> {
			// console.log(res);
			this.router.navigateByUrl('/login');
			this.notificatorService.push({
				title: "Отлично!",
				text: "Вы успешно зарегистрировались, используйте свои данные для входа в систему.",
				type: NotificationType.Success
			});
		}, (err: any)=> {
			// console.log(err);
			this.notificatorService.push({
				title: "Ошибка",
				text: createErrorText(err.error),
				type: NotificationType.Error
			});
		});
	}

}