import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class BirthdaysService {

	constructor(){

	}

	getBirthdays(){
		let subject = new ReplaySubject();
		subject.next(createBirthdays());
		subject.complete();
		return subject;
	}

}

let names = [
	"Willie Frances Cochran",
	"Laurel Kristie Fernandez",
	"Cody Myron Daniels",
	"Elisa Marty Richmond",
	"Adolfo Connie Mcdaniel",
	"Jerrold Florence Patrick",
	"Aida Carmine Coleman",
	"Brady Minh Ali",
	"Normand Leanna Meadows",
	"Shauna Lara Pruitt"
];

function createBirthdays() {
	let array: any[] = [];
	let max = Math.randomInt(0, 30);
	if (max < 2) max = 0;
	for (let i = 0; i < max; i++){
		array.push({
			time: new Date(new Date().getTime() + Math.randomInt(100000000, 20000000000)).getTime(),
			name: names[Math.randomInt(0, names.length-1)],
			age: Math.randomInt(10, 60),
			checked: Math.random() > 0.5
		});
	}
	return array;
}