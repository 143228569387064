import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { CONFIG } from './../config';

@Injectable()
export class UsersService {

	subcategories: any = {
		login: "login/",
		logout: "logout/",
		token: "token/",
		tokenRefresh: "token-refresh/",
		getUsername: "user/",
		passwordReset: "password/reset/",
		passwordChange: "password/change/",
		registration: "registration/",
		// users
		list: "list/",
		current: "current/",
		birthdays: "birthdays/",
		groups: "groups/"
	}

	headers: HttpHeaders = new HttpHeaders();

	constructor(private http: HttpClient){
		this.headers.append('Content-Type', 'application/json');
	}

	registrateUser(data: any){
		/*
			СТЕПА!!!!!!!!!!!! МНЕ НЕ НРАВИТСЯ ЧТО ТЫ МЕНЯ НАЕБАЛ!
			И Я ВЫНУЖДЕН ДЕЛАТЬ ВОТ ЭТУ ХУЙНЮ!!!
		*/
		let formData: FormData = new FormData();
		formData.append("email", data.email || "");
		formData.append("mobile", data.phone || "");
		formData.append("first_name", data.firstName);
		formData.append("last_name", data.lastName);
		formData.append("password1", data.password);
		formData.append("password2", data.password);
		return this.http.post(CONFIG.api.auth+this.subcategories.registration, formData);

		// return this.http.post(CONFIG.api.auth+this.subcategories.registration, {
		// 	email: data.email || "",
		// 	mobile: data.phone || "",
		// 	first_name: data.firstName,
		// 	last_name: data.lastName,
		// 	password1: data.password,
		// 	password2: data.password2
		// });
	}

	login(data: any){
		return this.http.post(CONFIG.api.auth+this.subcategories.token, {
			email: data.email,
			password: data.password,
		});
	}

	passwordReset(data: any){
		return this.http.post(CONFIG.api.auth+this.subcategories.passwordReset, {
			email: data.email
		});
	}

	getTemporaryCurrentUser(){
		return {
			assignedDepartment: null,
			birthday: null,
			createdAt: "",
			dateJoined: "",
			dateOfBirth: null,
			email: "example@mail.com",
			firstName: "Firstname",
			groups: [],
			id: 2,
			image: null,
			isActive: true,
			isAdmin: false,
			isSuperuser: false,
			lastLogin: "",
			lastName: "Lastname",
			role: "",
			updatedAt: "",
			userPermissions: []
		};
	}

	getCurrentUser(){
		return this.http.get(CONFIG.api.users+this.subcategories.current);
	}

	refreshToken(token: string){
		return this.http.post(CONFIG.api.auth+this.subcategories.tokenRefresh, {
			refresh: token
		});
	}

	logout(){
		return this.http.post(CONFIG.api.auth+this.subcategories.logout, {});
	}

	getUsers(){
		return this.http.get(CONFIG.api.users+this.subcategories.list);
	}

	getAdmins(){
		return this.fetchUsersByGroup("ADMIN");
	}

	getTeachers(){
		return this.fetchUsersByGroup("TEACHER");
	}

	getClients(){
		return this.fetchUsersByGroup("CLIENT");
	}

	private fetchUsersByGroup(role: string){
		let subject: ReplaySubject<any> = new ReplaySubject();
		this.http.get(CONFIG.api.users+this.subcategories.list).subscribe((res: any[])=> {
			subject.next(res.filter(u => u.role && u.role.match(new RegExp(role, "gim"))));
		});
		return subject;
	}

	getUser(id: number){
		return this.http.get(CONFIG.api.users+this.subcategories.list+id+"/");
	}

	getUserName(user: any): string {
		return user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : "no name";
	}

	getHighlihgtedUserName(user: any, search: string = ""){
		let name = this.getUserName(user);
		if (!search) return name;
		return name.replace(new RegExp("("+search+")", "gim"), "<span class=\"highlight\">$1</span>");
	}

	getUserDate(dateString: string): string {
		return dateString ? new Date(dateString).parseDate('dd.mm.yyyy') : 'дд.мм.гггг';
	}

	getUserPhone(user: any){
		return user.mobile || 'Не указано';
	}

	getUserAddress(user: any){
		return user.address || 'Не указано';
	}

	getUserEmail(user: any){
		return user.email || 'Не указано';
	}

	getUserContacts(user: any){
		return user.contact || 'Не указано';
	}

	getUserLink(user: any){
		return `http://${location.host}/#/users/${user.pk}/info`;
	}

	// survey
	createUserSurvey(user: any, data: any, questions: any[]){
		let result: any = {
			user: user.pk,
			profile: user.profile.pk,
			quiz: 4,
			user_survey_answers: []
		};
		for (let key in data){
			key = key.toString();
			let questionId: number = parseInt(key.replace(/question\_/gim, ''));
			let question = questions.find(q => q.pk === questionId);
			let answers: any[] = [];
			question.answers.map((a, i)=> {
				if (data[key][i] === true){
					answers.push(a.pk);
				}
			});
			result.user_survey_answers.push({
				question: questionId,
				resourcetype: question.user_answers_type,
				answer: (
					question.answers_type === "CHECKBOX" ? answers :
					question.answers_type === "SELECT" ? parseInt(data[key]) : data[key]
				)
			});
		}
		console.log(result);
		return this.http.post(CONFIG.api.users+"survey/", result);
	}

}