import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef, QueryList
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview, isOneOfFilled
} from './../utils';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CONFIG } from './../config';
import { Modal, Popover } from './../components';
import { Dialog } from './dialog';


export abstract class AbstractForm {

	@Output('onSubmit') onSubmit: EventEmitter<any> = new EventEmitter();
	@Output('onReset') onReset: EventEmitter<any> = new EventEmitter();
	@Output('onError') onError: EventEmitter<any> = new EventEmitter();


	config: any;
	validators: any;
	form: FormGroup;
	mode: AbstractFormModes = AbstractFormModes.Default;
	id: string | number;
	doReset: boolean = true;

	ifError: Function;
	ifSuccess: Function;
	createTooltip: Function = createTooltip;
	fieldType: Function = fieldType;
	isOneOfFilled: Function = isOneOfFilled;

	renderImagePreview: Function = renderImagePreview;
	files: any;

	dialog: Dialog;

	resetEnable: boolean;

	@ViewChild('imagePreview') imagePreview;
	@ViewChild('imageInput') imageInput;

	@ViewChild('modal') modal: Modal;
	@ViewChild('popover') popover: Popover;
	@ViewChild('firstFocus') firstFocus: ElementRef;

	@ViewChildren('pseudoInput') pseudoInputs: QueryList<any>;

	constructor(){
		this.dialog = new Dialog();
		this.resetEnable = true;
	}

	ngOnInit(){
		this.form = createForm(this.config, this.validators);
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
	}

	setFiles(files: any, event?: any, output?: any){
		this.files = files;
		if (!files && output){
			output.setAttribute('src','assets/image.png');
		}
	}

	submit(){
		validateAllFields(this.form);
		if (this.form.valid){
			this.onSubmit.emit(this.form.value);
			this.afterSubmit();
			if (this.resetEnable){
				this.reset();
			}
		} else {
			this.onError.emit(this.form);
		}
	}

	reset(doNotTouchModals?: boolean){
		if (!this.doReset) return;
		this.onReset.emit(this.form.value);
		this.form.reset();
		this.id = undefined;
		if (this.pseudoInputs.length){
			this.pseudoInputs.map((pseudoInput: any)=> {
				if (pseudoInput.reset) pseudoInput.reset();
			});
		}
		if (this.imagePreview){
			this.setFiles(null, null, this.imagePreview.nativeElement);
		}
		this.afterReset();
		// чтобы модалки не закрывали друг друга бесконечно
		if (doNotTouchModals) return;
		if (this.modal){
			this.modal.hide();
		}
		if (this.popover){
			this.popover.hide();
		}
	}

	afterSubmit(){
	}

	afterReset(){
	}

	afterPatch(context?: AbstractForm){

	}

	patch(data: any, id?: string | number){
		this.form.reset();
		this.id = id;
		for (let key in this.form.controls){
			if (data[key] && this.form.controls[key]){
				this.form.controls[key].setValue(data[key]);
				this.form.controls[key].markAsUntouched();
			}
		}
		if (data.image){
			this.imagePreview.nativeElement.setAttribute('src', data.image);
		}
		this.afterPatch(this);
	}

}

export enum AbstractFormModes {
	Default = "default",
	Edit = "edit"
};