import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class DebtsService {

	constructor(){

	}

	getDebts(){
		let subject = new ReplaySubject();
		subject.next(createDebts());
		subject.complete();
		return subject;
	}

}

let names = [
	"Willie Frances Cochran",
	"Laurel Kristie Fernandez",
	"Cody Myron Daniels",
	"Elisa Marty Richmond",
	"Adolfo Connie Mcdaniel",
	"Jerrold Florence Patrick",
	"Aida Carmine Coleman",
	"Brady Minh Ali",
	"Normand Leanna Meadows",
	"Shauna Lara Pruitt"
];

function createDebts() {
	let array: any[] = [];
	let max = Math.randomInt(0, 30);
	if (max < 2) max = 0;
	for (let i = 0; i < max; i++){
		array.push({
			id: Math.randomInt(100000, 10000000),
			name: names[Math.randomInt(0, names.length-1)],
			debt: Math.randomInt(-10000, 10000)
		});
	}
	return array;
}