import { Component, ViewChild, Input } from '@angular/core';
import { UsersService, NotificatorService } from './../../services';
import { NotificationType } from './../../interfaces';
import { CONFIG } from './../../config';
import { toCamelKeys } from './../../utils';
import { Calendar } from './../calendar';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';

@Component({
	selector: 'sidebar',
	templateUrl: './template.html',
	host: {
		"[class.collapsed]": "collapsed"
	},
	providers: [ UsersService, NotificatorService ]
})
export class Sidebar {

	@Input('collapsed') collapsed: boolean;
	@ViewChild('calendar') calendar: Calendar;

	currentPath: string = "";

	user: any;

	constructor(
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private router: Router
	){

		this.user = this.usersService.getTemporaryCurrentUser();
		this.routeEvent(this.router);
	}

	ngOnInit(){
		this.usersService.getCurrentUser().subscribe((res: any)=> {
			// console.log(toCamelKeys(res));
			this.user = toCamelKeys(res);
		}, (err: any)=> {
			this.notificatorService.push({
				title: "Пользователь",
				text: "Произошла ошибка при загрузке информации о пользователе",
				type: NotificationType.Error
			});
		});
		let calendarStart = new Date().getDayStart(),
				calendarEnd = calendarStart.clone().addDays(12);
		setTimeout(()=> {
			this.calendar.setDay(calendarStart);
			this.calendar.setDay(calendarEnd);
		}, 500);
	}

	collapse(){
		this.collapsed = true;
	}
	expand(){
		this.collapsed = false;
	}
	toggle(){
		this.collapsed ? this.expand() : this.collapse();
	}

	logout(){
		this.usersService.logout().subscribe((res: any)=> {
			this.notificatorService.push({
				title: "Пользователь",
				text: "Ключи уничтожены, следы заметены"
			});
			localStorage.removeItem("LOGOPED_TOKEN");
			localStorage.removeItem('LOGOPED_TOKEN_REFRESH');
			this.router.navigateByUrl('/login');
		});
	}

	routeEvent(router: Router){
		router.events.subscribe(e => {
			if (e instanceof NavigationEnd){
				this.currentPath = e.url;
			}
			if (e instanceof ActivationStart){
			}
		});
	}

	routeIs(url: string){
		return this.currentPath.match(new RegExp(url, "gim"));
	}

}
