import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef, QueryList
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createErrorText, fieldType, createConfig, makeFlatValue
} from './../../utils';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'input-group',
	templateUrl: './template.html',
	host: {
		"(keydown)": "onKeyDown($event)"
	}
})
export class InputGroup {

	@Input('config') config: any;
	@Input('externalData') externalData: any = {};
	@Input('placeholder') placeholder: string = "";
	@Input('min') min: number = 1;
	@Input('max') max: number = 10;
	@ViewChildren('input') inputs: QueryList<ElementRef>;

	@Output('onInit') onInit: EventEmitter<any> = new EventEmitter();
	@Output('onChange') onChange: EventEmitter<any> = new EventEmitter();
	@Output('onChangeSpecial') onChangeSpecial: EventEmitter<any> = new EventEmitter();
	@Output('onAdd') onAdd: EventEmitter<any> = new EventEmitter();
	@Output('onDelete') onDelete: EventEmitter<any> = new EventEmitter();

	indexes: any[] = [];
	form: FormGroup;
	key: string;
	formFields: string[] = [];
	ifError: Function;
	ifSuccess: Function;
	fieldType: Function = fieldType;

	constructor(){

	}

	ngOnInit(){
		if (!this.config) {
			console.warn('InputGroup => no config passed!');
			return;
		}
		this.key = Object.keys(this.config)[0];
		// this.makeGroupFromConfig();

		this.form = new FormGroup({});
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
		// this.onChange.emit(this);
		for (let i = 0; i < this.min; i++){
			this.add();
		}
		this.onInit.emit(this);
	}

	add(value?: string, byButton?: boolean){
		let name = Math.randomHash();
		this.form.addControl(name, new FormControl('', createConfig(this.config, true)[this.key]));
		this.indexes.push(name);
		if (value){
			setTimeout(()=> {
				this.form.controls[name].setValue(value.toString());
				this.form.controls[name].markAsUntouched();
			});
		}
		this.onChange.emit(this);
		if (byButton){
			this.onAdd.emit(this.form.controls[name]);
		}
		setTimeout(()=> {
			if (!value && byButton){
				this.inputs.last.nativeElement.focus();
			}
		});
	}

	change(event: any, key: string, index: number){
		window.waitForFinalEvent(()=> {
			this.onChangeSpecial.emit({
				value: this.form.value[key],
				index: index,
				key: key,
				formControl: this.form.controls[key],
				externalData: this.externalData
			});
		}, 500, key);
	}

	remove(name: string, i: number){
		this.form.removeControl(name);
		this.indexes.splice(i, 1);
		this.onChange.emit(this);
		this.onDelete.emit(i);
	}

	reset(){
		let i = 0;
		for (let key in this.form.controls){
			this.remove(key.toString(), i);
			i++;
		}
		this.indexes = [];
	}

	getPlaceholder(index: number){
		return `${this.config[this.key][0]}${index ? ' #'+(index+1) : ''}`;
	}

	onKeyDown(event: any){
		if (event.keyCode === 13){
			if (Object.keys(this.form.controls).length < this.max){
				this.add('', true);
			}
		} else if (event.keyCode === 46){

		}
	}

}