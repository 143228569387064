<main-header #mainHeader *ngIf="uiAvailable()" (onUiCollapse)="onUiCollapse($event)"></main-header>

<notificator></notificator>

<sidebar
	*ngIf="uiAvailable()"
	[collapsed]="uiCollapsed"
></sidebar>

<div class="page-content"
	[class.collapsed]="uiCollapsed"
	[class.static]="!uiAvailable()"
>
	<router-outlet></router-outlet>
</div>

<div id="dragTarget"></div>