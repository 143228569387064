import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys
} from './../utils';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CONFIG } from './../config';
import { DepartmentsService, NotificatorService } from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';


@Component({
	selector: 'departments-detail-page',
	templateUrl: './departments-detail-page.html',
	providers: [ DepartmentsService, NotificatorService ]
})
export class DepartmentsDetailPage {

	id: string;
	data: any = {};
	dataLoading: boolean = true;

	constructor(
		private route: ActivatedRoute,
		private departmentsService: DepartmentsService,
		private notificatorService: NotificatorService,
	){

		this.id = route.snapshot.params["id"];

		this.departmentsService.getDepartment(this.id).subscribe((res: any)=> {
			setTimeout(()=> {
				this.data = toCamelKeys(res);
				this.dataLoading = false;
				console.log(this.data);
			}, 500);
		});

	}

}
