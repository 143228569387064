import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, atLeastOneChecked
} from './../utils';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { CONFIG } from './../config';
import { UsersService, NotificatorService, SettingsService } from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';


@Component({
	selector: 'user-survey-page',
	templateUrl: './user-survey-page.html',
	providers: [ NotificatorService, UsersService, SettingsService ]
})
export class UserSurveyPage {

	userId: string;
	user: any = null;
	loaded: boolean = false;
	// raw data
	_questions: any[] = [];
	_answers: any[] = [];
	// calculated data
	questions: any[] = [];

	form: FormGroup;
	error: boolean = false;

	ifSuccess: Function;
	ifError: Function;

	constructor(
		private route: ActivatedRoute,
		private notificationService: NotificatorService,
		private usersService: UsersService,
		private settingsService: SettingsService
	){

		this.userId = route.snapshot.params["userId"];

	}

	ngOnInit(){

		this.form = createForm();
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);

		this.usersService.getUser(parseInt(this.userId)).subscribe((user)=> {
			this.user = user;
			console.log("user", user);

			this.settingsService.getQuizQuestions().subscribe((questions: any)=> {
				this._questions = questions;
				console.log("questions", questions);

				this.settingsService.getQuizAnswers().subscribe((answers: any)=> {
					this._answers = answers;
					console.log("answers", answers);

					this.OnLoaded();
				});

			});
		});
	}

	OnLoaded(){
		this.loaded = true;

		let questions: any[] = [];
		let formBuilder: FormBuilder = new FormBuilder();

		this._questions.map((question) => {


			let newQuestion: any = JSON.parse(JSON.stringify(question));
			newQuestion.answers = this._answers.filter(a => a.question === newQuestion.pk);
			// could be a case when we don't have an answers at all
			// so we need to filter out empty questions
			if (newQuestion.answers_type === "CHECKBOX" || newQuestion.answers_type === "RADIO"){
				if (!newQuestion.answers.length){
					return;
				}
			}

			// add FormControl
			if (newQuestion.answers_type === "CHECKBOX"){
				let controls: FormControl[] = newQuestion.answers.map((q: any)=> {
					return new FormControl(false);
				});
				let array: FormArray = new FormArray(controls, atLeastOneChecked);
				this.form.addControl("question_"+newQuestion.pk, array);
			} else {
				let control: FormControl = new FormControl(
					newQuestion.answers_type === "BOOL" ? false : "",
					this.getValidators(newQuestion.answers_type)
				);
				this.form.addControl("question_"+newQuestion.pk, control);
			}

			questions.push(newQuestion);
		});

		this.questions = questions.sort((a, b)=> a.question_sort - b.question_sort);
		console.log("form", this.form);
	}

	getQuestionTitle(question: any){
		return question.subject.replace(/\#\{user\}/gim, this.usersService.getUserName(this.user));
	}

	private getValidators(type: string): ValidatorFn[] {
		let validators: ValidatorFn[] = [ Validators.required ];
		if (type === "TEXT"){
			validators.push(Validators.minLength(2));
			validators.push(Validators.maxLength(200));
		}
		return validators;
	}

	submit(){
		validateAllFields(this.form);
		// console.log(this.form);
		if (this.form.valid){
			this.error = false;
			this.usersService.createUserSurvey(this.user, this.form.value, this.questions).subscribe((res)=> {
				this.notificationService.push({
					title: "QUIZ",
					text: "Бланк успешно сохранен",
					type: NotificationType.Success
				});
			}, (error)=> {
				this.notificationService.push({
					title: "QUIZ",
					text: "Произошла ошибка",
					type: NotificationType.Error
				});
			});
			// console.log(this.form.value);
		} else {
			this.error = true;
			// console.warn("Errors", this.form.value);
		}
	}

	addOrRemoveCheckboxValue(input: HTMLInputElement, formControlKey: string, questionIndex: number, answerIndex: number){
		// console.log(input.value, formControlKey, questionIndex, answerIndex);
		// console.log(this.form.controls[formControlKey].setValue())
		let question = this.questions.find(q => q.pk === questionIndex);
		if (!question) return;
		let answer = question.answers.find((a, i)=> i === answerIndex);
		if (!answer) return;

		setTimeout(()=> {
			answer.selected = input.checked;

			this.form.controls[formControlKey].setValue(
				question.answers.map((a => a.selected ? true : false))
			);
		});
		// console.log(question)
	}

	setRadioValue(input: HTMLInputElement, formControlKey: string, questionIndex: number, answerIndex: number){
		let question = this.questions.find(q => q.pk === questionIndex);
		if (!question) return;
		let answer = question.answers.find((a, i)=> i === answerIndex);
		if (!answer) return;

		setTimeout(()=> {
			answer.selected = input.checked;

			this.form.controls[formControlKey].setValue(answer.pk);
		});
	}

}
