<button class="btn btn-small btn-primary" (click)="modal.show()">Добавить</button>

<modal [title]="'Добавить стоимость'"  class="align-left" #modal>
	<form action="#"  [formGroup]="form">
		<label>Преподаватель</label>
		<div class="form-group" [class.error]="ifError('teacher')" [class.success]="ifSuccess('teacher')">
			<i class="icon icon-drop-down input-icon small"></i>
			<select class="input small" formControlName="teacher">
				<option [value]="teacher.pk" *ngFor="let teacher of teachers">{{getTeacherName(teacher)}}</option>
			</select>
		</div>
		<label>Комната</label>
		<div class="form-group" [class.error]="ifError('room')" [class.success]="ifSuccess('room')">
			<i class="icon icon-drop-down input-icon small"></i>
			<select class="input small" formControlName="room">
				<option [value]="room.pk" *ngFor="let room of rooms">{{room.title}}</option>
			</select>
		</div>
		<label>Тип занятия</label>
		<div class="form-group" [class.error]="ifError('type')" [class.success]="ifSuccess('type')">
			<i class="icon icon-drop-down input-icon small"></i>
			<select class="input small" formControlName="type" (change)="updateSubtypesOptions()">
				<option [value]="type.pk" *ngFor="let type of types">{{type.title}}</option>
			</select>
		</div>
		<label>Подтип занятия</label>
		<div class="form-group" [class.error]="ifError('subtype')" [class.success]="ifSuccess('subtype')">
			<i class="icon icon-drop-down input-icon small"></i>
			<select class="input small" formControlName="subtype">
				<option [value]="subtype.pk" *ngFor="let subtype of subtypes">{{subtype.title}}</option>
			</select>
		</div>
		<hr class="light">
		<div class="btn-group align-right">
			<button type="button" class="btn btn-small btn-primary" (click)="submit()">Добавить</button>
			<button type="button" class="btn btn-small" (click)="modal.hide()">Отмена</button>
		</div>
	</form>
</modal>