import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class TasksService {

	constructor(){

	}

	getTasks(){
		let subject = new ReplaySubject();
		subject.next(null);
		subject.complete();
		return subject;
	}

}