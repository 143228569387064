import { Component, SimpleChanges } from '@angular/core';
import { CONFIG } from './../config';
import {
	SettingsService, NotificatorService,
	DepartmentsService, UsersService
} from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';

@Component({
	selector: 'occupations-cost-page',
	templateUrl: './occupations-cost-page.html',
	providers: [
		NotificatorService, SettingsService,
		DepartmentsService, UsersService
	]
})
export class OccupationsCostPage {

	departments: any[] = [];
	teachers: any = {};
	teachersKeys: string[] = [];

	selectedTeachers: any[] = [];
	selectedRooms: any[] = [];

	rooms: any[] = [];
	roomsKeys: any = {};

	occupationTypes: any[] = [];
	occupationTypesKeys: any = {};
	occupationSubtypesKeys: any = {};

	flatOccupationTypes: any[] = [];

	costListIndividual: any[] = [];
	costListIndividualFiltered: any[] = [];
	costListGroup: any[] = [];
	costListGroupFiltered: any[] = [];

	activeCost: any = null;
	activeCostIndex: number = 0;

	filter: any = {
		department: null,
		type: '',
		subtype: ''
	}
	filtered: any = {
		teachers: {},
		rooms: []
	}

	sortableKeys: any[] = [
		{key: 'room', value: "Комната"},
		{key: 'parentTitle', value: "Тип занятия"},
		{key: 'title', value: "Подтип занятия"}
	];


	constructor(
		private settingsService: SettingsService,
		private notificatorService: NotificatorService,
		private departmentsService: DepartmentsService,
		private usersService: UsersService
	){

		setTimeout(()=> {

			// departments
			this.departmentsService.getDepartments().subscribe((res: any)=> {
				this.departments = res;
			});
			// users
			this.usersService.getUsers().subscribe((res: any)=> {
				res.map((user: any)=> {
					if (user.role !== "TEACHER" && user.role !== "LEADING_SPECIALIST") return;
					if (!this.teachers[user.role]){
						this.teachers[user.role] = [];
						this.teachersKeys.push(user.role);
					}
					this.teachers[user.role].push(user);
				});
				console.log('users', res, this.teachers);
				this.filterItems();
			});
			// rooms
			this.departmentsService.getRooms().subscribe((res: any)=> {
				this.rooms = res;
				for (let i = 0; i < res.length; i++){
					let room = res[i];
					this.roomsKeys[room.pk] = room;
				}
				console.log('rooms', res);
				this.filterItems();
			});
			//occupation types
			this.settingsService.getOccupationTypes().subscribe((res: any)=> {
				console.log('types', res);
				this.occupationTypes = res;
				let flatArray: any[] = [];
				this.occupationTypes.map((type: any)=> {
					// type.isParent = "Тип занятия";
					// flatArray.push(type);
					this.occupationTypesKeys[type.pk] = type;
					type.occupationsubtype_set.map((subtype: any)=> {
						subtype.parent = type;
						subtype.parentTitle = type.title;
						subtype.title = subtype.subtitle;
						flatArray.push(subtype);
						this.occupationSubtypesKeys[subtype.pk] = subtype;
					});
				});
				this.flatOccupationTypes = flatArray;
				console.log('flat-types', flatArray);

				this.fetchCostsList();
			});

		}, 500);

	}

	fetchCostsList(){
		this.settingsService.getCostList().subscribe((res: any)=> {
			this.costListIndividual = res.filter(item => item.kind === "I");
			this.costListGroup = res.filter(item => item.kind !== "I");
			this.filterItems();
			this.getTeachersList();
			console.log('costs', res);
		});
	}

	changeDepartment(value?: string){
		this.filter.department = value ? parseInt(value) : null;
		this.filterItems();
	}

	changeOccupationType(value?: string){
		this.filter.type = value;
		window.waitForFinalEvent(()=> {
			this.filterItems();
		}, 500, 'changeOccupationType');
	}

	changeOccupationSubtype(value?: string){
		this.filter.subtype = value;
		window.waitForFinalEvent(()=> {
			this.filterItems();
		}, 500, 'changeOccupationSubtype');
	}

	filterItems(){
		// teachers
		let newTeachers: any = {};
		this.teachersKeys.map((k: any)=> {
			newTeachers[k] = this.getFilteredTeachers(k);
		});
		this.filtered.teachers = newTeachers;
		// rooms
		this.filtered.rooms = this.filter.department ? this.rooms.filter((r: any)=>{
			return r.department === this.filter.department;
		}) : this.rooms;
		this.getTeachersList();
	}

	private doesTeacherInList(teacherId: any): boolean {
		let state: boolean = false;
		for (let i = 0; i < this.selectedTeachers.length; i++){
			if (teacherId === this.selectedTeachers[i].pk){
				state = true;
				return state;
			}
		}
		return state;
	}

	private doesRoomInList(roomId: any): boolean {
		let state: boolean = false;
		for (let i = 0; i < this.selectedRooms.length; i++){
			if (roomId === this.selectedRooms[i].pk){
				state = true;
				return state;
			}
		}
		return state;
	}

	getTeachersList(){
		this.selectedTeachers = [];
		let arr: any[] = [];
		for (let k in this.filtered.teachers){
			this.filtered.teachers[k].map((t: any)=> {
				if (t.selected){
					arr.push(t);
				}
			});
		}
		this.selectedTeachers = arr;
		arr = [];
		this.filtered.rooms.map((t: any)=> {
			if (t.selected){
				arr.push(t);
			}
		});
		this.selectedRooms = arr;

		this.costListIndividualFiltered = this.costListIndividual
			.filter(c => this.doesTeacherInList(c.performer) && this.doesRoomInList(c.room))
			.filter(c => this.occupationTypesKeys[c.type].title.match(new RegExp(this.filter.type, "gim")))
			.filter(c => this.occupationSubtypesKeys[c.subtype].title.match(new RegExp(this.filter.subtype, "gim")))
			.sort((a: any, b: any)=> {
				return a.changed.created_on - b.changed.created_on;
			});
		this.costListGroupFiltered = this.costListGroup
			.filter(c => this.doesTeacherInList(c.performer) && this.doesRoomInList(c.room))
			.filter(c => this.occupationTypesKeys[c.type].title.match(new RegExp(this.filter.type, "gim")))
			.filter(c => this.occupationSubtypesKeys[c.subtype].title.match(new RegExp(this.filter.subtype, "gim")))
			.sort((a: any, b: any)=> {
				return a.changed.created_on - b.changed.created_on;
			});
	}

	selectAllTeachersByKey(key: string, isSelected: boolean){
		this.filtered.teachers[key].map(teacher => teacher.selected = isSelected );
	}

	selectAllTeachers(isSelected: boolean){
		this.selectAllTeachersByKey("TEACHER", isSelected);
		this.selectAllTeachersByKey("LEADING_SPECIALIST", isSelected);
		this.getTeachersList();
	}

	selectAllRooms(isSelected: boolean){
		this.filtered.rooms.map(r => r.selected = isSelected);
		this.getTeachersList();
	}

	highlightSearch(text: string, key: string){
		if (text.match(new RegExp(this.filter[key], "gim"))){
			return text.replace(new RegExp(`(${this.filter[key]})`, "gim"), "<b class='search'>$1</b>");
		} else {
			return text;
		}
	}

	getFilteredTeachers(key: string){
		// console.log(this.filter.department);
		let result = this.filter.department ? this.teachers[key].filter((t: any)=> {
			return t.assigned_department && (t.assigned_department === this.filter.department);
		}) : this.teachers[key];
		return result;
	}

	getCost(cost: any, onlyValue?: boolean){
		return cost && cost.value ? cost.value + (onlyValue ? "" : "грн") : '';
	}

	isSameCost(cost: any){
		return (this.activeCost) && (cost) && (this.activeCost.pk === cost.pk)
	}

	addNewCost(cost: any){
		this.notificatorService.push({
			title: "Class Cost",
			text: `Ценник создан`,
			type: NotificationType.Success
		});
		this.fetchCostsList();
		// this.filterItems();
		// this.getTeachersList();
	}

	/*cost editing*/
	edit(cost: any, index?: number){
		this.activeCostIndex = index;
		this.activeCost = JSON.parse(JSON.stringify(cost));
	}

	save(){
		for (let i = 0; i < 5; i++){
			this.activeCost.classes_cost[i] = {
				value: this.activeCost.classes_cost[i] ? this.activeCost.classes_cost[i].value : null,
				duration: 30 + i * 15
			};
		}
		this.settingsService.updateCost(this.activeCost).subscribe((res: any)=> {
			// console.log(res);
			this.fetchCostsList();
			// this.costListIndividualFiltered.splice(this.activeCostIndex, 1, res);
			this.notificatorService.push({
				title: "Class Cost",
				text: `Ценник обновлен`
			});
		});
		this.edit(null);
	}

	delete(cost: any, index: number){
		this.settingsService.deleteCost(cost.pk).subscribe((res: any)=> {
			// this.costListIndividualFiltered.splice(index, 1);
			this.fetchCostsList();
			this.notificatorService.push({
				title: "Class Cost",
				text: `Ценник удален`,
				type: NotificationType.Error
			});
		});
	}

	handleCostPrice(value: string | number, index: number){
		if (!this.activeCost) return;
		value = parseInt(value.toString());
		this.activeCost.classes_cost[index] = {
			value: value
		};
		// console.log(value, index);
	}

}
