<div class="chart-group">
	<div class="header">
		<div class="row">
			<div class="col-xs-7">
				<div class="title">{{ config.title }}</div>
			</div>
			<div class="col-xs-5 align-right">
				<div class="navigation">
					<button type="button" class="btn-nav btn-prev">&lt;</button>
					<span class="caption">
						{{ config.dateFrom }} &ndash; {{ config.dateTo }}
					</span>
					<button type="button" class="btn-nav btn-next">&gt;</button>
				</div>
			</div>
		</div>
	</div>
	<div class="chart-cover">
		<ul class="chart-legend clear">
			<li class="item" *ngFor="let data of dataset; let i = index;">
				<span class="point" [ngStyle]="{'background-color': config.colors[i]}"></span>
				<span class="name">{{data.name}}</span>
			</li>
		</ul>
		<div class="graph-outer">
			<svg xmlns="http://www.w3.org/2000/svg" [attr.width]='config.width' [attr.height]='config.height'>
				<defs>
					<!-- for label -->
					<mask id="7jvab" width="2" height="2" x="-1" y="-1">
						<path fill="#fff" d="M22 17h80v37H22z"/>
						<path d="M55.5 47H27a5 5 0 0 1-5-5V22a5 5 0 0 1 5-5h70a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H68.64l-6.57 6.571z"/>
					</mask>
					<filter id="7jvaa" width="150" height="112" x="-13" y="-18" filterUnits="userSpaceOnUse">
						<feOffset dy="5" in="SourceGraphic" result="FeOffset1023Out"/>
						<feGaussianBlur in="FeOffset1023Out" result="FeGaussianBlur1024Out" stdDeviation="10 10"/>
					</filter>
					<filter id="shadow">
						<feDropShadow dx="0.2" dy="0.4" stdDeviation="0.5"/>
					</filter>
				</defs>
				<!-- lines vertial-->
				<g class="lines-vertical">
					<line *ngFor="let label of config.labels; let i = index" [attr.x1]="( config.width / config.labels.length * i ) + config.offset" [attr.y1]="config.height - 12" [attr.x2]="( config.width / config.labels.length * i ) + config.offset" [attr.y2]="0" stroke="#e7ebf0" />
				</g>
				<!-- lines horizontal-->
				<g class="lines-horizontal">
					<line *ngFor="let lines of verticalData; let i = index" [attr.x1]="0 + config.offset" [attr.y1]="lines.coord  + config.offset" [attr.x2]="config.width - config.offset" [attr.y2]="lines.coord + config.offset" stroke="#e7ebf0" />
				</g>
				<!-- label -->
				<g transform="translate(0,0)" class="chart-label" opacity="0">
					<g filter="url(#7jvaa)">
						<path fill="none" d="M55.5 47H27a5 5 0 0 1-5-5V22a5 5 0 0 1 5-5h70a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H68.64l-6.57 6.571z" mask="url(&quot;#7jvab&quot;)"/>
						<path fill="#41a85f" fill-opacity=".45" d="M55.5 47H27a5 5 0 0 1-5-5V22a5 5 0 0 1 5-5h70a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H68.64l-6.57 6.571z"/>
					</g>
					<path fill="#fff" d="M55.5 47H27a5 5 0 0 1-5-5V22a5 5 0 0 1 5-5h70a5 5 0 0 1 5 5v20a5 5 0 0 1-5 5H68.64l-6.57 6.571z"/>
					<text dominant-baseline="text-before-edge" style="line-height:11px;font-kerning:normal" fill="#41a85f" font-family="'Arial','ArialMT'" font-size="8" font-style="none" font-weight="400" letter-spacing=".08" transform="translate(55 28)">
						<tspan>12.4%</tspan>
					</text>
					<path fill="#41a85f" d="M46 34.046L49.046 31l3.047 3.046z"/>
				</g>
				<!-- bottom x caption -->
				<g class="dates-label">
					<text class="label-text" *ngFor="let label of config.labels; let i = index" [attr.y]='config.height' [attr.x]='( config.width / config.labels.length * i ) + config.offset + 10'>{{ label }}</text>
				</g>
				<!-- left y caption -->
				<g class="data-label">
					<text class="label-text" *ngFor="let label of verticalData; let even = even; let odd = odd; let first = first; let last = last;" [attr.y]='label.coord + config.offset' [attr.x]='7' [ngClass]="{ odd: odd, even: even, first: first, last: last }">{{ config.endPoints[1] - label.data }}</text>
				</g>
				<!-- curves -->
				<g *ngFor="let points of coordinates; let i = index" class="chart-curves">
					<path [attr.d]="getPathCoordinates(points, true)" stroke="transparent" fill="none" stroke-width="14" (mouseenter)="onPathHover($event, config.colors[i], i)" (mouseleave)="onPathHover($event, 'none', i)"/>
					<path [attr.d]="getPathCoordinates(points, false)" [attr.stroke]="config.colors[i]" fill="none" stroke-width="1" stroke-dasharray="4"/>
				</g>
				<!-- points -->
				<g *ngFor="let points of coordinates; let i = index" class="chart-points">
					<circle class="point" *ngFor="let point of points" [attr.cx]='point[0]' [attr.cy]='point[1]' [attr.r]='config.pointRadius' stroke-width="2" stroke="#fff" [attr.fill]='config.colors[i]' filter="url(#shadow)" opacity="0" [attr.data-point]='i'>
					</circle>
				</g>
			</svg>
		</div>
	</div>
</div>