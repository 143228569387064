import { Component, ViewChild, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Dropdown } from './../dropdown';
import { handleZero } from './../../utils/datetime';

@Component({
	selector: 'timepicker',
	templateUrl: './template.html',
	host: {
	}
})
export class Timepicker {

	@Input('node') node: any;
	@Input('control') control: FormControl;
	@ViewChild('hoursSelect') hoursSelect: HTMLSelectElement;
	@ViewChild('minutesSelect') minutesSelect: HTMLSelectElement;
	@ViewChild('dropdown') dropdown: Dropdown;
	@Output('onChange') onChange: EventEmitter<any> = new EventEmitter();

	hours: any[] = [];
	minutes: any[] = [];

	selection: any = {
		hours: handleZero(0), minutes: handleZero(0)
	};

	constructor(){

	}

	ngOnInit(){
		for (let h = 0; h < 24; h++){
			this.hours.push(handleZero(h));
		}
		for (let m = 0; m < 60; m += 5){
			this.minutes.push(handleZero(m));
		}
		// console.log(this.node.value);
		this.node.addEventListener('click', (e: any)=> {
			this.dropdown.show();
		});
	}

	ngOnChanges(change: SimpleChange){
		// if (change.node){
		// 	console.log(change.control.currentValue);

		// }
	}

	setHours(value: any){
		this.selection.hours = handleZero(value);
		this.outputValue();
		this.onChange.emit(this.selection);
	}

	setMinutes(value: any){
		this.selection.minutes = handleZero(value);
		this.outputValue();
		this.onChange.emit(this.selection);
	}

	outputValue(){
		this.control.setValue(this.selection.hours+":"+this.selection.minutes);
	}

}