import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelectOption } from './../interfaces/selectOption';
import { Notification, NotificationType } from './../interfaces/notification';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType
} from './../utils/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from './../services/users';
import { CONFIG } from './../config';
import { NotificatorService } from './../services/notificator';

@Component({
	selector: 'password-change-page',
	templateUrl: './password-change-page.html',
	providers: [ UsersService, NotificatorService ]
})
export class PasswordChangePage {

	form: FormGroup;
	formConfig: any = {
		password: ["Пароль", "r", "m|8", "M|24"],
		password2: ["Пароль", "r", "m|8", "M|24"]
	}
	formFields: string[] = ['password', 'password2'];
	ifError: Function;
	ifSuccess: Function;
	createTooltip: Function = createTooltip;
	fieldType: Function = fieldType;

	constructor(
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private router: Router
	){

		// console.log(this.form);

	}

	ngOnInit(){
		this.form = createForm(this.formConfig, { validator: isEqualsTo('password', 'password2') });
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
	}

	submit(){
		validateAllFields(this.form);
		if (this.form.valid){
			this.usersService.passwordReset(this.form.value).subscribe((res: any)=> {
				console.log(res);
				// this.router.navigateByUrl('/password-change');
				// this.notificatorService.push({
				// 	title: "Восстановление пароля",
				// 	text: "Ссылка для восстановления была выслана на ваш Email",
				// });
			}, (err: any)=> {
				this.notificatorService.push({
					title: "Ошибка",
					text: createErrorText(err.error),
					type: NotificationType.Error
				});
			});
		}
	}

}