import { Component } from '@angular/core';
import { SelectOption } from './../interfaces/selectOption';

@Component({
	selector: 'main-page',
	templateUrl: './main-page.html'
})
export class MainPage {

	title = 'logoped';

	options: SelectOption[] = [
		{value: "admins", label: "Администраторы", selected: false},
		{value: "moders", label: "Модераторы", selected: false},
		{value: "teachers", label: "Преподаватели", selected: false},
		{value: "lohs", label: "Лохи", selected: false},
		{value: "perkers", label: "Парковщики", selected: false},
		{value: "washer", label: "Мойщик окон", selected: false},
		{value: "doctors", label: "Врач-педикюр", selected: false},
		{value: "vasya", label: "Васько", selected: false}
	];

	constructor(){

	}

	showTags(data: any) {
		console.log(data);
	}

}