import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Vector } from '../utils/vector';
import { getScrollTop } from '../utils';
import { Droppable } from './droppable';
import { BehaviorSubject } from 'rxjs';

function getBBox(element: HTMLElement): ClientRect {
	return element.getBoundingClientRect();
}

const DEBUG: boolean = false;

@Directive({
	selector: '[ngDraggable]',
	host: {
		"(mousedown)": "dragStart($event)",
		"[class.drag-active]": "active",
		"(onDragStart)": "handleDragStart($event)",
		"(onDrag)": "handleDrag($event)",
		"(onDragEnd)": "handleDragEnd($event)"
	}
})
export class Draggable {

	@Input('ngDraggable') key: string = "";
	@Input('id') id: string = "";
	@Input('useBounds') useBounds: string = "";
	@Input('initializedDisabled') initializedDisabled: string = "";
	@Input('externalData') externalData: any = {};

	@Output('onDragStart') onDragStart: EventEmitter<any> = new EventEmitter();
	@Output('onDrag') onDrag: EventEmitter<any> = new EventEmitter();
	@Output('onDragEnd') onDragEnd: EventEmitter<any> = new EventEmitter();
	@Output('onDropEnd') onDropEnd: EventEmitter<any> = new EventEmitter();

	start: Vector = new Vector();
	translation: Vector = new Vector();
	active: boolean = false;
	enabled: boolean = true;

	static instances: Draggable[] = [];
	static activeDraggable: Draggable;
	static draggableTarget: any;
	static draggable: BehaviorSubject<Draggable> = new BehaviorSubject(null);

	constructor(public host: ElementRef){
		// console.log(this.host, this.component);
		Draggable.instances.push(this);
	}

	ngOnInit(){
		// console.log(this);
		if (this.initializedDisabled){
			this.enabled = false;
		}
	}
	ngOnDestroy(){
		for (let i = 0; i < Draggable.instances.length; i++){
			if (Draggable.instances[i] === this){
				Draggable.instances.splice(i, 1);
				return;
			}
		}
	}

	dragStart(e: any){
		if (!e || e.which !== 1) return;
		e.preventDefault();
		// console.log(this);
		if (!this.enabled) return;
		if (!Draggable.draggableTarget){
			Draggable.draggableTarget = document.querySelector('#dragTarget');
		}
		// console.log(this.host.nativeElement.outerHTML);
		Draggable.activeDraggable = this;
		this.start.x = e.pageX;
		this.start.y = e.pageY - getScrollTop();
		if (DEBUG){
			console.groupCollapsed(`Drag: ${this.id}, mouse: ${this.start.x}x${this.start.y}`);
			console.log("start (programaticaly)", this);
		}
		Draggable.draggableTarget.innerHTML = this.host.nativeElement.outerHTML.replace(/image\-load/gim, '');
		let bbox = getBBox(this.host.nativeElement);
		// console.log(bbox.width+" x "+bbox.height);
		Draggable.draggableTarget.style.left = bbox.left+"px";
		Draggable.draggableTarget.style.top = bbox.top+"px";
		Draggable.draggableTarget.style.width = bbox.width+"px";
		Draggable.draggableTarget.style.height = bbox.height+"px";
	}

	drag(e: any){
		let current = new Vector(e.pageX, e.pageY - getScrollTop());
		if ((this.start.distanceTo(current) / window.devicePixelRatio) < 10) {
			if (DEBUG)
				console.log(`not started, offset - ${this.start.distanceTo(current)}/10px`);
			return;
		}
		if (!this.active){
			this.active = true;
			// console.log("Draggable: start");
			if (DEBUG)
				console.log("start (visible moving), offset > 10px", this);
			this.onDragStart.emit(this);
			Draggable.draggable.next(this);
			return;
		}
		Draggable.draggableTarget.classList.add('active');
		if (DEBUG)
			console.log(`moving, mouse: ${current.x}x${current.y}`, this);
		this.translation = current.subtract(this.start);
		Draggable.draggableTarget.style.transform = `translate(${this.translation.x}px, ${this.translation.y + getScrollTop()}px)`;
		// console.log("Draggable: move");
		this.onDrag.emit(this);
		// console.log(this.translation);
		// console.log(Droppable.instances);
	}

	dragEnd(e: any){
		this.active = false;
		Draggable.draggableTarget.classList.remove('active');
		// console.log("Draggable: end");
		if (Droppable.activeDroppable && !Droppable.activeDroppable.disabled){
			Droppable.activeDroppable.drop();
			this.onDropEnd.emit(this);
			if (DEBUG)
				console.log("Emit DROP", this);
		}
		if (DEBUG){
			console.log("Stop", this);
			console.groupEnd();
		}
		this.onDragEnd.emit(this);
		Draggable.draggable.next(null);
	}

	enable(){
		this.enabled = true;
	}

	disable(){
		this.enabled = false;
	}

	/*
		Эти обработчики пока вынес сюда
	*/

	handleDragStart(draggable: Draggable){
		Droppable.traverseByKey(this.key, (droppable: Droppable, index: number)=> {
			// if (!droppable.slot) return false;
			// 
		});
	}
	handleDrag(draggable: Draggable){
		let mouse: Vector = draggable.start.clone().add(draggable.translation);
		let target = Draggable.draggableTarget;
		// console.log(this.key);
		let draggableBBox: ClientRect = target.getBoundingClientRect(),
			draggableCenter = new Vector(draggableBBox.left + draggableBBox.width/2, draggableBBox.top + draggableBBox.height/2);
		let activeDroppable: Droppable | null = null,
				minimumDistance = 1000000;
		if (Droppable.activeDroppable) {
			Droppable.activeDroppable.detach();
		}
		Droppable.traverseByKey(this.key, (droppable: Droppable, index: number)=> {
			if (droppable.disabled) return;
			if (droppable.host.nativeElement.classList.contains('faded')) return;
			let droppableBBox: ClientRect = droppable.host.nativeElement.getBoundingClientRect();

			if (this.useBounds){
				if ((
						mouse.x > droppableBBox.left && mouse.x < droppableBBox.left+droppableBBox.width
					) && (
						mouse.y > droppableBBox.top && mouse.y < droppableBBox.top+droppableBBox.height
					)){
					activeDroppable = droppable;
				}
			} else {
				let droppableCenter = new Vector(droppableBBox.left + droppableBBox.width/2, droppableBBox.top + droppableBBox.height/2);
				// console.log(droppableCenter.distanceTo(draggableCenter));
				if (droppableCenter.distanceTo(mouse) < minimumDistance && droppableCenter.distanceTo(mouse) < droppableBBox.width){
					minimumDistance = droppableCenter.distanceTo(mouse);
					activeDroppable = droppable;
				}
			}

		});
		if (activeDroppable){
			activeDroppable.attach();
		}
		// console.log(activeDroppable);
	}
	handleDragEnd(draggable: Draggable){
		Droppable.enableAll();
	}

	static traverseByKey(key: string, cb: Function){
		Draggable.instances.filter((d: Draggable)=> {
			return d.key === key;
		}).map((draggable: Draggable, index: number)=> {
			cb(draggable, index);
		});
	}

	static enableByKey(key: string){
		Draggable.traverseByKey(key, (d: Draggable)=>{
			d.enable();
		});
	}

	static disableByKey(key: string){
		Draggable.traverseByKey(key, (d: Draggable)=>{
			d.disable();
		});
	}

}

document.addEventListener('mousemove', function(e: any){
	if (!Draggable.activeDraggable) return;
	Draggable.activeDraggable.drag(e);
	// console.log(Draggable.activeDraggable);
});

document.addEventListener('mouseup', function(e: any){
	if (!Draggable.activeDraggable) {
		return;
	}
	if (Draggable.activeDraggable.active){
		Draggable.activeDraggable.dragEnd(e);
	}
	if (DEBUG)
		console.groupEnd();
	Draggable.activeDraggable.translation = new Vector();
	Draggable.activeDraggable = null;
	Draggable.draggableTarget.innerHTML = "";
	Draggable.draggableTarget.style.transform = `translate(0, 0)`;
	Draggable.draggableTarget.style.left = "-1000px";
	Draggable.draggableTarget.style.top = "-1000px";
	Draggable.draggableTarget.style.width = "0px";
	Draggable.draggableTarget.style.height = "0px";

	// console.log(activeDraggable);
});