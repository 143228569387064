import { ReplaySubject } from 'rxjs';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

export class GlobalEvent {

	static instance: GlobalEvent;

	private events: any[] = [];
	subject: ReplaySubject<any> = new ReplaySubject();

	constructor(){
		if (GlobalEvent.instance) {
			return GlobalEvent.instance;
		}
		GlobalEvent.instance = this;
	}

	trigger(key: string, data?: any) {
		let newEvent: GlobalEventInterface = {
			key: key,
			data: data || null,
			timestamp: new Date().getTime()
		};
		this.subject.next(newEvent);
		this.subject.next(null);
		this.events.push(newEvent);
		console.log(`global event [${key}] ${data ? JSON.stringify(data) : "null"}`);
	}

	subscribe(callback: any){
		if (!callback) {
			console.warn("no callback for GlobalEvent subscribe");
		}
		this.subject.pipe(filter(value => value !== null)).subscribe(callback);
	}

}

export interface GlobalEventInterface {
	key: string;
	data: any;
	timestamp: number;
}