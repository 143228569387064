<div class="verticaly-middle">
	<div class="inner">
		<div class="modal-content">
			<div class="h3-title main-title" *ngIf="title">{{title}}</div>
			<button type="button" class="btn btn-transparent btn-round close" (mousedown)="hide()">
				<i class="icon icon-cross"></i>
			</button>
			<ng-content></ng-content>
		</div>
	</div>
</div>