import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AbstractForm, AbstractFormModes } from './../forms/abstract-form';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, createForm, ifSuccess, ifError
} from './../utils';
import { Calendar, Schedule } from './../components';
import { SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService } from './../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalEvent, GlobalEventInterface } from './../utils';

@Component({
	selector: 'schedule-page',
	templateUrl: './schedule-page.html',
	providers: [ SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService ]
})
export class SchedulePage {

	filter: FormGroup;
	filterConfig: any = {
		dates: ["", "r"],
		type: ["", "r"],
		hourFrom: ["", "r"],
		hourTo: ["", "r"],
		clients: ["", "r"]
	}
	ifError: Function;
	ifSuccess: Function;

	teachers: any[] = [];
	teachersKeys: any = {};
	occupations: any[] = [];
	occupationsKeys: any = {};
	teachersOptions: any[] = [];
	durationOptions: any[] = [];
	rooms: any[] = [];
	roomsOptions: any[] = [];
	types: any[] = [];
	typesKeys: any = {};
	subtypes: any[] = [];
	clients: any[] = [];
	clientsOptions: any[] = [];
	clientsKeys: any = {};

	type: string;

	dates: Date[] = [];

	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('schedule') schedule: Schedule;

	globalEvent: GlobalEvent;

	constructor(
		private settingsService: SettingsService,
		private usersService: UsersService,
		private departmentsService: DepartmentsService,
		private occupationsService: OccupationsService,
		private notificatorService: NotificatorService,
		private route: ActivatedRoute
	){

		this.type = route.data['value'].type;

		this.usersService.getUsers().subscribe((res: any[])=> {
			// console.log('users', res);
			this.teachers = res.filter(u => u.role === "TEACHER");
			this.teachersOptions = this.teachers.map((t: any)=> {
				this.teachersKeys[t.pk] = t;
				return { value: t.pk, label: this.getName(t), image: t.image || 'assets/image.png' }
			});
			this.clients = res.filter(u => u.role === "CLIENT");
			this.clientsOptions = this.clients.map((c: any)=> {
				this.clientsKeys[c.pk] = c;
				return { value: c.pk, label: this.getName(c), image: c.image || 'assets/image.png' }
			});
		});

		this.settingsService.getOccupationTypes().subscribe((res: any[])=> {
			// console.log('types', res);
			this.types = res;
			res.map(t => this.typesKeys[t.pk] = t);
		});

		this.departmentsService.getRooms().subscribe((res: any[])=> {
			// console.log('rooms', res);
			this.rooms = res;
			this.roomsOptions = res.map((r: any)=> {
				return { value: r.pk, label: r.title, image: r.image || 'assets/image.png' }
			});
		});

		this.occupationsService.getOccupations().subscribe((res: any[])=> {
			// console.log('occupations', res);
			this.occupations = this.occupationsService.removeDoubleOccupations(
				this.occupationsService.unwrapOccupations(res)
			);
			this.occupations.map(o => this.occupations[o.pk] = o);
		});

		this.globalEvent = new GlobalEvent();
		this.globalEvent.subscribe((event: GlobalEventInterface)=> {
			// console.log(event);
			if (event.key === "department.change"){
				this.fetchOccupations();
				this.schedule.fetchOccupations(this.type);
			}
		});
	}

	ngOnInit(){
		this.schedule.dates = this.dates;
		this.filter = createForm(this.filterConfig);
		this.ifError = ifError.bind(this.filter);
		this.ifSuccess = ifSuccess.bind(this.filter);
		this.filter.addControl('realRooms', new FormControl(true));
		this.filter.addControl('virtualRooms', new FormControl(true));
		this.filter.controls['type'].setValue('0');
		this.filter.controls['hourFrom'].setValue('08:00');
		this.filter.controls['hourTo'].setValue('21:00');

		this.calendar.setDay(new Date().getDayStart());

		this.filter.valueChanges.subscribe((val: any) => {
			// console.log(val);
			window.waitForFinalEvent(()=> {
				this.fetchOccupations();
			}, 1000, 'filter_occupations');
		});

		this.setDates();

	}

	setDates(){
		this.dates = [];
		if (this.type === "day"){
			this.dates.push(this.calendar.selection.start.clone());
			this.schedule.dates = this.dates;
		}
		if (this.type === "week"){
			let startOfWeek = this.calendar.selection.start.clone().getWeekStart();
			for (let i = 0; i < 7; i++){
				this.dates.push(startOfWeek.addDays(i));
			}
			this.schedule.dates = this.dates;
			let weekStart = this.calendar.selection.start.clone().getWeekStart(),
					weekEnd = this.calendar.selection.start.clone().getWeekEnd();
			this.calendar.selection.start = null;
			this.calendar.selection.end = null;
			this.calendar.multiple = true;
			this.calendar.setDay(weekStart);
			this.calendar.setDay(weekEnd);
		}
		if (this.type === "month"){
			let startOfMonth = this.calendar.selection.start.clone().getMonthStart(),
					monthLength = startOfMonth.getMonthLength();
			for (let i = 0; i < monthLength; i++){
				this.dates.push(startOfMonth.addDays(i));
			}
			this.schedule.dates = this.dates;
			let monthStart = this.calendar.selection.start.clone().getMonthStart(),
					monthEnd = this.calendar.selection.start.clone().getMonthEnd();
			this.calendar.selection.start = null;
			this.calendar.selection.end = null;
			this.calendar.multiple = true;
			this.calendar.setDay(monthStart);
			this.calendar.setDay(monthEnd);
		}
	}

	getName(teacher: any): string {
		return teacher.first_name && teacher.last_name ? `${teacher.first_name} ${teacher.last_name}` : teacher.email;
	}

	onFilterReset(){
		// console.log('reset');
	}


	getCalendarTitle(){
		if (!this.calendar.selection.start || (this.calendar.multiple && !this.calendar.selection.end)){
			return "";
		}
		let title: string = "",
				start = this.calendar.selection.start.clone().parseDate(this.calendar.multiple ? 'DD.MM' : 'DD.MM.YYYY'),
				end = (this.calendar.selection.end || new Date()).clone().parseDate(this.calendar.multiple ? 'DD.MM' : 'DD.MM.YYYY');
		if (this.type === "day"){
			title = `${start}`;
		} else if (this.type === "week" || this.type === "month"){
			title = `${start} &mdash; ${end}`;
		}
		return title;
	}

	onDayChange(event: any){
		window.waitForFinalEvent(()=> {
			this.fetchOccupations();
			let dates: string = '';
			if (this.calendar.selection.start){
				dates += this.calendar.selection.start.clone().parseDate('YYYY-MM-DD');
			}
			if (this.calendar.selection.end){
				dates += ','+this.calendar.selection.end.clone().parseDate('YYYY-MM-DD');
			}
			this.filter.controls['dates'].setValue(dates);
			this.setDates();

			this.fetchOccupations();
			this.schedule.fetchOccupations(this.type);
		}, 1000, 'calendar_occupations');
	}

	fetchOccupations(){
		this.schedule.filter = this.filter.value;

		// this.notificatorService.push({
		// 	title: 'Фильтр занятий',
		// 	text: `Пошел запрос на календарик ${new Date().toLocaleString()}`
		// });
	}

	resetFilters(){
		this.schedule.filter = this.filter.value;
	}

	prevSchedule(){
		if (this.type === "day") this.calendar.prevDay('start');
		if (this.type === "week") this.calendar.prevWeek();
		if (this.type === "month") this.calendar.prevMonth();
	}

	nextSchedule(){
		if (this.type === "day") this.calendar.nextDay('start');
		if (this.type === "week") this.calendar.nextWeek();
		if (this.type === "month") this.calendar.nextMonth();
	}
}
