import {
	Component, ViewChild, Input, Output, EventEmitter,
	ElementRef
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'lesson',
	template: `
		<div class="line" [style.background]="color"></div>
		<div class="fader" [style.background]="color"></div>
		<div class="counter" title="Сколько клиентов: {{data?.clients.length}}">
			<i class="icon icon-user" *ngIf="data?.clients.length <= 1"></i>
			<i class="icon icon-users" *ngIf="data?.clients.length > 1"></i>
			<span>{{data?.clients.length}}</span>
		</div>
		<div class="cycle">
			<i class="icon icon-reload" title="{{cycles[data?.cycle]}}"></i>
		</div>
		<ul class="clear clients" [class.flat]="data?.duration < 45">
			<li *ngFor="let client of data?.clients">{{getClientName(client)}}</li>
		</ul>
		<div class="type" title="{{data.occupation_type.title}}">{{data.occupation_type.title}}</div>
		<div class="time">
			<i class="icon icon-clock"></i> {{getTimeString()}}
		</div>
	`,
	host: {
		"[class.collapsed]": "height === 2",
		"[style.top]": "top+'px'",
		"[style.height]": "(height * (data?.duration / 15))+'px'",
		"(click)": "edit()"
	},
})
export class Lesson {

	@Input() data: any = {};
	@Input() top: number = 0;
	@Input() height: number;

	cycles: any = {
		ONE: "Одноразовое занятие",
		MWF: "Понедельник-Среда-Пятница",
		TT: "Вторник-Четверг",
		TTS: "Вторник-Четверг-Суббота"
	};
	color: string;
	host: any;

	constructor(public _host: ElementRef, private router: Router){
		this.host = this._host.nativeElement;
		this.color = '#'+Math.floor(Math.random()*16777215).toString(16);
		// console.log(this.router);
	}

	ngOnInit(){
		// console.log(this.type);
	}

	edit(){
		let arr = this.data.url.split('/'),
				pk = arr[arr.length-2];
		// console.log(pk);
		this.router.navigateByUrl('lessons/edit/'+this.data.pk);
	}

	getClientName(client: any){
		return client.first_name && client.last_name ? client.last_name+" "+client.first_name+"." : client.email;
	}

	getTimeString(){
		let z = (v)=> { return v < 10 ? "0"+v : v; };
		let t1 = new Date().getTimeFromLesson(this.data),
				t2 = new Date().getTimeFromLesson(this.data, true);
		return `${z(t1.getHours())}:${z(t1.getMinutes())} ${z(t2.getHours())}:${z(t2.getMinutes())}`;
	}

}