<div class="preloader" [class.active]="loading"><span></span></div>

<div class="row middle-xs offset-bottom" *ngIf="length < usersRaw.length">
	<div class="col-xs-5 align-right">
		<button class="btn btn-round" type="button" (click)="prevPage()"><i class="icon icon-angle-left"></i></button>
	</div>
	<div class="col-xs-2 align-center">
		Страница {{this.page+1}} из {{this.getPagesLength()}}
	</div>
	<div class="col-xs-2 align-left">
		<button class="btn btn-round" type="button" (click)="nextPage()"><i class="icon icon-angle-right"></i></button>
	</div>
	<div class="col-xs-3 align-right">
		<label for="usersLengthPerPage" style="display: inline-block; vertical-align: baseline;">Показать</label>&nbsp;
		<div class="form-group collapsed inline">
			<select [(ngModel)]="length" id="usersLengthPerPage" class="input small" (input)="onLengthChange()">
				<option value="10">10</option>
				<option value="20">20</option>
				<option value="50">50</option>
				<option value="100">100</option>
			</select>
			<i class="icon icon-drop-down input-icon small"></i>
		</div>
	</div>
</div>

<ul class="clear users-list">
	<li *ngFor="let user of users; let userIndex = index">
		<a [routerLink]="['/users/detail/'+user.pk]" href="">
			<span style="width: 50px">
				<figure class="image-cover round">
					<img [src]="user.image || 'assets/image.png'">
				</figure>
			</span>
			<span style="width: 70px">id: {{user.pk}}</span>
			<span>{{user.first_name}}</span>
			<span>{{user.last_name}}</span>
			<span>{{user.email}}</span>
			<span style="width: 100px" class="align-center">
				<span *ngIf="user.profile" class="badge">id:{{user.profile.pk}}</span>
				<!-- <span *ngIf="user.is_superuser" class="badge success">Админ</span> -->
				<span *ngIf="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'" class="badge success">Админ</span>
			</span>
			<span style="width: 150px;" class="align-right">{{getDate(user.date_joined)}}</span>
		</a>
	</li>
</ul>

<div class="row middle-xs offset-top" *ngIf="length < usersRaw.length">
	<div class="col-xs-5 align-right">
		<button class="btn btn-round" type="button" (click)="prevPage()"><i class="icon icon-angle-left"></i></button>
	</div>
	<div class="col-xs-2 align-center">
		Страница {{this.page+1}} из {{this.getPagesLength()}}
	</div>
	<div class="col-xs-5 align-left">
		<button class="btn btn-round" type="button" (click)="nextPage()"><i class="icon icon-angle-right"></i></button>
	</div>
</div>

<div class="alert error" *ngIf="!users.length && !loading">Нет еще пользователей</div>