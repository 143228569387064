import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService } from './../../services';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, ifSuccess, ifError, createForm
} from './../../utils';
import { Calendar, TagsSelect } from './../../components';
import { LessonTimeframe } from './../lesson-timeframe';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from './../../interfaces';

@Component({
	selector: 'form-lesson-add',
	templateUrl: './template.html',
	providers: [ SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService ]
})
export class FormLessonAdd extends AbstractForm {

	config: any = {
		title: ['', 'r', 'm|5', 'M|50'],
		lesson: ['', 'r'],
		teacher: ['', 'r'],
		room: ['', 'r'],
		loop: ['', 'r'],
		startDate: ['', 'r'],
		startTime: ['', 'r'],
		type: ['', 'r'],
		subtype: ['', 'r'],
		duration: ['', 'r'],
		clients: ['', 'r'],
		repeats: ['', 'r']
	}

	teachers: any[] = [];
	teachersKeys: any = {};
	occupations: any[] = [];
	occupationsKeys: any = {};
	teachersOptions: any[] = [];
	durationOptions: any[] = [];
	rooms: any[] = [];
	roomsOptions: any[] = [];
	types: any[] = [];
	typesKeys: any = {};
	subtypes: any[] = [];
	clients: any[] = [];
	clientsOptions: any[] = [];
	clientsKeys: any = {};

	@ViewChild('calendar') calendar: Calendar;
	@ViewChild('lessonTimeframe') lessonTimeframe: LessonTimeframe;
	@ViewChild('roomsTags') roomsTags: TagsSelect;
	@ViewChild('teachersTags') teachersTags: TagsSelect;
	@ViewChild('clientsTags') clientsTags: TagsSelect;

	id: string;
// <option *ngFor="let teacher of teachers" [value]="teacher.pk">{{getName(teacher)}}</option>

	constructor(
		private location: Location,
		private settingsService: SettingsService,
		private usersService: UsersService,
		private departmentsService: DepartmentsService,
		private occupationsService: OccupationsService,
		private notificatorService: NotificatorService,
		private route: ActivatedRoute,
		private router: Router
	){
		super();

		this.resetEnable = false;

		for (let i = 0; i < 5; i++){
			this.durationOptions.push({
				value: 30 + 15 * i,
				label: `${30 + 15 * i} минут`
			});
		}

		this.usersService.getUsers().subscribe((res: any[])=> {
			// console.log('users', res);
			this.teachers = res.filter(u => u.role === "TEACHER");
			this.teachersOptions = this.teachers.map((t: any)=> {
				this.teachersKeys[t.pk] = t;
				return { value: t.pk, label: this.getName(t), image: t.image || 'assets/image.png' }
			});
			this.clients = res.filter(u => u.role === "CLIENT");
			this.clientsOptions = this.clients.map((c: any)=> {
				this.clientsKeys[c.pk] = c;
				return { value: c.pk, label: this.getName(c), image: c.image || 'assets/image.png' }
			});
		});

		this.settingsService.getOccupationTypes().subscribe((res: any[])=> {
			// console.log('types', res);
			this.types = res;

			res.map(t => this.typesKeys[t.pk] = t);
		});

		this.departmentsService.getRooms().subscribe((res: any[])=> {
			// console.log('rooms', res);
			this.rooms = res;
			this.roomsOptions = res.map((r: any)=> {
				return { value: r.pk, label: r.title, image: r.image || 'assets/image.png' }
			});
		});

		this.id = route.snapshot.params["id"];

	}

	goBack(){
		this.location.back();
	}

	getName(teacher: any): string {
		return teacher.first_name && teacher.last_name ? `${teacher.first_name} ${teacher.last_name}` : teacher.email;
	}

	getSubtypes(){
		this.subtypes = this.typesKeys[this.form.controls['type'].value].occupationsubtype_set || [];
	}

	ngOnInit(){
		this.form = createForm(this.config);
		this.ifError = ifError.bind(this.form);
		this.ifSuccess = ifSuccess.bind(this.form);
		this.calendar.setDay(new Date().getDayStart());
		this.form.controls['repeats'].setValue(1);

		// if edit
		if (this.id){
			this.injectValues();
		}

		this.updateTimeframe();

	}

	injectValues(){
		this.occupationsService.getOccupation(this.id).subscribe((o: any)=> {
			// console.log('detail', o);
			// title: ['', 'r', 'm|5', 'M|50'],
			// lesson: ['', 'r'],
			// teacher: ['', 'r'],
			// room: ['', 'r'],
			// loop: ['', 'r'],
			// startDate: ['', 'r'],
			// startTime: ['', 'r'],
			// type: ['', 'r'],
			// subtype: ['', 'r'],
			// duration: ['', 'r'],
			// clients: ['', 'r']
			o = this.occupationsService.unwrapOccupations([o])[0];
			setTimeout(()=> {
				this.form.controls['title'].setValue(o.title);
				this.form.controls['lesson'].setValue(o.kind);
				this.form.controls['type'].setValue(o.occupation_type);
				setTimeout(()=> {
					this.getSubtypes();
					this.form.controls['subtype'].setValue(o.occupation_subtype);
				});
				this.roomsTags.selectOptionByValue(o.room);
				this.form.controls['loop'].setValue(o.cycle);
				// this.calendar.setDay()
				this.calendar.setDay(new Date(o.date).getDayStart());
				this.form.controls['duration'].setValue(o.duration);
				this.lessonTimeframe.setTimeFromLesson(o);
				this.teachers.map((t: any)=> {
					if (o.performers.indexOf(t.pk) >= 0){
						this.teachersTags.selectOptionByValue(t.pk);
					}
				});
				this.clients.map((c: any)=> {
					if (o.clients.indexOf(c.pk) >= 0){
						this.clientsTags.selectOptionByValue(c.pk);
					}
				});
			}, 500);
		});
	}

	setStartDate(event: Date){
		this.form.controls['startDate'].setValue(this.calendar.selection.start.toLocaleDateString());
		this.form.controls['startDate']['rawDate'] = this.calendar.selection.start.getTime();
		this.updateTimeframe();
	}

	getSelectedTeachers(ids: any[]){
		let result = [];
		ids.map((id: any)=> {
			if (this.teachersKeys[id]){
				result.push(this.teachersKeys[id]);
			}
		});
		return result;
	}
	getSelectedClients(ids: any[]){
		let result = [];
		ids.map((id: any)=> {
			if (this.clientsKeys[id]){
				result.push(this.clientsKeys[id]);
			}
		});
		return result;
	}

	updateTimeframe(){
		this.occupationsService.getOccupationsForDay({dates: this.calendar.selection.start.parseDate('YYYY-MM-DD')}).subscribe((res: any[])=> {
			this.occupations = this.occupationsService.removeDoubleOccupations(
				this.occupationsService.unwrapOccupations(res)
			);
			this.occupations.map(o => this.occupationsKeys[o.pk] = o);
			// this.lessonTimeframe.createTimeline();
		});
	}

	afterSubmit(){
		// console.log(this.form.value);
		if (this.id){
			this.occupationsService.updateOccupation(this.id, this.form.value, this.form).subscribe((res: any)=> {

			});
			return;
		}
		this.occupationsService.createOccupation(this.form.value, this.form).subscribe((res: any)=> {
			// console.log(res);
			console.log('New occupation created: ', res);
			this.notificatorService.push({
				title: "OCCUPATIONS",
				text: 'Занятие добавлено',
				type: NotificationType.Success
			});
			this.reset();

			this.router.navigateByUrl("/schedule");
		});
	}

}