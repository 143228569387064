<div class="layout">
	<div class="column info-column" *ngIf="showDay">
		<div class="day-info" *ngIf="selection.start">
			<div class="date">{{selection.start.getDate()}}</div>
			<div class="date-name">{{getDayNameHandled(selection.start.getUTCDay())}}</div>
			<div class="date-day">
				{{getMonthNameHandled(selection.start.getMonth())}}
				{{selection.start.parseDate('yyyy')}}
			</div>
			<div class="day-navigation" *ngIf="showDayButtons">
				<button class="btn btn-small btn-round btn-transparent"
					(click)="prevDay('start')"
				>
					<i class="icon icon-angle-left small"></i>
				</button>
				<button class="btn btn-small btn-round btn-transparent"
					(click)="nextDay('start')"
				>
					<i class="icon icon-angle-right small"></i>
				</button>
			</div>
		</div>
		<div class="day-info" *ngIf="!selection.start">{{getStart()}}</div>
		<div class="day-info" *ngIf="multiple && selection.end">
			<div class="date">{{selection.end.getDate()}}</div>
			<div class="date-name">{{getDayNameHandled(selection.end.getUTCDay())}}</div>
			<div class="date-day">
				{{getMonthNameHandled(selection.end.getMonth())}},
				{{selection.end.parseDate('yyyy')}}
			</div>
			<div class="day-navigation" *ngIf="showDayButtons">
				<button class="btn btn-small btn-round btn-transparent"
					(click)="prevDay('end')"
				>
					<i class="icon icon-angle-left small"></i>
				</button>
				<button class="btn btn-small btn-round btn-transparent"
					(click)="nextDay('end')"
				>
					<i class="icon icon-angle-right small"></i>
				</button>
			</div>
		</div>
		<div class="day-info" *ngIf="multiple && !selection.end">{{getEnd()}}</div>
	</div>
	<div class="column">
		<div class="header">
			<button type="button" class="btn btn-round btn-transparent prev" (click)="slideToPrevMonth()"
				[class.disabled]="page === 0"
			><i class="icon icon-arrow-left"></i></button>
			<button type="button" class="btn btn-round btn-transparent next" (click)="slideToNextMonth()"
				[class.disabled]="page === months-1"
			><i class="icon icon-arrow-right"></i></button>
			<div class="month-year">{{calendar[min(page, months)].start.getUTCFullYear()}}</div>
			<div class="month-name">{{getMonthName(calendar[min(page, months)].index)}}</div>
		</div>
		<div class="months-wrapper">
			<ul class="clear titles">
				<li *ngFor="let i of [0,1,2,3,4,5,6]" [class.weekend]="i > 4"
					[class.active]="(selection.start && selection.start.getUTCDay() === i) || (selection.end && selection.end.getUTCDay() === i)"
				>{{getDayName(i)}}</li>
			</ul>
			<div class="inner" [style.transform]="'translateX('+(100 * -page)+'%)'">
				<div class="month" *ngFor="let month of calendar; let m = index" [class.visible]="page === m">
					<table class="table">
						<tbody>
							<tr *ngFor="let week of month.weeks">
								<!-- <td *ngFor="let day of week">{{day.getDate()}}</td> -->
								<td *ngFor="let day of week"
									[class.highlighted]="isHighlighted(day)"
									[class.first]="day.isEqual(selection.start)"
									[class.last]="day.isEqual(selection.end)"
								>
									<span class="day"
										[class.disabled]="isDisabled(day)"
										[class.offsetted]="!day.inRange(month.start, month.end)"
										[class.today]="day.isToday()"
										[class.active]="day.isEqual(selection.start) || day.isEqual(selection.end)"
										(click)="setDay(day)"
									>
										{{day.getDate()}}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
