import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';

@Component({
	selector: 'tabs',
	templateUrl: './template.html',
})
export class Tabs {

	@Input('anchors') anchors: string[] = [];
	@Input('anchorsIcons') anchorsIcons: string[] = [];
	@Input('index') index: number = 0;

	constructor(){

	}

	select(index: number){
		this.index = index;
	}

}