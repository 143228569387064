import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService } from './../../services';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, ifSuccess, ifError, createForm, isOneOfFilled
} from './../../utils';
import { Calendar, TagsSelect } from './../../components';
import { LessonTimeframe } from './../lesson-timeframe';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from './../../interfaces';

@Component({
	selector: 'form-registration',
	templateUrl: './template.html',
	providers: [  ]
})
export class FormRegistration extends AbstractForm {

	config: any = {
		email: ["Email", "r", "e", "m|9", "M|13"],
		phone: ["Номер телефона", "r", "m|9", "M|13"],
		firstName: ["Имя", "r", "m|2", "M|30"],
		lastName: ["Фамилия", "r", "m|2", "M|30"],
		password: ["Пароль", "r", "m|8", "M|24"],
		password2: ["Повторите пароль", "r", "m|8", "M|24"]
	}
	fields: string[] = ['email', 'phone', 'firstName', 'lastName', 'password', 'password2'];
	validators: any = {validator: isOneOfFilled("email", "phone")};
	doReset: boolean = false;


	afterSubmit(){
		this.doReset = true;
		this.reset();
	};

}