<div class="wrapper">

	<div class="loading-progress absolute" #progress [class.active]="occupationsLoadingProgress">
		<span [style.width]="occupationsLoadingProgress+'%'"></span>
	</div>

	<div class="fixed-header" [class.active]="fixedHeaderEnabled">
		<!-- <div>Время</div> -->
		<div *ngFor="let room of rooms; let roomIndex = index"
			[class.disabled]="isRoomDisabled(room.room)"
			[style.width]="(1 / rooms.length * 100)+'%'"
		>
			{{room.room.title}} <span *ngIf="room.lessons.length">({{room.lessons.length}})</span>
		</div>
	</div>

	<div class="timeline">
		<div class="header">Время</div>
		<div class="day-time" *ngFor="let date of dates; let dateIndex = index"
			[class.collapsed]="height === 2"
		>
			<div class="date-title" *ngIf="dates.length > 1" [attr.data-title]="getDayName(date)" [attr.data-day]="getDayName(date, true)"
				[class.holiday]="date.getUTCDay() >= 5"
			></div>
			<ul class="clear hours" [class.collapsed]="height === 2">
				<li *ngFor="let hour of timeline; let hourIndex = index">
					<div>
						{{hour[0].hours < 10 ? "0" : ""}}{{hour[0].hours}}:00
					</div>
					<div>
						<span *ngFor="let minute of hour; let minuteIndex = index">
							{{minute.minutes}}
						</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="rooms" [style.height]="(((toHour - fromHour) * 4 * height) * dates.length + 50)+'px'">
		<div class="preloader absolute" [class.active]="!rooms || !rooms.length"><span></span></div>
		<div class="room" *ngFor="let room of rooms; let roomIndex = index"
			[class.disabled]="isRoomDisabled(room.room)"
			[style.width]="(1 / rooms.length * 100)+'%'"
		>
			<div class="header">
				{{room.room.title}} <span *ngIf="room.lessons.length">({{room.lessons.length}})</span>
			</div>
			<div class="lessons" [class.collapsed]="height === 2">
				<lesson #lessonHost
					*ngFor="let lesson of room.lessons | lessonsFilter:filter:filter; let lessonIndex = index"
					[data]="lesson" [top]="getLessonTop(lesson)"
					(mouseenter)="setDetailLesson(lesson, lessonHost)"
					(mouseleave)="setDetailLesson(null)"
					[height]="height"
				></lesson>
			</div>
		</div>
	</div>

</div>

<div class="lesson-details"
	[style.top]="detailPosition.y+'px'"
	[style.left]="detailPosition.x+'px'"
	*ngIf="detailLesson"
	[class.active]="detailLessonVisible"
	#detailLessonNode
>
	<div class="heading">
		<div class="row paddless-row">
			<div class="col-xs-12">
				<div class="h3-title collapsed">[{{detailLesson.date}}]</div>
				<div class="h3-title collapsed">{{detailLesson.title}}</div>
			</div>
			<div class="col-xs-4" data-info>
				{{getTimeString(detailLesson)}}
			</div>
			<div class="col-xs-8" data-info>
				<i class="icon icon-reload"></i>
				{{cycles[detailLesson.cycle] || 'Одноразовое занятие'}}
			</div>
			<div class="col-xs-12" data-info>
				{{detailLesson.occupation_type.title}}
				<i class="icon icon-angle-right"></i>
				{{getDetailLessonSubtype().subtitle}}
			</div>
		</div>
	</div>

	<div class="darken">
		<div class="h4-title lined collapsed"><span>Преподаватели ({{detailLesson.performers.length}})</span></div>
		<ul class="clear users-mini-list">
			<li *ngFor="let teacher of detailLesson.performers">
				<img [src]="teacher.image || 'assets/image.png'">
				{{getName(teacher)}}
			</li>
		</ul>
		<div class="h4-title lined collapsed"><span>Клиенты ({{detailLesson.clients.length}})</span></div>
		<ul class="clear users-mini-list">
			<li *ngFor="let client of detailLesson.clients">
				<img [src]="client.image || 'assets/image.png'">
				{{getName(client)}}
			</li>
		</ul>
	</div>

</div>