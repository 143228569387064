import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Vector } from './../utils/vector';


@Directive({
	selector: '[ngDroppable]',
	host: {
		"[class.drop-disabled]": "disabled",
		"[class.drop-attaching]": "attaching"
	}
})
export class Droppable {

	static instances: Droppable[] = [];
	static activeDroppable: Droppable;

	disabled: boolean = false;
	id: number = Math.floor(Math.random()*999999999);
	attaching: boolean = false;

	@Input('ngDroppable') key: string = "";
	@Input('externalData') externalData: any = {};

	@Output('onDrop') onDrop: EventEmitter<any> = new EventEmitter();
	@Output('onAttach') onAttach: EventEmitter<any> = new EventEmitter();
	@Output('onDetach') onDetach: EventEmitter<any> = new EventEmitter();

	constructor(public host: ElementRef){
		Droppable.instances.push(this);
	}

	ngOnInit(){
		// console.log(this);
	}

	disable(){
		this.disabled = true;
	}

	enable(){
		this.disabled = false;
	}

	// ngOnChanges(ch: SimpleChanges){
	// 	console.log(ch);
	// }
	ngOnDestroy(){
		for (let i = 0; i < Droppable.instances.length; i++){
			if (Droppable.instances[i] === this){
				Droppable.instances.splice(i, 1);
				return;
			}
		}
	}

	drop(){
		this.onDrop.emit(this);
	}

	static enableAll(key?: string){
		for (let i = 0; i < Droppable.instances.length; i++){
			if (Droppable.instances[i].key === key || !key){
				Droppable.instances[i].enable();
				Droppable.instances[i].detach();
			}
		}
	}

	static disableAll(key?: string){
		for (let i = 0; i < Droppable.instances.length; i++){
			if (Droppable.instances[i].key === key || !key){
				Droppable.instances[i].disable();
			}
		}
	}

	attach(){
		Droppable.activeDroppable = this;
		this.attaching = true;
		this.onAttach.emit(this);
	}

	detach(){
		Droppable.activeDroppable = null;
		this.attaching = false;
		this.onDetach.emit(this);
	}

	static traverseByKey(key: string, cb: Function){
		Droppable.instances.filter((d: Droppable)=> {
			return d.key === key;
		}).map((droppable: Droppable, index: number)=> {
			cb(droppable, index);
		});
	}

}