import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { CONFIG } from './../config';

@Injectable()
export class SettingsService {
SettingsService
	subcategories: any = {
		occupationType: "occupation_type/",
		occupationSubtype: "occupation_subtype/",
		classTypeCost: "class_type_cost/",
		dynamic: "dynamic/",
		quizesMultichoiceQuestion: "quizzes/multichoice-question/",
		quizesTextQuestion: "quizzes/text-answer-question/",
		quizzesQuestion: "quizzes/question/",
		quizzesMultichoiceAnswer: "quizzes/multichoice-answer/",
		quizzesSort: "quizzes/sort_questions/"
	}

	headers: HttpHeaders = new HttpHeaders();

	constructor(private http: HttpClient){
		this.headers.append('Content-Type', 'application/json');
	}

	getOccupationTypes(){
		return this.http.get(CONFIG.api.settings+this.subcategories.occupationType);
	}

	createOccupationType(data: any){
		return this.http.post(CONFIG.api.settings+this.subcategories.occupationType, {
			title: data.title
		});
	}

	updateOccupationType(data: any, id: number | string){
		return this.http.patch(CONFIG.api.settings+this.subcategories.occupationType+id+"/", {
			title: data.title
		});
	}

	deleteOccupationTypes(id: number | string){
		return this.http.delete(CONFIG.api.settings+this.subcategories.occupationType+id+"/");
	}

	/* SUBTYPES */

	createOccupationSubtype(title: string, occupationId: number){
		return this.http.post(CONFIG.api.settings+this.subcategories.occupationSubtype, {
			subtitle: title,
			occupation_type: occupationId
		});
	}

	updateOccupationSubtype(title: string, subtypeId: number){
		return this.http.patch(CONFIG.api.settings+this.subcategories.occupationSubtype+subtypeId+"/", {
			subtitle: title
		});
	}

	deleteOccupationSubtype(id: number){
		return this.http.delete(CONFIG.api.settings+this.subcategories.occupationSubtype+id+"/");
	}

	// cost
	createCost(data: any){
		return this.http.post(CONFIG.api.settings+this.subcategories.classTypeCost, {
			kind: data.kind,
			department: data.department,
			room: data.room,
			type: data.type,
			subtype: data.subtype,
			performer: data.teacher
		});
	}

	updateCost(data: any){
		return this.http.patch(CONFIG.api.settings+this.subcategories.classTypeCost+data.pk+"/", {
			room: data.room,
			type: data.type,
			subtype: data.subtype,
			classes_cost: data.classes_cost
		});
	}

	getCostList(){
		return this.http.get(CONFIG.api.settings+this.subcategories.classTypeCost);
	}

	deleteCost(id: number){
		return this.http.delete(CONFIG.api.settings+this.subcategories.classTypeCost+id+"/");
	}

	/* quiz */
	createQuizQuestion(data: any){
		return this.http.post(
			CONFIG.api.settings+(data.type === "TEXT" ? this.subcategories.quizesTextQuestion : this.subcategories.quizesMultichoiceQuestion)
		, {
			subject: data.title,
			content_prefix: data.prefix || "",
			content_postfix: data.postfix || "",
			question_sort: data.sort,
			answers_type: data.type,
		});
	}

	updateQuizQuestion(question: any, data: any){
		return this.http.patch(question.url, {
			subject: data.title,
			content_prefix: data.prefix || "",
			content_postfix: data.postfix || "",
			question_sort: data.sort,
			answers_type: data.type,
		});
	}

	setQuestionsSorting(sotrting: any[]){
		return this.http.patch(CONFIG.api.settings+this.subcategories.quizzesSort, sotrting);
	}

	getQuizQuestions(){
		return this.http.get(CONFIG.api.settings+this.subcategories.quizzesQuestion);
	}

	deleteQuizQuestion(q: any){
		return this.http.delete(q.url);
	}

	createQuizAnswer(data: any){
		return this.http.post(CONFIG.api.settings+this.subcategories.quizzesMultichoiceAnswer, data);
	}

	updateQuizAnswer(answer: any){
		return this.http.patch(answer.url, { content: answer.content });
	}

	deleteQuizAnswer(answer: any){
		return this.http.delete(answer.url);
	}

	getQuizAnswers(){
		return this.http.get(CONFIG.api.settings+this.subcategories.quizzesMultichoiceAnswer);
	}
}