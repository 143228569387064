<button type="button" class="btn btn-large" (click)="openWindow()">
	<i class="icon icon-plus primary"></i>
	Создать тип занятия
</button>

<modal #modal [title]="'Тип занятия'">
	<form action="#" [formGroup]="form">
		<div class="form-group"
			[class.error]="ifError('title')"
			[class.success]="ifSuccess('title')"
		>
			<label for="title">Название занятия</label>
			<input type="text" class="input small" formControlName="title" id="title" placeholder="Услуги психотерапевта" #firstFocus
			>
		</div>
		<hr class="light">
		<button type="button" class="btn btn-transparent btn-small" (click)="submit()">
			<i class="icon icon-checked small offset-right success"></i>
			Применить
		</button>
		<button type="button" class="btn btn-transparent btn-small" (click)="modal.hide()">
			<i class="icon icon-undo small offset-right"></i>
			Отмена
		</button>
	</form>
</modal>