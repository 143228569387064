import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { OccupationsService, DepartmentsService, SettingsService, UsersService, NotificatorService } from './../../services';
import { Popover } from './../popover';
import { Scrollable } from './../scrollable';
import { getElementOffset, getScrollTop } from './../../utils';
import { Lesson } from './lesson';
import { CONFIG } from './../../config';


@Component({
	selector: 'schedule',
	templateUrl: './template.html',
	host: {},
	providers: [ OccupationsService, DepartmentsService, SettingsService, UsersService, NotificatorService ]
})
export class Schedule {

	lessonsRaw: any[] = [];
	lessons: any[] = [];

	roomsRaw: any[] = [];
	rooms: any[] = [];
	roomsKeys: any = {};

	types: any[] = [];
	typesKeys: any = {};

	users: any[] = [];

	usersKeys: any = {};

	fromHour: number = 8;
	toHour: number = 21;
	timeline: any[] = [];
	height: number = 15;

	popover: any = {
		open: false,
		top: 0, left: 0,
		origin: 'top'
	};

	cycles: any = {
		ONE: "Одноразовое занятие",
		MWF: "Понедельник-Среда-Пятница",
		TT: "Вторник-Четверг",
		TTS: "Вторник-Четверг-Суббота"
	};

	@Input() dates: Date[] = [];
	@Input() type: string = "day";

	detailLesson: any = null;
	detailShowing: boolean = false;
	detailPosition: any = {x: 0, y: 0};

	filter: any;

	occupationsLoadingProgress: number = 0;

	fixedHeaderEnabled: boolean = false;

	detailLessonVisible: boolean = false;

	@ViewChild('detailsScrollable') detailsScrollable: Scrollable;

	@ViewChild('detailLessonNode') detailLessonNode: ElementRef;

	constructor(
		private occupationsService: OccupationsService,
		private departmentsService: DepartmentsService,
		private settingsService: SettingsService,
		private usersService: UsersService,
		private notificatorService: NotificatorService,
		private host: ElementRef
	){

		// time
		let newTimeline: any[] = [];
		for (let i = this.fromHour; i < this.toHour; i++){
			let hour = [];
			for (let j = 0; j < 60; j += 15){
				hour.push({
					hours: i, minutes: j
				});
			}
			newTimeline.push(hour);
		}
		this.timeline = newTimeline;
		// this.fetchOccupations();

		document.addEventListener('scroll', (event: any)=> {
			let top = getScrollTop();
			if (!this.fixedHeaderEnabled && top > 300){
				this.fixedHeaderEnabled = true;
			} else if (this.fixedHeaderEnabled && top <= 300){
				this.fixedHeaderEnabled = false;
			}
		});

	}

	fetchOccupations(type?: string){
		this.occupationsLoadingProgress = 0;
		this.height = type === 'month' ? 2 : 15;
		this.rooms = [];
		// lessons
		// console.log(this.height);
		(type === "month" ? this.occupationsService.getOccupationsForMonth(this.filter) :
		 type === "week" ? this.occupationsService.getOccupationsForWeek(this.filter) :
		 this.occupationsService.getOccupationsForDay(this.filter)
		).subscribe((res: any)=> {
			this.lessonsRaw = res;
			console.log('Schedule taken: ', res.length + " items");
			this.notificatorService.push({
				title: "SCHEDULE",
				text: `Найдено занятий: ${res.length}`
			});
			this.occupationsLoadingProgress = 40;
			// this.lessons = res;
			// console.log('lessons', this.lessonsRaw);
			// console.log(this.lessonsRaw[0]);

			this.usersService.getUsers().subscribe((res: any)=> {
				this.users = res;
				res.map(u => this.usersKeys[u.pk] = u);
				this.occupationsLoadingProgress = 70;

				// types
				this.settingsService.getOccupationTypes().subscribe((res: any)=> {
					this.types = res;
					res.map(t => this.typesKeys[t.pk] = t);

					// rooms
					this.departmentsService.getRooms().subscribe((res: any)=> {
						this.roomsRaw = res.slice(0,100).sort((a, b)=> {
							return (new Date(b.changed.created_on).getTime() - new Date(a.changed.created_on).getTime()) * -1;
						});
						this.occupationsLoadingProgress = 100;
						res.map(r => this.roomsKeys[r.pk] = r);
						// console.log('rooms', this.roomsRaw);

						let rooms: any[] = [];
						this.roomsRaw.map((room: any)=> {
							let lessons = this.lessonsRaw.filter((l: any)=> l.room === room.pk );
							rooms.push({
								room: room,
								lessons: lessons.map((l: any)=> {
									return {
										...l,
										occupation_type: this.typesKeys[l.occupation.occupation_type],
										clients: l.occupation.clients.map(c => this.usersKeys[c]),
										performers: l.occupation.performers.map(p => this.usersKeys[p]),
										room: this.roomsKeys[l.room],
										title: l.occupation.title
									};
								})
							});
						});
						this.rooms = rooms;
						setTimeout(()=> {
							this.occupationsLoadingProgress = 0;
						}, 300);
					});
				});
			});


		});
	}

	getLessonTop(lesson: any): number {
		let startDay: number = new Date(this.filter.dates.split(',')[0]).differenceInDays(new Date(lesson.date)); // -1
		let dayHeight: number = (this.toHour - this.fromHour) * (this.height * 4);
		let startHour: number = Math.floor(lesson.from_hour / 100) - this.fromHour;
		// console.log(this.filter.dates.split(',')[0], lesson.date);
		return (startHour * (this.height * 4) + (lesson.from_hour % 100)) + (startDay * dayHeight);
	}

	// openPopover(lesson: any){
	// 	console.log(lesson);

	// 	let parentBbox = this.host.nativeElement.getBoundingClientRect();

	// 	let node = lesson.host;
	// 	let bbox: ClientRect = node.getBoundingClientRect();
	// 	this.popover.open = true;
	// 	this.popover.top = bbox.top - parentBbox.top;
	// 	this.popover.left = bbox.left - parentBbox.left;
	// 	this.popover.origin = window.innerWidth - bbox.left < 500 ? 'left' : 'top';

	// 	console.log(window.innerWidth - bbox.left);
	// }

	setDetailLesson(lessonObj: any, lesson: Lesson){
		this.detailLessonVisible = false;
		this.detailLesson = lessonObj;

		if (!lesson) return;


		setTimeout(()=> {
			let bbox: ClientRect = lesson.host.getBoundingClientRect();
			this.detailPosition.x = Math.floor(bbox.left + bbox.width/2);
			this.detailPosition.y = Math.floor(bbox.top + document.documentElement.scrollTop);

			setTimeout(()=> {
				if (this.detailLessonNode){
					let hintBbox: ClientRect = (this.detailLessonNode.nativeElement as Element).getBoundingClientRect();
					let hintDistance = Math.getElementDistances(hintBbox);
					let lessonDistance = Math.getElementDistances(bbox);
					console.log(hintDistance, lessonDistance);

					// shift verticaly
					if (lessonDistance.bottom > hintBbox.height){
						this.detailPosition.y += bbox.height;
					} else {
						this.detailPosition.y -= hintBbox.height;
					}
					// shift horizontaly
					if (hintDistance.right < 0){
						this.detailPosition.x -= hintBbox.width / 2 - bbox.width/2;
					} else if (hintDistance.left < 200){
						this.detailPosition.x += hintBbox.width / 2 + bbox.width/2;
					}

					this.detailLessonVisible = true;
				}
			});
		});
	}

	getTimeString(lesson: any){
		return this.occupationsService.getLessonTimeString(lesson);
	}

	getDetailLessonSubtype(){
		for (let i = 0; i < this.detailLesson.occupation_type.occupationsubtype_set.length; i++){
			if (this.detailLesson.occupation_type.occupationsubtype_set[i].pk === this.detailLesson.occupation.occupation_subtype){
				return this.detailLesson.occupation_type.occupationsubtype_set[i];
			}
		}
		return {
			subtitle: '-'
		};
	}

	getName(user: any): string {
		return user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email;
	}

	isRoomDisabled(room: any){
		return (room.type === 'L' && !this.filter.realRooms) ||
						(room.type === 'V' && !this.filter.virtualRooms);
	}

	getDayName(date: Date, force: boolean = false){
		let DD = date.getDate(),
				DDD = date.getUTCDay(),
				MM = date.getMonth(),
				YYYY = date.getFullYear(),
				h = date.getHours(),
				m = date.getMinutes();
		let mask = (this.height === 2 ? `dd` : `dddd, dd mmmm yyyy`);
		function handleZero(value: number){
			return (value < 10 ? "0"+value : value).toString();
		};
		if (force){
			mask = 'ddd';
		}
		return mask
			.replace(/mmmm/gim, CONFIG.monthsNamesFull2[MM])
			.replace(/mmm/gim, CONFIG.monthsNames[MM])
			.replace(/dddd/gim, CONFIG.dayNamesFull[DDD])
			.replace(/ddd/gim, CONFIG.dayNames[DDD])
			.replace(/dd/gim, DD.toString())
			.replace(/mm/gim, handleZero(MM+1))
			.replace(/yyyy/gim, YYYY.toString())
			.replace(/h/gim, handleZero(h)).replace(/m/gim, handleZero(m));
	}

}