import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview
} from './../utils';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { CONFIG } from './../config';
import { DepartmentsService, NotificatorService } from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';
import { FormDepartmentAdd, AbstractFormModes } from './../forms';
import { GlobalEvent, GlobalEventInterface } from './../utils';


@Component({
	selector: 'departments-page',
	templateUrl: './departments-page.html',
	providers: [ DepartmentsService, NotificatorService ]
})
export class DepartmentsPage {

	currentDepartment: any = null;

	// data
	departments: any[] = [];
	departmentsLoading: boolean = true;

	@ViewChild('addDepartmentForm') addDepartmentForm: FormDepartmentAdd;

	globalEvent: GlobalEvent;

	constructor(
		private departmentsService: DepartmentsService,
		private notificatorService: NotificatorService
	){

		this.globalEvent = new GlobalEvent();
		this.globalEvent.subscribe((event: GlobalEventInterface)=> {
			// console.log(event);
			if (event.key === "department.change"){
				this.fetchDepartments();
			}
		});

	}

	ngOnInit(){
		this.fetchDepartments();
	}

	private fetchDepartments(){
		this.departmentsLoading = true;
		this.departmentsService.getDepartments().subscribe((res: any)=> {
			this.departments = res.map((d: any)=> {
				return toCamelKeys(d);
			}).sort((a: any, b: any)=> {
				return new Date(b.changed.createdOn).getTime() - new Date(a.changed.createdOn).getTime();
			});
			console.log(this.departments[1]);
			this.departmentsLoading = false;
		});
	}


	/**/
	deleteDepartment(id: number, index: number){
		this.departmentsService.deleteDepartment(id.toString()).subscribe((res: any)=> {
			this.notificatorService.push({
				title: "Departments",
				text: `Отдел "${this.departments[index].title}" удален`
			});
			this.departments.splice(index, 1);
		});
	}

	handleAddDepartment(data: any){
		let flatData = makeFlatValue(data, ['phones', 'emails']);
		flatData.files = this.addDepartmentForm.files;
		this.departmentsService.createDepartment(flatData).subscribe((res: any)=> {
			if (data.files){
				this.departmentsService.setDepartmentImage(res.pk, data.files[0]).subscribe((res: any)=> {
					this.fetchDepartments();
				});
			} else {
				this.fetchDepartments();
			}
			this.notificatorService.push({
				title: "Departments",
				text: `Отдел "${flatData.name}" успешно создан`,
				type: NotificationType.Success
			});
		});
	}

	handleEditDepartment(data: any, id: string){
		let flatData = makeFlatValue(data, ['phones', 'emails']);
		flatData.files = this.addDepartmentForm.files;
		this.departmentsService.editDepartment(flatData, id).subscribe((res: any)=> {
			if (data.files){
				this.departmentsService.setDepartmentImage(res.pk, data.files[0]).subscribe((res: any)=> {
					this.fetchDepartments();
				});
			} else {
				this.fetchDepartments();
			}
			this.notificatorService.push({
				title: "Departments",
				text: `Отдел "${flatData.name}" изменен`
			});
		});
	}

	handleDeleteDepartment(department: any){
		this.notificatorService.push({
			title: "Departments",
			text: `Филиал "${department.title}" удален`,
			type: NotificationType.Error
		});
		this.fetchDepartments();
	}

	handleDepartmentRoom(data: any){
		this.departmentsService.createRoom(data).subscribe((res: any)=> {
			if (data.files){
				this.departmentsService.setRoomImage(res.pk, data.files).subscribe((res: any)=> {
					this.fetchDepartments();
				});
			} else {
				this.fetchDepartments();
			}
			this.notificatorService.push({
				title: "Rooms",
				text: `Комната "${data.title}" добавлена`,
				type: NotificationType.Success
			});
		});
	}


}