import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
	selector: 'range',
	templateUrl: './template.html',
	host: {
		"[attr.did]": "did"
	}
})
export class Range {

	static instances: Range[] = [];
	static hostListener: boolean = false;
	static did: number = 0;
	private did: number = 0;

	@Input('min') min: number = 0;
	@Input('max') max: number = 100;
	@Input('step') step: number = 1;
	@Input('multiple') multiple: boolean = false;
	@Input('value') value: number | number[] = 0;


	handleA: number = 0;
	handleB: number = 0;

	isDragging: boolean = false;

	startPos: number = 0;
	activeHandle: string = 'handleA';

	constructor(private sanitizer: DomSanitizer){
		Range.instances.push(this);
		this.did = Range.did;
		Range.did++;
		if (!Range.hostListener){
			document.addEventListener('mouseup', (event: Event | any)=> {
				this.isDragging = false;
			});
			document.addEventListener('mousemove', (event: Event | any)=> {
				if (!this.isDragging) return;
				let currentPos = event.clientX-this.startPos;
				console.log(currentPos, this.activeHandle);

			});
			Range.hostListener = true;
		}
	}

	ngOnChanges(changes: SimpleChanges){
		if (changes.value){
			if (typeof changes.value.currentValue === 'number'){
				this.handleA = (changes.value.currentValue-this.min) / (this.max-this.min);
			} else {
				this.handleA = (changes.value.currentValue[0]-this.min) / (this.max-this.min);
				this.handleB = (changes.value.currentValue[1]-this.min) / (this.max-this.min);
			}
		}
	}

	onMouseDown(event: any, range: string){
		event.preventDefault();
		this.isDragging = true;
		this.startPos = event.clientX;
		this.activeHandle = range;
		// console.log(range);
	}

	onMouseMove(event: any, range: string){
	}

	getProgressStyle(){
		if (this.multiple){
			return this.sanitizer.bypassSecurityTrustStyle(`
				left: ${(this.handleA)*100}%;
				width: ${(this.handleB-this.handleA)*100}%;
			`);
		} else {
			return this.sanitizer.bypassSecurityTrustStyle(`
				width: ${(this.handleA)*100}%;
			`);
		}
	}

}


class RangeHandler {

	position: number = 0;

	constructor(position: number){
		console.log(position);
		this.position = position;
	}

}