<div [formGroup]="form">
	<button type="button" class="btn btn-small btn-transparent"
		tabindex="-1"
		*ngIf="!indexes.length"
		(click)="add('', true)"
	><i class="icon icon-plus"></i>Создать</button>

	<div class="form-group controls-group" *ngFor="let f of indexes; let i = index"
		[class.error]="ifError(f)"
		[class.success]="ifSuccess(f)"
	>
		<label for="">{{getPlaceholder(i)}}</label>
		<input type="text" class="input small"
			#input
			(input)="change($event, f, i)"
			[placeholder]="placeholder"
			[formControlName]="f"
		>
		<button type="button" class="btn btn-small btn-round btn-transparent"
			tabindex="-1"
			*ngIf="i > min-1"
			(click)="remove(f, i)"
		><i class="icon icon-trash"></i></button>
		<button type="button" class="btn btn-small btn-round btn-transparent"
			tabindex="-1"
			*ngIf="i >= indexes.length-1 && i < max-1"
			(click)="add('', true)"
		><i class="icon icon-plus"></i></button>
	</div>
</div>