export function handleZero(val: any){
	if (val.length) {
		val = parseInt(val);
	}
	return val < 10 ? "0"+val : val;
};

export function getDate(timestamp: number, mask: string = ""){
	let date = new Date(timestamp);
	return `
		${handleZero(date.getUTCDate())}.
		${handleZero(date.getUTCMonth())}.
		${handleZero(date.getUTCFullYear())}
	`.replace(/(\s| |\n|\r|\t)/gim, '');
}