<ng-content></ng-content>
<dropdown [hideButton]="true" #dropdown>
	<div class="row paddless-row middle-xs">
		<div class="col-xs-5">
			<select class="input small transparent" (input)="setHours($event.target.value)" #hoursSelect>
				<option [value]="option" *ngFor="let option of hours">{{option}}</option>
			</select>
		</div>
		<div class="col-xs-2 align-center">:</div>
		<div class="col-xs-5">
			<select class="input small transparent" (input)="setMinutes($event.target.value)" #minutesSelect>
				<option [value]="option" *ngFor="let option of minutes">{{option}}</option>
			</select>
		</div>
	</div>
</dropdown>