import { Component, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { CONFIG } from './../../config';

@Component({
	selector: 'calendar',
	templateUrl: './template.html',
	host: {
		"[class.collapsed]": "collapsed"
	}
})
export class Calendar {

	start: Date;
	end: Date;

	@Input('months') months: number = 3;
	@Input('multiple') multiple: boolean = false;
	@Input('showDay') showDay: boolean = false;
	@Input('showDayButtons') showDayButtons: boolean = false;
	@Input('collapsed') collapsed: boolean = false;

	page: number = 0;

	calendar: any[] = [];

	selection: CalendarSelection = {
		start: null,
		end: null
	};

	@Output('onchange') onchange: EventEmitter<any> = new EventEmitter();
	@Output('click') click: EventEmitter<any> = new EventEmitter();

	constructor(){
		this.start = new Date().getMonthStart();
		this.end = this.start.clone().addMonths(this.months-1).getMonthEnd();
		this.generateCalendar();
	}

	ngOnChanges(changes: SimpleChanges){
		if (changes.months){
			this.end = this.start.clone().addMonths(changes.months.currentValue-1).getMonthEnd();
			this.generateCalendar();
		}
	}

	private generateCalendar(){
		this.calendar = [];
		for (let m = 0; m < this.months; m++){
			let startOfMonth = this.start.clone().addMonths(m);
			let length = startOfMonth.getMonthLength();
			let weeks = Math.ceil(length / 7) + 1;
			this.calendar[m] = {
				start: startOfMonth.getMonthStart(),
				end: startOfMonth.getMonthEnd(),
				weeks: [],
				index: startOfMonth.getMonth()
			};
			for (let w = 0; w < weeks; w++){
				let weekStart = startOfMonth.clone().getWeekStart().addWeeks(w);
				let weekDays: any[] = [];
				for (let d = 0; d < 7; d++){
					weekDays.push(weekStart.clone().addDays(d));
				}
				this.calendar[m].weeks.push(weekDays);
			}
		}
	}

	reset(){
		this.selection.start = null;
		this.selection.end = null;
		this.onchange.emit({
			start: this.selection.start, end: this.selection.end
		});
	}

	min(a, b){
		return Math.min(a, b);
	}

	getMonthName(index: number){
		return CONFIG.monthsNames[index];
	}
	getMonthNameFull(index: number){
		return CONFIG.monthsNamesFull[index];
	}
	getDayName(index: number){
		return CONFIG.dayNames[index];
	}
	getDayNameFull(index: number){
		return CONFIG.dayNamesFull[index];
	}
	getMonthNameHandled(index: number){
		return (this.collapsed ? CONFIG.monthsNames : CONFIG.monthsNamesFull)[index];
	}
	getDayNameHandled(index: number){
		return (this.collapsed ? CONFIG.dayNames : CONFIG.dayNamesFull)[index];
	}


	isDisabled(day: Date){
		return day < new Date().addDays(-1) && day >= this.start;
	}

	isHighlighted(day: Date){
		if (!this.selection.start || !this.selection.end) return false;
		return day.isGreaterEqual(this.selection.start) && day.isLessEqual(this.selection.end);
	}

	slideToPrevMonth(){
		if (this.page > 0){
			this.page--;
		}
	}

	slideToNextMonth(){
		if (this.page < this.months-1){
			this.page++;
		}
	}

	prevDay(key: string){
		if (!this.selection[key]) return;
		let oldDay: Date = this.selection[key].clone();
		let prevDay: Date = oldDay.clone().addDays(-1);
		if (prevDay.getTime() >= new Date().getDayStart().getTime()){
			this.selection[key] = prevDay;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			if ((prevDay.getMonth() < oldDay.getMonth()) || (prevDay.getFullYear() < oldDay.getFullYear())){
				this.slideToPrevMonth();
			}
		}
	}

	nextDay(key: string){
		if (!this.selection[key]) return;
		let oldDay: Date = this.selection[key].clone();
		let nextDay: Date = oldDay.clone().addDays(1);
		if (nextDay.getTime() <= this.end.getTime()){
			this.selection[key] = nextDay;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			if ((nextDay.getMonth() > oldDay.getMonth()) || (nextDay.getFullYear() > oldDay.getFullYear())){
				this.slideToNextMonth();
			}
		}
	}

	prevWeek(){
		if (!this.selection.start) return;
		let anchor: Date = this.selection.start.clone().addDays(-3),
				start: Date = anchor.clone().getWeekStart(),
				end: Date = anchor.clone().getWeekEnd();
		if (end.getTime() > this.start.getTime()){
			this.selection.start = start;
			this.selection.end = end;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			console.log(start, end);
			if (start.getMonth() < end.getMonth() || (start.getFullYear() < end.getFullYear())){
				this.slideToPrevMonth();
			}
		}
	}

	nextWeek(){
		if (!this.selection.start) return;
		let anchor: Date = this.selection.end.clone().addDays(3),
				start: Date = anchor.clone().getWeekStart(),
				end: Date = anchor.clone().getWeekEnd();
		if (start.getTime() < this.end.getTime()){
			this.selection.start = start;
			this.selection.end = end;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			if (end.getMonth() > start.getMonth() || (end.getFullYear() > start.getFullYear())){
				this.slideToNextMonth();
			}
		}
	}

	prevMonth(){
		if (!this.selection.start) return;
		let anchor: Date = this.selection.start.clone().addDays(-15),
				start: Date = anchor.clone().getMonthStart(),
				end: Date = anchor.clone().getMonthEnd();
		if (end.getTime() > this.start.getTime()){
			this.selection.start = start;
			this.selection.end = end;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			this.slideToPrevMonth();
		}
	}

	nextMonth(){
		if (!this.selection.start) return;
		let anchor: Date = this.selection.end.clone().addDays(15),
				start: Date = anchor.clone().getMonthStart(),
				end: Date = anchor.clone().getMonthEnd();
		if (start.getTime() < this.end.getTime()){
			this.selection.start = start;
			this.selection.end = end;
			this.onchange.emit({
				start: this.selection.start, end: this.selection.end
			});
			this.slideToNextMonth();
		}
	}

	setDay(day: Date){
		if (!this.multiple){
			this.selection.start = day;
			this.onchange.emit(this.selection.start);
		} else {
			if (this.selection.end && this.selection.start){
				this.selection.end = this.selection.start = null;
			}
			if (!this.selection.start){
				this.selection.start = day;
			} else if (!this.selection.end && this.selection.start){
				this.selection.end = day;
				if (this.selection.start > this.selection.end){
					let temp = this.selection.start;
					this.selection.start = this.selection.end;
					this.selection.end = temp;
				}
			}
		}
	}

	getStart(){
		return this.selection.start ? this.selection.start.parseDate('dd.mm.yyyy') : 'дд.мм.гггг';
	}

	getEnd(){
		return this.selection.end ? this.selection.end.parseDate('dd.mm.yyyy') : 'дд.мм.гггг';
	}


}

interface CalendarSelection {
	start: Date;
	end: Date;
}