<div class="container-fluid">

	<div class="card">
		<div class="row">

			<div class="col-xs-5 col-lg-4">
				<div class="heading">
					<div class="h3-title collapsed">Типы занятий</div>
				</div>
				<form>
					<div class="row paddless-row">
						<div class="col-xs-4">
							<label for="department">Филиал</label>
							<div class="form-group">
								<i class="icon icon-drop-down input-icon"></i>
								<select class="input" id="department" (input)="changeDepartment($event.target.value)" name="department">
									<option value="0" selected="true">Все</option>
									<option [value]="department.pk" *ngFor="let department of departments">{{department.title}}</option>
								</select>
							</div>
						</div>
						<div class="col-xs-4">
							<label for="occupationName">Тип занятия</label>
							<div class="form-group">
								<i class="icon icon-search input-icon"></i>
								<input type="text" class="input" id="occupationName" placeholder="Фильтр..." (input)="changeOccupationType($event.target.value)">
							</div>
						</div>
						<div class="col-xs-4">
							<label for="subOccupationName">Подтип занятия</label>
							<div class="form-group">
								<i class="icon icon-search input-icon"></i>
								<input type="text" class="input" id="subOccupationName" placeholder="Фильтр..." (input)="changeOccupationSubtype($event.target.value)">
							</div>
						</div>
					</div>
				</form>
				<div class="row offset-top">
					<div class="col-xs-6">
						<div class="row paddless-row bottom-xs">
							<div class="col-xs-9">
								<div class="h4-title">Педагоги</div>
								<label>Назначено на</label>
							</div>
							<div class="col-xs-3 align-right">
								<div class="checkbox inline users-mini-list-checker">
									<input type="checkbox" id="selectAllTeachers"
										(click)="selectAllTeachers($event.target.checked)"
									>
									<label for="selectAllTeachers"></label>
								</div>
							</div>
						</div>
						<scrollable [height]="'300px'" [maxHeight]="'300px'" class="bordered">
							<div class="verticaly-middle align-center" *ngIf="!teachersKeys.length">
								<div class="inner">Загрузка...</div>
							</div>
							<div *ngFor="let key of teachersKeys; let keyIndex = index">
								<!-- #teachers="getFilteredTeachers(key)" -->
								<ul class="clear users-mini-list" *ngIf="filtered.teachers[key].length">
									<li>
										<div class="title">{{key.toLowerCase()}}</div>
										<div class="checkbox">
											<input type="checkbox" [attr.id]="key+'_select_all'"
												(click)="selectAllTeachersByKey(key, $event.target.checked); getTeachersList()"
											>
											<label [attr.for]="key+'_select_all'"></label>
										</div>
									</li>
									<li *ngFor="let teacher of filtered.teachers[key]; let teacherIndex = index">
										<img [src]="teacher.image || 'assets/image.png'">
										{{ teacher.first_name && teacher.last_name ? teacher.first_name+' '+teacher.last_name : teacher.email }}
										<div class="checkbox">
											<input type="checkbox" [attr.id]="key+'_teacher_'+teacherIndex" [(ngModel)]="teacher.selected" (change)="getTeachersList()">
											<label [attr.for]="key+'_teacher_'+teacherIndex"></label>
										</div>
									</li>
								</ul>
							</div>
						</scrollable>
					</div>
					<div class="col-xs-6">
						<div class="row paddless-row bottom-xs">
							<div class="col-xs-9">
								<div class="h4-title">Комнаты</div>
								<label>Для занятий</label>
							</div>
							<div class="col-xs-3 align-right">
								<div class="checkbox inline users-mini-list-checker">
									<input type="checkbox" id="selectAllRooms"
										(click)="selectAllRooms($event.target.checked)"
									>
									<label for="selectAllRooms"></label>
								</div>
							</div>
						</div>
						<scrollable [height]="'300px'" [maxHeight]="'300px'" class="bordered">
							<div class="verticaly-middle align-center" *ngIf="!rooms.length">
								<div class="inner">Загрузка...</div>
							</div>
							<ul class="clear rooms-mini-list">
								<li *ngFor="let room of filtered.rooms; let i = index">
									<img [src]="room.image || 'assets/image.png'">
									<span>
										{{ room.title }}
										<small>{{room.title === "L" ? "В здании" : "Виртуальная"}}</small>
									</span>
									<div class="checkbox">
										<input type="checkbox" [attr.id]="'room_'+i" [(ngModel)]="room.selected" (click)="getTeachersList()">
										<label [attr.for]="'room_'+i"></label>
									</div>
								</li>
							</ul>
						</scrollable>
					</div>
				</div>
			</div>

			<div class="col-xs-7 col-lg-8 border-left">
				<div class="heading">
					<div class="h3-title collapsed">Настройка стоимости</div>
				</div>
				<div class="h4-title offset-bottom">Индивидуальные занятия</div>
				<div class="alert" *ngIf="!costListIndividualFiltered.length">Список пуст. Выберите преподавателей или добавьте новый ценник</div>
				<table class="table stripped small" *ngIf="costListIndividualFiltered.length">
					<thead>
						<tr>
							<th>Комната</th>
							<th>Тип занятия</th>
							<th>Подтип занятия</th>
							<th style="width: 70px">30 мин</th>
							<th style="width: 70px">45 мин</th>
							<th style="width: 70px">60 мин</th>
							<th style="width: 70px">75 мин</th>
							<th style="width: 70px">90 мин</th>
							<th style="width: 50px"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let cost of costListIndividualFiltered; let costIndex = index"
							[class.highlighted]="isSameCost(cost)"
						>
							<td>
								<div *ngIf="!isSameCost(cost)">
									{{roomsKeys[cost.room]?.title}} <br>
									<small class="grey">{{roomsKeys[cost.room]?.type === "L" ? "В здании" : "На выезде"}}</small>
								</div>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.room">
										<option [value]="room.pk" *ngFor="let room of rooms">{{room.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)" [innerHTML]="highlightSearch(occupationTypesKeys[cost.type]?.title, 'type')"></span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.type">
										<option [value]="type.pk" *ngFor="let type of occupationTypes">{{type.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)" [innerHTML]="highlightSearch(occupationSubtypesKeys[cost.subtype]?.title, 'subtype')"></span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.subtype">
										<option [value]="subtype.pk" *ngFor="let subtype of occupationTypesKeys[cost.type].occupationsubtype_set">{{subtype.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[0])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[0], true)" (input)="handleCostPrice($event.target.value, 0)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[1])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[1], true)" (input)="handleCostPrice($event.target.value, 1)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[2])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[2], true)" (input)="handleCostPrice($event.target.value, 2)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[3])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[3], true)" (input)="handleCostPrice($event.target.value, 3)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[4])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[4], true)" (input)="handleCostPrice($event.target.value, 4)">
								</div>
							</td>
							<td>
								<dropdown [btn]="'btn-round btn-block btn-transparent'" [icon]="'dots-vertical'" [hideArrow]="true" class="inline small" data-origin="right" #dropdown>
									<ul class="clear links-list">
										<li *ngIf="!activeCost">
											<div class="link" (click)="edit(cost, costIndex); dropdown.hide()"><i class="icon icon-pencil"></i>Редактировать</div>
										</li>
										<li *ngIf="activeCost">
											<div class="link" (click)="save(); dropdown.hide()"><i class="icon icon-checked primary"></i>Сохранить</div>
										</li>
										<li *ngIf="activeCost">
											<div class="link" (click)="edit(null); dropdown.hide()"><i class="icon icon-undo"></i>Отменить</div>
										</li>
										<li>
											<div class="link" (click)="delete(cost, costIndex); dropdown.hide()"><i class="icon icon-trash"></i>Удалить</div>
										</li>
									</ul>
								</dropdown>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="offset-top">
					<div class="btn-group align-right">
						<button *ngIf="activeCost && activeCost.kind === 'I'" class="btn btn-small btn-primary" (click)="save()">Сохранить</button>
						<button *ngIf="activeCost && activeCost.kind === 'I'" class="btn btn-small" (click)="edit(null)">Отмена</button>

						<form-class-cost-add
							*ngIf="!activeCost && selectedTeachers.length"
							[teachers]="selectedTeachers"
							[rooms]="selectedRooms"
							[roomsKeys]="roomsKeys"
							[types]="occupationTypes"
							[typesKeys]="occupationTypesKeys"
							[kind]="'I'"
							(onCreate)="addNewCost($event)"
						></form-class-cost-add>
					</div>
				</div>

				<div class="h4-title offset-top offset-bottom">Груповые занятия</div>
				<div class="alert" *ngIf="!costListGroupFiltered.length">Список пуст. Выберите преподавателей или добавьте новый ценник</div>
				<table class="table stripped small" *ngIf="costListGroupFiltered.length">
					<thead>
						<tr>
							<th>Комната</th>
							<th>Тип занятия</th>
							<th>Подтип занятия</th>
							<th style="width: 70px">30 мин</th>
							<th style="width: 70px">45 мин</th>
							<th style="width: 70px">60 мин</th>
							<th style="width: 70px">75 мин</th>
							<th style="width: 70px">90 мин</th>
							<th style="width: 50px"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let cost of costListGroupFiltered; let costIndex = index"
							[class.highlighted]="isSameCost(cost)"
						>
							<td>
								<div *ngIf="!isSameCost(cost)">
									{{roomsKeys[cost.room]?.title}} <br>
									<small class="grey">{{roomsKeys[cost.room]?.type === "L" ? "В здании" : "На выезде"}}</small>
								</div>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.room">
										<option [value]="room.pk" *ngFor="let room of rooms">{{room.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)" [innerHTML]="highlightSearch(occupationTypesKeys[cost.type]?.title, 'type')"></span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.type">
										<option [value]="type.pk" *ngFor="let type of occupationTypes">{{type.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)" [innerHTML]="highlightSearch(occupationSubtypesKeys[cost.subtype]?.title, 'subtype')"></span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<i class="icon icon-drop-down input-icon small"></i>
									<select class="input small" [(ngModel)]="cost.subtype">
										<option [value]="subtype.pk" *ngFor="let subtype of occupationTypesKeys[cost.type].occupationsubtype_set">{{subtype.title}}</option>
									</select>
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[0])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[0], true)" (input)="handleCostPrice($event.target.value, 0)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[1])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[1], true)" (input)="handleCostPrice($event.target.value, 1)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[2])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[2], true)" (input)="handleCostPrice($event.target.value, 2)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[3])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[3], true)" (input)="handleCostPrice($event.target.value, 3)">
								</div>
							</td>
							<td>
								<span *ngIf="!isSameCost(cost)">{{getCost(cost.classes_cost[4])}}</span>
								<div class="form-group collapsed" *ngIf="isSameCost(cost)">
									<span class="input-suffix small">грн</span>
									<input type="text" class="input small" maxlength="3" onlyDigit [value]="getCost(cost.classes_cost[4], true)" (input)="handleCostPrice($event.target.value, 4)">
								</div>
							</td>
							<td>
								<dropdown [btn]="'btn-round btn-block btn-transparent'" [icon]="'dots-vertical'" [hideArrow]="true" class="inline small" data-origin="right" #dropdown>
									<ul class="clear links-list">
										<li *ngIf="!activeCost">
											<div class="link" (click)="edit(cost, costIndex); dropdown.hide()"><i class="icon icon-pencil"></i>Редактировать</div>
										</li>
										<li *ngIf="activeCost">
											<div class="link" (click)="save(); dropdown.hide()"><i class="icon icon-checked primary"></i>Сохранить</div>
										</li>
										<li *ngIf="activeCost">
											<div class="link" (click)="edit(null); dropdown.hide()"><i class="icon icon-undo"></i>Отменить</div>
										</li>
										<li>
											<div class="link" (click)="delete(cost, costIndex); dropdown.hide()"><i class="icon icon-trash"></i>Удалить</div>
										</li>
									</ul>
								</dropdown>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="offset-top">
					<div class="btn-group align-right">
						<button *ngIf="activeCost && activeCost.kind === 'G'" class="btn btn-small btn-primary" (click)="save()">Сохранить</button>
						<button *ngIf="activeCost && activeCost.kind === 'G'" class="btn btn-small" (click)="edit(null)">Отмена</button>

						<form-class-cost-add
							*ngIf="!activeCost && selectedTeachers.length"
							[teachers]="selectedTeachers"
							[rooms]="selectedRooms"
							[roomsKeys]="roomsKeys"
							[types]="occupationTypes"
							[typesKeys]="occupationTypesKeys"
							[kind]="'G'"
							(onCreate)="addNewCost($event)"
						></form-class-cost-add>
					</div>
				</div>

			</div>

		</div>
	</div>

</div>

