<div class="container-fluid">

	<div class="row middle-xs offset-bottom">
		<div class="col-xs-4">
			<div class="h1-title collapsed">Филиалы {{departments.length ? '('+departments.length+')' : ''}}</div>
		</div>
	</div>

	<div>
		<div class="preloader" [class.active]="departmentsLoading"></div>
		<div *ngIf="!departmentsLoading && !departments.length">
			<hr>
			Еще нет филиалов. Нажмите "Создать" чтобы добавить новый филиал
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12 col-sm-6 col-md-4 offset-bottom" *ngFor="let department of departments; let i = index">
			<department
				[editor]="addDepartmentForm"
				[roomEditor]="addRoomForm"
				[data]="department"
				(onDelete)="handleDeleteDepartment($event)"
			></department>
		</div>

	</div>

	<form-room-add
		[hideButton]="true"
		(onSubmit)="handleDepartmentRoom($event)"
		#addRoomForm
	></form-room-add>

	<form-department-add
		(onSubmit)="addDepartmentForm.mode == 'edit' ? handleEditDepartment($event, addDepartmentForm.id) : handleAddDepartment($event)"
		#addDepartmentForm
	></form-department-add>

</div>
