import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview
} from './../../utils';
import { CONFIG } from './../../config';
import { DepartmentsService, NotificatorService } from './../../services';
import { Dialog } from './../../forms/dialog';
import { FormDepartmentAdd, FormRoomAdd } from './../../forms';


@Component({
	selector: 'department',
	templateUrl: './template.html',
	providers: [ DepartmentsService, NotificatorService ]
})
export class Department {

	@Input() data: any;
	@Input() editor: FormDepartmentAdd;
	@Input() roomEditor: FormRoomAdd;
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	dialog: Dialog;

	constructor(
		private departmentsService: DepartmentsService,
		private notificatorService: NotificatorService
	){
		this.dialog = new Dialog();
	}

	delete(){
		this.dialog.show(`Действительно удалить отдел?`, ()=> {
			this.departmentsService.deleteDepartment(this.data.pk).subscribe((res: any)=> {
				this.onDelete.emit(this.data);
			});
		}, 'error');
	}

	edit(){
		if (!this.editor) return;
		let actualData: any = {
			name: this.data.title,
			address: this.data.address.addressLine,
			image: this.data.image
		};
		// phones
		this.editor.contactsGroup.reset();
		this.data.contacts.departmentPhones.map((phone: any)=> {
			this.editor.contactsGroup.add(phone.number);
		});
		// emails
		this.editor.emailsGroup.reset();
		this.data.contacts.departmentEmails.map((email: any)=> {
			this.editor.emailsGroup.add(email.address);
		});
		// days (timetable)
		this.editor.form.removeControl('days');
		let newArrayFields: any[] = [];
		this.data.timetable.timetableHours.map((day: any)=> {
			let subForm: FormGroup = createForm(this.editor.daysConfig);
			setTimeout(()=> {
				subForm.controls['from'].setValue(day.from_hour.substr(0,5));
				subForm.controls['to'].setValue(day.to_hour.substr(0,5));
				subForm.controls['selected'].setValue(day.checked);
			});
			// console.log(subForm);
			newArrayFields[day.weekday-1] = subForm;
		});
		for (let i = 0; i < 7; i++){
			if (!newArrayFields[i]){
				newArrayFields[i] = createForm(this.editor.daysConfig);
			}
		}
		let newArray: FormArray = new FormArray(newArrayFields);
		this.editor.form.addControl('days', newArray);
		setTimeout(()=> {
			this.editor.form.controls['allDaysSame'].setValue(this.data.timetable.uniformWorkingDays);
		});
		// console.log(this.data.timetable.uniformWorkingDays);
		this.editor.patch(actualData);
		this.editor.openWindow(this.data.pk);
	}

	openRoomsModal(){
		if (!this.roomEditor) return;
		this.roomEditor.openWindowWithDepartmentSelected(this.data.pk);
		setTimeout(()=> {
			this.roomEditor.form.controls['department'].setValue(this.data.pk);
		});
	}

	deleteRoom(id: string | number, index: number){
		this.departmentsService.deleteRoom(id.toString()).subscribe((res)=> {
			this.notificatorService.push({
				title: "Rooms",
				text: "Комната удалена"
			});
			this.data.roomSet.splice(index, 1);
		});
	}

	getTime(){
		return new Date(this.data.changed.createdOn).toLocaleString();
	}

}