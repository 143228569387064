import { Component, ViewChild, Input } from '@angular/core';

@Component({
	selector: 'spoiler',
	templateUrl: './template.html',
	host: {

	}
})
export class Spoiler {

	@Input('open') open: boolean = false;
	@Input('hideButton') hideButton: boolean = false;

	show(){
		this.open = true;
	}

	hide(){
		this.open = false;
	}

	toggle(){
		this.open ? this.hide() : this.show();
	}

}