<div class="anchor" (mousedown)="toggle($event)">
	<span class="text" *ngIf="!getSelectedLength()"></span>
	<ul class="clear selected-list" *ngIf="getSelectedLength()">
		<li *ngFor="let option of getSelected()" [class.full-width]="max < 2" [class.large]="size === 'large'">
			<img [src]="option.image" *ngIf="option.image">
			<span>
				{{option.label}}
				<span class="remove" (click)="removeSelected(option)"><i class="icon icon-cross"></i></span>
			</span>
		</li>
	</ul>
</div>
<div class="dropdown-menu">
	<div class="content">
		<div class="btn-wrapper">
			<div class="form-group">
				<i class="icon icon-search input-icon small"></i>
				<input type="text" class="input grey small" *ngIf="search" #searchInput [(ngModel)]="searchRequest">
			</div>
		</div>
		<div class="btn-wrapper" [class.hidden]="sorted.length">
			<div class="alert">Ничего не найдено</div>
		</div>
		<scrollable>
			<ul class="clear options-list">
				<li *ngFor="let option of options | search:searchRequest:'label':setSorted.bind(this)"
					(click)="selectOption(option)"
					[class.active]="hasOption(option)"
				>
					<img [src]="option.image" *ngIf="option.image">
					<span [innerHTML]="higlightSearchRequest(option.label)"></span>
				</li>
			</ul>
		</scrollable>
		<div class="btn-wrapper">
			<div class="row collapsed-row middle-xs">
				<div class="col-xs-6">
					Выбрано: {{getSelectedLength()}}
				</div>
				<div class="col-xs-6 align-right">
					<button type="button" class="btn btn-small" (click)="reset()">Очистить</button>
				</div>
			</div>
		</div>
	</div>
</div>