<div class="verticaly-middle">
	<div class="inner">
		<form class="static-form small" [formGroup]="form" autocomplete="new-password">
			<div class="h1-title align-center">Авторизация</div>
			<div class="form-group"
				*ngFor="let field of formFields"
				[class.error]="ifError(field)"
				[class.success]="ifSuccess(field)"
			>
				<input [attr.type]="fieldType(field)" class="input" [formControlName]="field" [placeholder]="formConfig[field][0]" autocomplete="new-password">
				<i class="icon icon-cross" data-validation></i>
				<i class="icon icon-checked" data-validation></i>
				<div class="tooltip" [class.disabled]="ifSuccess(field)" [innerHTML]="createTooltip(formConfig[field]).parseBytecodes()"></div>
			</div>
			<div class="input-sublink"><a routerLink="/password-reset">Забыли пароль?</a></div>
			<button type="submit" (click)="submit()" class="btn btn-primary btn-block">Войти</button>
			<hr>
			<a routerLink="/registration">Зарегистрироваться</a> если Вы еще не зарегистрированы в системе
		</form>
	</div>
</div>