

export class Dialog {

	private static node: HTMLElement;
	private static acceptBtn: HTMLElement;
	private static rejectBtn: HTMLElement;
	private static messageBlock: HTMLElement;
	private static message: string = "";
	private static type: string = "";
	static instance: Dialog;

	private static template: string = `
		<div class="verticaly-middle">
			<div class="inner">
				<div class="modal-content">
					<div class="message" id="dialogMessage"></div>
					<div class="align-right btn-wrapper">
						<button type="button" class="btn" id="dialogAcceptBtn">Да</button>
						<button type="button" class="btn" id="dialogRejectBtn">Нет</button>
					</div>
				</div>
			</div>
		</div>
	`;

	private callback: Function;

	constructor(){
		if (Dialog.instance) {
			return Dialog.instance;
		}

		Dialog.node = document.createElement('div');
		Dialog.node.classList.add('dialog-window');
		Dialog.node.innerHTML = Dialog.template;
		document.body.appendChild(Dialog.node);
		Dialog.acceptBtn = Dialog.node.querySelector('#dialogAcceptBtn');
		Dialog.rejectBtn = Dialog.node.querySelector('#dialogRejectBtn');
		Dialog.messageBlock = Dialog.node.querySelector('#dialogMessage');

		let $dialog = this;
		Dialog.acceptBtn.addEventListener('click', (e)=> {
			if ($dialog.callback) {
				$dialog.callback();
			}
			$dialog.hide();
		});
		Dialog.rejectBtn.addEventListener('click', (e)=> {
			$dialog.hide();
		});

		Dialog.instance = this;
	}

	show(string: string, callback: Function, type?: string){
		Dialog.message = string;
		Dialog.type = type || "";
		Dialog.instance.callback = callback;
		// console.log(instance);
		Dialog.messageBlock.innerHTML = Dialog.message;
		Dialog.acceptBtn.classList.remove('btn-success', 'btn-danger', 'btn-error');
		Dialog.acceptBtn.classList.add('btn-'+Dialog.type);
		Dialog.node.classList.add('open');
	}

	hide(){
		Dialog.node.classList.remove('open');
		Dialog.messageBlock.innerHTML = "";
	}

}