import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

	transform(array: any[], key: string, ordering: string): any[] {
		let order: number = ordering === "desc" ? -1 : 1;
		if (!array || !key) return array;
		return array.sort((a: any, b: any) => {
			return (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0) * order;
		})
	}

}