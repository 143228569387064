<div class="container-fluid">

	<div class="h1-title">
		<a routerLink="/departments" class="btn btn-transparent btn-small btn-round">
			<i class="icon icon-angle-left"></i>
		</a>
		{{dataLoading ? "Загрузка..." : 'Филиал "'+data.title+'"'}}
	</div>
	<div class="preloader" [class.active]="dataLoading"></div>

	<div *ngIf="data.pk && !dataLoading">
		<div class="offset-bottom">
			{{data.address.addressLine}}
		</div>
		<div class="row offset-bottom">
			<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
				<ul class="clear single-line" *ngIf="data.contacts">
					<li *ngFor="let phone of data.contacts.departmentPhones">
						<i class="icon icon-phone offset-right"></i>
						{{phone.number}}
					</li>
				</ul>
			</div>
			<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
				<ul class="clear single-line" *ngIf="data.contacts">
					<li *ngFor="let email of data.contacts.departmentEmails">
						<i class="icon icon-mail offset-right"></i>
						{{email.address}}
					</li>
				</ul>
			</div>
			<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
				<ul class="clear single-line" *ngIf="data.timetable">
					<li *ngFor="let day of data.timetable.openingHours">
						<i class="icon icon-timer offset-right"></i>
						{{day.from_hour}} - {{day.to_hour}}
					</li>
				</ul>
			</div>
		</div>

		<div class="h2-title">Комнаты</div>
		<div class="relative inline">
			<button type="button" class="btn"
				(click)="addRoom.show()"
			>
				<i class="icon icon-plus small offset-right primary"></i>Добавить комнату
			</button>
			<popover #addRoom [title]="'Создать комнату'" [origin]="'top'">
				<div class="row paddless-row">
					<div class="col-xs-4">
						<figure class="image-cover round smaller">
							<img src="/assets/image.png" alt="">
						</figure>
						<div class="form-group">
							<input type="file" id="roomImage">
							<label for="roomImage" class="align-center">Загрузить фото</label>
						</div>
					</div>
					<div class="col-xs-8">
						<div class="form-group">
							<label for="roomDep">Филиал</label>
							<select name="" id="roomDep" class="input small">
								<option value="">Первый </option>
								<option value="">Второй</option>
								<option value="">Третий</option>
							</select>
						</div>
						<div class="form-group">
							<label for="depAddress">Название</label>
							<input type="text" class="input small" formControlName="address" id="depAddress"
								placeholder="г. Киев, ул. Пеячерская 99"
							>
						</div>
						<hr class="light">
						<button type="button" class="btn btn-transparent btn-small"><i class="icon icon-checked small offset-right success"></i>Применить</button>
						<button type="button" class="btn btn-transparent btn-small" (click)="addRoom.hide()"><i class="icon icon-undo small offset-right"></i>Отмена</button>
					</div>
				</div>
			</popover>
		</div>

	</div>


</div>