import { Component, ViewChild, Input } from '@angular/core';

@Component({
	selector: 'popover',
	templateUrl: './template.html',
	host: {
		"[class.open]": "open",
		"[class.origin-right]": "origin === 'right'",
		"[class.origin-left]": "origin === 'left'",
		"[class.origin-top]": "origin === 'top'",
		"[class.origin-bottom]": "origin === 'bottom'",
		"[attr.did]": "did"
	}
})
export class Popover {

	@Input('open') open: boolean = false;
	@Input('origin') origin: string = "right";
	@Input('title') title: string = "";

	did: number = Math.randomInt(1000000, 9999999);

	static instances: Popover[] = [];

	constructor(){
		Popover.instances.push(this);
	}

	show(){
		this.open = true;
	}

	hide(){
		this.open = false;
	}

	toggle(){
		this.open ? this.hide() : this.show();
	}

	ngOnDestroy(){
		for (let i = 0; i < Popover.instances.length; i++){
			if (this === Popover.instances[i]){
				Popover.instances.splice(i, 1);
				return;
			}
		}
	}

}

document.addEventListener('mousedown', (e: any)=> {
	let closest = e.target.closest('popover');
	for (let i = 0; i < Popover.instances.length; i++){
		if (!closest || closest.getAttribute('did') !== Popover.instances[i].did.toString()){
			Popover.instances[i].hide();
			return;
		}
	}
});