import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'modal',
	templateUrl: './template.html',
	host: {
		"[class.open]": "open",
		"[attr.did]": "did",
		"[class.small]": "small",
		"[class.large]": "large",
		"[class.extra]": "extra"
	}
})
export class Modal {

	@Input('open') open: boolean = false;
	@Input('small') small: boolean = false;
	@Input('large') large: boolean = false;
	@Input('extra') extra: boolean = false;
	@Input('title') title: string = "";
	@Output('onOpen') onOpen: EventEmitter<any> = new EventEmitter();
	@Output('onClose') onClose: EventEmitter<any> = new EventEmitter();

	did: number = Math.randomInt(1000000, 9999999);

	static instances: Modal[] = [];

	constructor(){
		Modal.instances.push(this);
	}

	show(){
		this.open = true;
		this.onOpen.emit(this);
		document.body.classList.add('overlayed');
	}

	hide(){
		this.open = false;
		this.onClose.emit(this);
		document.body.classList.remove('overlayed');
	}

	toggle(){
		this.open ? this.hide() : this.show();
	}

	ngOnDestroy(){
		for (let i = 0; i < Modal.instances.length; i++){
			if (this === Modal.instances[i]){
				Modal.instances.splice(i, 1);
				return;
			}
		}
	}

}

document.addEventListener('mousedown', (e: any)=> {
	let closestModal = e.target.closest('modal');
	let closest = e.target.closest('.modal-content');
	// console.log(closest);
		// console.log(closestModal.getAttribute('did'), Modal.instances)
	for (let i = 0; i < Modal.instances.length; i++){
		if (!closest || !closestModal || (closestModal && closestModal.getAttribute('did') !== Modal.instances[i].did.toString())){
			if (Modal.instances[i].open) Modal.instances[i].hide();
			// return;
		}
	}
});