import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CONFIG } from './../config';
import { Notification, NotificationType } from './../interfaces/notification';

@Injectable()
export class NotificatorService {

	static instance: NotificatorService;
	notifications: ReplaySubject<Notification>;

	constructor(){
		if (NotificatorService.instance){
			return NotificatorService.instance;
		}
		NotificatorService.instance = this;
		this.notifications = new ReplaySubject();

		// setInterval(()=> {
		// 	this.push({
		// 		title: 'sdfasdfasds',
		// 		text: 'sdfasdfasds'
		// 	});
		// }, 1000);
	}
	push(notification: Notification){
		notification.active = true;
		if (!notification.type){
			notification.type = NotificationType.Default;
		}
		this.notifications.next(notification);
		this.pushToStorage(notification);
	}

	pushToStorage(notification: Notification){
		let notifications: any = JSON.parse(localStorage.getItem("LOGOPED_NOTIFICATIONS") || "[]");
		notifications.push(notification);
		localStorage.setItem("LOGOPED_NOTIFICATIONS", JSON.stringify(notifications));
	}

	clearStorage(){
		localStorage.removeItem("LOGOPED_NOTIFICATIONS");
	}

	getFromStorage(){
		return JSON.parse(localStorage.getItem("LOGOPED_NOTIFICATIONS") || "[]");
	}

}