<div class="container-fluid">
	<div class="h1-title">Настройка типа занятий</div>

	<div class="row">
		<div class="col-xs-12 col-sm-6 col-md-4 offset-bottom" *ngFor="let occupationType of occupationTypes; let i = index">
			<occupation-type
				[editor]="formOccupationAdd"
				[data]="occupationType"
				(onDelete)="handleDeleteOccupation($event)"
			></occupation-type>
		</div>

	</div>

	<form-occupation-add #formOccupationAdd
		(onSubmit)="formOccupationAdd.mode == 'edit' ? handleEditOccupation($event, formOccupationAdd.id) : handleAddOccupation($event)"
	></form-occupation-add>

</div>