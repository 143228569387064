<modal [title]="'Редактирование времени занятия'" #modal [extra]="true">

	<div class="row paddless-row bottom-xs">
		<div class="col-xs-2">
			<div class="row paddless-row middle-xs">
				<div class="col-xs-3">
					<button type="button" class="btn btn-round btn-small btn-transparent"
						(click)="calendar.prevDay('start')"
					>
						<i class="icon icon-angle-left small"></i>
					</button>
				</div>
				<div class="col-xs-6">
					<div class="calendar-day">{{getActualDate()}}</div>
					<div class="calendar-day">{{getActualTime()}}</div>
				</div>
				<div class="col-xs-3">
					<button type="button" class="btn btn-round btn-small btn-transparent"
						(click)="calendar.nextDay('start')"
					>
						<i class="icon icon-angle-right small"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-2">
			<label for="durationSelect">Длительность</label>
			<div class="form-group collapsed">
				<select #durationSelect class="input small" id="durationSelect" [(ngModel)]="duration">
					<option [value]="dur.value" *ngFor="let dur of durationOptions">{{dur.label}}</option>
				</select>
				<i class="icon icon-drop-down input-icon small"></i>
			</div>
		</div>
		<div class="col-xs-1 col-lg-2">
			<ul class="clear timeline-demo">
				<li (click)="duration = '30'" [class.active]="duration === '30'">15</li>
				<li (click)="duration = '30'" [class.active]="duration === '30'">30</li>
				<li (click)="duration = '45'" [class.active]="duration === '45'">45</li>
				<li (click)="duration = '60'" [class.active]="duration === '60'">60</li>
				<li (click)="duration = '75'" [class.active]="duration === '75'">75</li>
				<li (click)="duration = '90'" [class.active]="duration === '90'">90</li>
			</ul>
		</div>
		<div class="col-xs-3 col-lg-2">
			<div class="checkbox round inline">
				<input type="checkbox" id="localRooms" [(ngModel)]="filter.local"
					(input)="changeRoomsType()"
				>
				<label for="localRooms">Комнаты для занятий</label>
			</div>
		</div>
		<div class="col-xs-3 col-lg-2">
			<div class="checkbox round inline">
				<input type="checkbox" id="virtulRooms" [(ngModel)]="filter.virtual"
					(input)="changeRoomsType()"
				>
				<label for="virtulRooms">Виртуальные комнаты</label>
			</div>
		</div>
		<div class="col-xs-2 align-right">Занятий: {{occupations.length}}</div>
	</div>

	<br>


	<div class="timeline-table">
		<div class="rooms-col">
			<ul class="clear">
				<li *ngFor="let row of timeline">
					<img [src]="row.room.image || 'assets/image.png'">
				</li>
			</ul>
		</div>
		<div class="timeline-col">
			<ul class="clear timeline-heading">
				<li *ngFor="let heading of timelineHeading">{{heading}}</li>
			</ul>
			<ul class="clear" *ngIf="filteredRooms.length">
				<li *ngFor="let row of timeline; let rowIndex = index" class="table"
					[class.active]="activeRow === rowIndex"
				>
					<div>{{row.room.title}}</div>
					<span>
						<i *ngFor="let cell of row.cells; let cellIndex = index"
							[attr.v]="cell.value"
							[attr.d]="duration"
							(click)="setActualTime(rowIndex, cellIndex)"
						>
							{{cell.label}}
						</i>
					</span>
				</li>
			</ul>
			<div class="alert align-center" *ngIf="!filteredRooms.length">
				Список комнат пуст
			</div>
		</div>
	</div>

</modal>