import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';

import {
	MainHeader, Sidebar, Dropdown, Sortable, Scrollable,
	TagsSelect, Calendar, Notificator, Spoiler, Popover,
	Modal, InputGroup, Timepicker, SvgChart, Schedule,
	Lesson, Range, Tabs
} from './components';

import { VarDirective } from './directives/var';
import { OnlyDigitDirective } from './directives/only-digit';
import { Draggable } from './directives/draggable';
import { Droppable } from './directives/droppable';

import { OrderByPipe } from './pipes/orderby';
import { SearchPipe } from './pipes/search';
import { LessonsFilterPipe } from './pipes/lessons-filter';

import { RouterModule, Routes } from '@angular/router';

import {
	MainPage,
	LoginPage,
	RegistrationPage,
	PasswordPage,
	PasswordChangePage,
	DepartmentsPage,
	DepartmentsDetailPage,
	RoomsPage,
	OccupationsPage,
	OccupationsCostPage,
	SchedulePage,
	ScheduleTeacherPage,
	UsersDetailPage,
	UsersBalancePage,
	UsersInfoPage,
	UsersLessonsPage,
	UsersReviewsPage,
	UsersListPage,
	QuizConstructorPage,
	UserSurveyPage
} from './pages';

import {
	Room, Department, OccupationType, UsersList
} from './elements';

import {
	FormRoomAdd, FormDepartmentAdd, FormOccupationAdd,
	FormClassCostAdd, FormLessonAdd, LessonTimeframe,
	FormQuizAdd, FormRegistration, FormActivityAdd
} from './forms';



const appRoutes: Routes = [
	// { path: 'crisis-center', component: CrisisListComponent },
	// { path: 'hero/:id',      component: HeroDetailComponent },
	// {
	// 	path: 'heroes',
	// 	component: HeroListComponent,
	// 	data: { title: 'Heroes List' }
	// },
	{ path: '', component: MainPage },
	{ path: 'login', component: LoginPage, data: { title: "LOGIN < Logoped" } },
	{ path: 'password-reset', component: PasswordPage, data: { title: "RESET PASSWORD < Logoped" } },
	{ path: 'password-change', component: PasswordChangePage, data: { title: "SET NEW PASSWORD < Logoped" } },
	{ path: 'registration', component: RegistrationPage, data: { title: "REGISTRATION < Logoped" } },
	{ path: 'departments', component: DepartmentsPage, data: { title: "DEPARTMENTS < Logoped" } },
	{ path: 'occupations', component: OccupationsPage, data: { title: "OCCUPATIONS < Logoped" } },
	{ path: 'occupations-cost', component: OccupationsCostPage, data: { title: "OCCUPATIONS COST < Logoped" } },
	{ path: 'rooms', component: RoomsPage, data: { title: "ROOMS < Logoped" } },
	{ path: 'lessons/add', component: FormLessonAdd, data: { title: "NEW LESSON < Logoped" } },
	{ path: 'lessons/edit/:id', component: FormLessonAdd, data: { title: "EDIT LESSON < Logoped" } },
	{ path: 'departments/:id', component: DepartmentsDetailPage, data: { title: "DEPARTMENT < Logoped" } },
	{ path: 'schedule', component: SchedulePage, data: { title: "DAY SCHEDULE < Logoped", type: "day" } },
	{ path: 'schedule/week', component: SchedulePage, data: { title: "WEEK SCHEDULE < Logoped", type: "week" } },
	{ path: 'schedule/month', component: SchedulePage, data: { title: "MONTH SCHEDULE < Logoped", type: "month" } },
	{ path: 'schedule-teacher', component: ScheduleTeacherPage, data: { title: "TEACHERS SCHEDULE < Logoped" } },
	{ path: 'users', redirectTo: "users/clients", pathMatch: "full" },
	{ path: 'users/:group', component: UsersListPage, data: { title: "USERS < Logoped" } },
	{ path: 'users/detail/:id', component: UsersDetailPage, data: { title: "USER DETAIL < Logoped" }, pathMatch: 'prefix', children: [
		{ path: 'info', component: UsersInfoPage, pathMatch: 'prefix' },
		{ path: 'balance', component: UsersBalancePage, pathMatch: 'prefix' },
		{ path: 'reviews', component: UsersReviewsPage, pathMatch: 'prefix' },
		{ path: 'lessons', component: UsersLessonsPage, pathMatch: 'prefix' },
		{ path: '**', redirectTo: 'info' }
	]},
	{ path: 'quiz-constructor', component: QuizConstructorPage },
	{ path: 'survey/:userId', component: UserSurveyPage },
	{ path: '**', component: MainPage }
];


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/http-auth-inerceptor';
import { NoAuthInterceptor } from './services/http-noauth-inerceptor';
/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: NoAuthInterceptor, multi: true },
];

@NgModule({
	declarations: [
		AppComponent,

		// components
		MainHeader,
		Notificator,
		Sidebar,
		Dropdown,
		Sortable,
		Scrollable,
		TagsSelect,
		Calendar,
		Spoiler,
		Popover,
		Modal,
		InputGroup,
		Timepicker,
		SvgChart,
		Schedule,
		Lesson,
		Range,
		Tabs,

		// elements
		Room,
		Department,
		OccupationType,
		UsersList,

		// forms
		FormRoomAdd,
		FormDepartmentAdd,
		FormOccupationAdd,
		FormClassCostAdd,
		FormLessonAdd,
		LessonTimeframe,
		FormQuizAdd,
		FormRegistration,
		FormActivityAdd,

		// pipes
		OrderByPipe,
		SearchPipe,
		LessonsFilterPipe,

		// directives
		VarDirective,
		OnlyDigitDirective,
		Draggable,
		Droppable,

		// pages
		MainPage,
		LoginPage,
		RegistrationPage,
		PasswordPage,
		PasswordChangePage,
		DepartmentsPage,
		DepartmentsDetailPage,
		RoomsPage,
		OccupationsPage,
		OccupationsCostPage,
		SchedulePage,
		ScheduleTeacherPage,
		UsersDetailPage,
		UsersBalancePage,
		UsersInfoPage,
		UsersLessonsPage,
		UsersReviewsPage,
		UsersListPage,
		QuizConstructorPage,
		UserSurveyPage
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot(appRoutes, {useHash: true}),
		HttpClientModule
	],
	providers: [
		Location, {
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},
		httpInterceptorProviders
	],
	bootstrap: [ AppComponent, ]
})
export class AppModule { }
