import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { SettingsService, UsersService, DepartmentsService, OccupationsService, NotificatorService } from './../../services';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, ifSuccess, ifError, createForm
} from './../../utils';
import { Calendar, TagsSelect } from './../../components';
import { LessonTimeframe } from './../lesson-timeframe';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from './../../interfaces';

@Component({
	selector: 'form-quiz-add',
	templateUrl: './template.html',
	providers: [  ]
})
export class FormQuizAdd extends AbstractForm {

	config: any = {
		title: ["Заголовок вопроса", "r", "m|5", "M|100"],
		prefix: ["Префикс", "m|5", "M|100"],
		postfix: ["Постфикс", "m|5", "M|100"],
		type: ["", "r"]
	}

	fields: string[] = ["title", "prefix", "postfix"];
	placeholders: string[] = [
		"Как пациент выговаривает букву Р",
		"Что должна делать бука Р вообще",
		"Для чего нужно знать букву Р"
	];

	variants: any[] = [
		{ label: "Селект", value: "SELECT" },
		{ label: "Чекбокс", value: "CHECKBOX" },
		{ label: "Радио", value: "RADIO" },
		{ label: "Текст", value: "TEXT" }
	];

}