import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormBuilder, FormArray } from '@angular/forms';

export function createConfig(simpleConfig: any, onlyValidators?: boolean){
	let realConfig: any = {};
	for (let key in simpleConfig){
		let arr: any = [];
		// p - parameter
		simpleConfig[key].map((p: any, i: number)=> {
			// isOneOfFilled
			switch (p[0]) {
				// case "": arr.push(p); break;
				case "r": arr.push(Validators.required); break;
				case "p":
					let pattern = new RegExp(p.split('|')[1], "gim");
					arr.push(Validators.pattern(pattern));
					break;
				case "e":
					arr.push(Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
					break;
				case "m":
					let min = parseInt(p.split('|')[1] || 1);
					arr.push(Validators.minLength(min));
					break;
				case "M":
					let max = parseInt(p.split('|')[1] || 50);
					arr.push(Validators.maxLength(max));
					break;
			}
		});
		// arr.validators = simpleConfig[key];
		realConfig[key] = onlyValidators ? arr : ["", arr];
	}
	return realConfig;
}

export function createForm(simpleConfig?: any, validators?: any) {
	let formBuilder: FormBuilder = new FormBuilder();
	let realConfig = createConfig(simpleConfig);
	return validators ? formBuilder.group(realConfig, validators) : formBuilder.group(realConfig);
}

export function fieldType(field: string){
	return field.match(/pass/gim) ? 'password' : field.match(/mail/gim) ? 'email' : 'text';
}

/*
	helper which can help us to emulate form submit
	because we need to check errors on all of the fields
	in form after submitting. By default validation on all
	fields is disabled
*/
export function validateAllFields(formGroup: FormGroup) {
	Object.keys(formGroup.controls).forEach(field => {
		const control = formGroup.get(field);
		if (control instanceof FormControl || control instanceof FormArray) {
			markAllDirty(control);
			// control.markAsTouched({ onlySelf: true });
		} else if (control instanceof FormGroup) {
			validateAllFields(control);
		}
	});
}

/*
	sub helper for dirty setting fields as 'touched'
*/
export function markAllDirty(control: AbstractControl) {
	if(control.hasOwnProperty('controls')) {
		control.markAsDirty({onlySelf: true})
		let ctrl = <any>control;
		for (let inner in ctrl.controls) {
			markAllDirty(ctrl.controls[inner] as AbstractControl);
		}
	}
	else {
		(<FormControl>(control)).markAsDirty({onlySelf: true});
	}
}

/*
	inside of form groups we have .error-message, which shows what
	exaxtly wrong with our data inside field. From Angular's "Validator"
	we can receive a type of error (length, required, etc.), so we can use
	it to handle this things. After we can show user what is wrong
	[null] => is good, no errors
	[errorType] - we have an error, it is string
*/
export function getErrorType(formControl: FormControl | AbstractControl){
	if (formControl.errors){
		for (let key in formControl.errors){
			return key.toString();
		}
	}
	return null;
}

/*
	this is custom validator for Angular's native validator
	This function takes two keys, and see, searches for native
	Angular's control by "key" and see are this two fields equal
*/
export function isEqualsTo(targetKey: string, toMatchKey: string): ValidatorFn {
	return (group: FormGroup): {[key: string]: any} => {
		const target = group.controls[targetKey];
		const toMatch = group.controls[toMatchKey];
		const isMatch = target.value === toMatch.value;
		if (!isMatch && target.valid && toMatch.valid) {
			toMatch.setErrors({equal: targetKey});
			const message = targetKey + ' != ' + toMatchKey;
			return {'equal': message};
		}
		if (isMatch && toMatch.hasError('equal')) {
			toMatch.setErrors(null);
		}
		return null;
	};
}

/*
	we can set a couple of fields that one of those can be filled
	for example, we can choose how to registrate user:
	1) email + password
	2) phone + password
	so one of the fields should be filled to return true
*/
export function isOneOfFilled(fieldA: string, fieldB: string): ValidatorFn {
	return (group: FormGroup): {[key: string]: any} => {
		const controlA: AbstractControl = group.controls[fieldA];
		const controlB: AbstractControl = group.controls[fieldB];
		const isOneOfFilled = controlA.value || controlB.value ? true : false;
		if (!isOneOfFilled && controlA.valid && controlB.valid) {
			controlA.setErrors({equal: fieldB});
			controlB.setErrors({equal: fieldA});
			return {'oneOfFilled': false};
		}
		if (isOneOfFilled) {
			controlA.setErrors(null);
			controlB.setErrors(null);
		}
		return null;
	};
}


export function maxValue(max: number): ValidatorFn {
	return (group: FormGroup): {[key: string]: any} => {
		let value = parseInt(group.value);
		if (value > 99) {
			return { maxlength: 'Too much for it' };
		}
		return null;
	};
}

/*
	Decorators
*/
export function ifError(name: string){
	return (this.controls[name] && this.controls[name].errors && !this.controls[name].pristine);
}

export function ifSuccess(name: string){
	return (this.controls[name] && !this.controls[name].errors && this.controls[name].valid && this.controls[name].value);
}

export function getError(form: string, name: string){
	let errors = this.lang ? this.lang.validationMessages : {
		required: '',
		minlength: '',
		maxlength: '',
		pattern: '',
		equal: ''
	},
	type: string = getErrorType(this[form].controls[name]);
	return errors[type];
}



/* create tooltip */
export function createTooltip(config: any){

	function handlePostfix(digit: number){
		return "символ"+(digit > 1 && digit < 5 ? "а" : "ов");
	}

	let result = `Поле "${config[0]}" `;
	let count = 0;
	for (let i = 1; i < config.length; i++){
		let p = config[i];
		switch (p.replace(/\d|\|/gim, '')) {
			case "r":
				result += `обязательно`;
				count++;
				break;
			case "e":
				result += `${count > 0 ? '[br]' : ''} должно содержать реальный Email`;
				count++;
				break;
			case "m":
				let min = parseInt(p.split('|')[1] || 1);
				result += `${count > 0 ? '[br]' : ''} должно быть длиннее ${min} ${handlePostfix(min)}`;
				count++;
				break;
			case "M":
				let max = parseInt(p.split('|')[1] || 50);
				result += `${count > 0 ? '[br]' : ''} должно быть короче ${max} ${handlePostfix(max)}`;
				count++;
				break;
		}
	}
	return result;
}


export function createErrorText(errors: any){
	let text = "";
	let count = 0;
	for (let key in errors){
		text += (count > 0 ? ",[br]" : '')+errors[key];
		count++;
	}
	return text;
}

export function makeFlatValue(data: any, dataKey: string[]){
	dataKey.map(k => {
		let arr = [];
		for (let key in data[k]){
			arr.push(data[k][key]);
		}
		data[k] = arr;
	});
	return data;
}


export function createCheckboxArray(length: number): FormArray {
	let array: FormControl[] = [];
	for (let i = 0; i < length; i++){
		array.push(new FormControl(false));
	}
	return new FormArray(array);
}

export function atLeastOneChecked(array: FormArray){
	for (let i = 0; i < array.value.length; i++){
		if (array.value[i] === true){
			return null;
		}
	}
	return {notChecked: true};
}