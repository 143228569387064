import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'lessonsFilter',
	pure: false
})

export class LessonsFilterPipe implements PipeTransform {
	transform(items: any[], filter: any): any {
		// console.log(items, filter);
		return items.filter((l: any)=> {
			function forSpecialClient(){
				if (!filter.clients.length) return true;
				let clients = filter.clients.split(',').map(pk => parseInt(pk));
					// console.log(clients);
					for (let i = 0; i < l.clients.length; i++){
						// console.log(l.clients[i].pk);
						if (clients.indexOf(l.clients[i].pk) > -1){
							return true;
						}
					}
				return false;
			}
			// console.log(l.from_hour, parseInt(filter.hourFrom.replace(/\:/gim, '')));
			return (
				l.from_hour >= parseInt(filter.hourFrom.replace(/\:/gim, '')) &&
				l.to_hour <= parseInt(filter.hourTo.replace(/\:/gim, '')) &&
				(filter.type == "0" || l.occupation.occupation_type === parseInt(filter.type)) &&
				forSpecialClient()
			);
		});
	}
}