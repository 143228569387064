import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { CONFIG } from './../config';

@Injectable()
export class OccupationsService {

	subcategories: any = {
		list: "list/",
		scheduled: "scheduled/",
		classTime: "class-time/",
		occupationReport: "occupation-report/",
		reportPerClient: "report-per-client/",
		reportCommonComments: "report-common-comments/",
		reportAdminComments: "report-admin-comments/",
		activity: "activity/"
	}

	debug: boolean = false;

	headers: HttpHeaders = new HttpHeaders();

	constructor(private http: HttpClient){
		this.headers.append('Content-Type', 'application/json');
	}

	// date_after=
	// date_before=
	// exact_date=
	// occupation__performers=3
	// occupation__occupation_type=
	// occupation__occupation_subtype=
	// room=
	// occupation__kind=
	private createFilterString(filter: any, key: string){

		if (!filter || !key) return '';

		let string = "?";
		switch (key){
			case "day":
				var date1 = new Date(filter.dates.split(',')[0]),
						date2 = new Date(filter.dates.split(',')[0]);
				string += `date_after=${date1.parseDate('YYYY-MM-DD')}`;
				string += `&date_before=${date2.parseDate('YYYY-MM-DD')}`;
				break;
			case "week":
				var date1 = new Date(filter.dates.split(',')[0]).getWeekStart(),
						date2 = new Date(filter.dates.split(',')[0]).getWeekEnd();
				string += `date_after=${date1.parseDate('YYYY-MM-DD')}`;
				string += `&date_before=${date2.parseDate('YYYY-MM-DD')}`;
				break;
			case "month":
				var date1 = new Date(filter.dates.split(',')[0]).getMonthStart(),
						date2 = new Date(filter.dates.split(',')[0]).getMonthEnd();
				string += `date_after=${date1.parseDate('YYYY-MM-DD')}`;
				string += `&date_before=${date2.parseDate('YYYY-MM-DD')}`;
				break;
		}

		if (filter.teachers){
			// console.log(filter.teachers);
			filter.teachers.split(',').map(id => {
				string += "&occupation__performers="+id;
			})
		}

		// console.log(filter, string);
		return string;
	}

	getOccupations(filterString?: string){
		return this.http.get(CONFIG.api.occupations+this.subcategories.scheduled+(filterString || ''));
	}

	getOccupationsForDay(filter: any){
		// console.log(day);
		if (this.debug) console.log('Request occupations [day]..');
		return this.getOccupations(this.createFilterString(filter, 'day'));
	}

	getOccupationsForWeek(filter: any){
		if (this.debug) console.log('Request occupations [week]..');
		return this.getOccupations(this.createFilterString(filter, 'week'));
	}

	getOccupationsForMonth(filter: any){
		if (this.debug) console.log('Request occupations [month]..');
		return this.getOccupations(this.createFilterString(filter, 'month'));
	}

	getOccupation(id: string){
		return this.http.get(CONFIG.api.occupations+this.subcategories.scheduled+id+'/');
	}

	createOccupation(data: any, form?: any){
		// console.log(new Date(data.startDate).toLocaleDateString('YYYY-MM-DD'));
		let parse = function(time: number, dur: number): number {
			let m = time % 100,
					d = (m + dur);
			if (d >= 60){
				return parse(Math.floor(time / 100)*100 + (100*(Math.floor(d / 60))), d % 60);
			} else {
				return time + dur;
			}
		};
		let date, d, m, y;
		// if (data.startDate.match(/\//)) {
		//  	date = data.startDate.split('/'),
		//     d = date[1],
		//     m = date[0],
		//     y = date[2];
		// } else {
		//  	date = data.startDate.split('.'),
		//     d = date[0],
		//     m = date[1],
		//     y = date[2];
		// }
		date = new Date(form.controls.startDate.rawDate);
		d = date.getUTCDate();
		m = date.getUTCMonth()+1;
		y = date.getUTCFullYear();
		let 	from = parseInt(data.startTime.replace(/\:/gim, '')),
				to = from,
				dur = parseInt(data.duration);
		let _h = Math.floor(dur / 60)*100,
				_m = dur - Math.floor(dur / 60)*60;
		// console.log(from, dur, parse(from, dur));
		return this.http.post(CONFIG.api.occupations+this.subcategories.list, {
			title: data.title,
			kind: data.lesson,
			cycle: data.loop,
			initial_duration: dur,
			initial_room: parseInt(data.room),
			initial_date: `${y}-${m}-${d}`,
			initial_from_hour: from,
			initial_to_hour: parse(from, dur),
			occupation_type: parseInt(data.type),
			occupation_subtype: parseInt(data.subtype),
			performers: data.teacher.split(',').map(v => parseInt(v)),
			clients: data.clients.split(',').map(v => parseInt(v)),
			classes_number: data.repeats
		});
	}


	updateOccupation(id: any, data: any, form?: any){
		// console.log(new Date(data.startDate).toLocaleDateString('YYYY-MM-DD'));
		let date, d, m, y;
		// if (data.startDate.match(/\//)) {
		//  	date = data.startDate.split('/'),
		//     d = date[1],
		//     m = date[0],
		//     y = date[2];
		// } else {
		//  	date = data.startDate.split('.'),
		//     d = date[0],
		//     m = date[1],
		//     y = date[2];
		// }
		date = new Date(form.controls.startDate.rawDate);
		d = date.getUTCDate();
		m = date.getUTCMonth()+1;
		y = date.getUTCFullYear();


		let from = parseInt(data.startTime.replace(/\:/gim, '')),
				to = from,
				dur = parseInt(data.duration);
		let _h = Math.floor(dur / 60)*100,
				_m = dur - Math.floor(dur / 60)*60;
		// console.log(from, dur, from + _h + _m);
		return this.http.patch(CONFIG.api.occupations+this.subcategories.scheduled+id+"/", {
			title: data.title,
			kind: data.lesson,
			cycle: data.loop,
			duration: dur,
			room: parseInt(data.room),
			date: `${y}-${m}-${d}`,
			from_hour: from,
			to_hour: from + _h + _m,
			occupation_type: parseInt(data.type),
			occupation_subtype: parseInt(data.subtype),
			performers: data.teacher.split(',').map(v => parseInt(v)),
			clients: data.clients.split(',').map(v => parseInt(v))
		});
	}


	/* UTILS */

	filterCompletelyFilledOccupations(occupations: any[]) {
		let result: any[] = [];
		for (let i = 0; i < occupations.length; i++){
			if (
				occupations[i] &&
				occupations[i].date !== (null || undefined) &&
				occupations[i].duration !== (null || undefined) &&
				occupations[i].from_hour !== (null || undefined) &&
				occupations[i].to_hour !== (null || undefined) &&
				occupations[i].room !== (null || undefined) &&
				occupations[i].occupation_type !== (null || undefined) &&
				occupations[i].occupation_subtype !== (null || undefined)
			){
				result.push(occupations[i]);
			}
		}
		return result;
	};

	unwrapOccupations(occupations: any[]): any[] {
		let result: any[] = [];
		occupations.map((O: any)=> {
			result.push({
				...O.occupation,
				date: O.date || O.occupation.initial_date,
				duration: O.duration || O.occupation.initial_duration,
				from_hour: O.from_hour || O.occupation.initial_from_hour,
				to_hour: O.to_hour || O.occupation.initial_to_hour,
				performers: O.performers || O.occupation.performers,
				clients: O.clients || O.occupation.clients,
				room: O.occupation.initial_room,
			});
		});
		// console.log(occupations, result);
		return result;
	};

	removeDoubleOccupations(occupations: any[]): any[] {
		let result: any[] = [],
				found: any = {};
		occupations.map((o: any)=> {
			if (!found[o.room+"_"+o.from_hour]){
				found[o.room+"_"+o.from_hour] = true;
				result.push(o);
			}
		});
		return result;
	}

	getLessonTimeString(lesson: any){
		let z = (v)=> { return v < 10 ? "0"+v : v; };
		let t1 = new Date().getTimeFromLesson(lesson),
				t2 = new Date().getTimeFromLesson(lesson, true);
		return `${z(t1.getHours())}:${z(t1.getMinutes())} - ${z(t2.getHours())}:${z(t2.getMinutes())}`;
	}

	// date: "2020-03-10"
	getLessonTimestamp(lesson: any, key: string){
		let z = (v)=> { return v < 10 ? "0"+v : v; };
		let time = lesson[key];
		let hour = Math.floor(time.toString() / 100),
			minute = parseInt(time.toString().substr(-2));
		return new Date(`${lesson.date}T${z(hour)}:${z(minute)}:00`);
	}

	getLessonDuration(lesson: any) {
		let start = this.getLessonTimestamp(lesson, 'from_hour'),
				end = this.getLessonTimestamp(lesson, 'to_hour');
		return start.differenceInMinutes(end);
	}

	/*
		ACTIVITIES
	*/

	getActivities(){
		return this.http.get(CONFIG.api.occupations+this.subcategories.activity);
	}

	createActivity(data: any){
		return this.http.post(CONFIG.api.occupations+this.subcategories.activity, {
			type: data.type,
			users: data.users.split(","),
			date_start: new Date().fromDateAndHour(data.dateStart, data.hourFrom).toISOString(),
			date_end: new Date().fromDateAndHour(data.dateEnd, data.hourTo).toISOString(),
			comment: data.comment
		});
	}

	updateActivity(data: any, url: string){
		return this.http.patch(url, {
			type: data.type,
			users: data.users.split(","),
			date_start: new Date().fromDateAndHour(data.dateStart, data.hourFrom).toISOString(),
			date_end: new Date().fromDateAndHour(data.dateEnd, data.hourTo).toISOString(),
			comment: data.comment
		});
	}

	deleteActivity(activity: any){
		return this.http.delete(activity.url);
	}

}