import { Component, Output, Input, EventEmitter, Host, HostListener, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'scrollable',
	templateUrl: './template.html',
	host: {
		"[class.has-buttons]": "options.buttons",
		"[class.bbox]": "bbox",
		"[style.height]": "height"
	}
})

export class Scrollable {

	scroll: ScrollDataInterface;
	options: ScrollableOptions;
	host: Element | HTMLElement;
	content: Element | HTMLElement;
	scrollbar: Element | HTMLElement;
	thumbSize: number;
	isDragging: boolean;
	dragStartOffset: {
		top: number;
		left: number;
	};
	thumbTop: number;
	scrollbarTop: number;
	thumbLeft: number;
	scrollbarLeft: number;
	noMorePrev: boolean;
	noMoreNext: boolean;

	animationRunning: boolean = false;
	scrollFrom: number = 0;
	scrollTarget: number = 0;
	animationFrame: number = 0;

	lastScroll: number = 0;

	active: boolean = false;

	@Input("orientation") _orientation: string;
	@Input("buttons") _buttons: boolean;
	@Input("bbox") bbox: boolean;
	@Input("innerSpace") innerSpace: boolean;
	@Input("innerSpaceVertical") innerSpaceVertical: boolean;
	@Input("height") height: string = "200px";
	@Input("origin") origin: string = "right";
	@Input("maxHeight") maxHeight: string = "200px";

	@ViewChild("scrollableContent") scrollableContent: ElementRef;
	@ViewChild("scrollbar") scrollbarElement: ElementRef;

	constructor(host: ElementRef){
		this.scroll = {
			left: 0,
			top: 0,
			maxLeft: 0,
			maxTop: 0
		};
		this.options = {
			orientation: "vertical",
			buttons: false
		};
		this.host = host.nativeElement;
		this.isDragging = false;
		this.dragStartOffset = {
			left: 0, top: 0
		};
		this.thumbTop = 0;
		this.scrollbarTop = 0;
		this.thumbLeft = 0;
		this.scrollbarLeft = 0;
	}

	@HostListener('mousewheel', ['$event'])
	handleMouseWheel(event: MouseWheelEvent){
		if (!this.host) return;
		event.preventDefault();
		this.setRects();
		// this.scroll.top = Math.clamp(this.scroll.top + event.deltaY, 0, this.scroll.maxTop);
		// this.update();
		this.moveBy(event.deltaY*2);
		// console.log(this.scroll)
	}

	ngOnInit(){
		if (this._orientation) this.options.orientation = this._orientation;
		if (this._buttons) this.options.buttons = this._buttons;
	}

	ngAfterContentChecked(){
		this.content = this.scrollableContent.nativeElement;
		if (this.scrollbarElement){
			this.scrollbar = this.scrollbarElement.nativeElement;
		}
		this.setRects();
		this.update();
	}

	setRects(){
		this.scroll.maxTop = this.content.scrollHeight-this.content.clientHeight;
		this.scroll.maxLeft = this.content.scrollWidth-this.content.clientWidth;
		if (this.options.orientation === "vertical"){
			this.thumbSize = this.content.clientHeight / this.content.scrollHeight;
		} else {
			this.thumbSize = this.content.clientWidth / this.content.scrollWidth;
		}
		this.active = this.thumbSize < 1;
	}

	update(){
		if (!this.host) return;
		this.content.scrollTo(this.scroll.left, this.scroll.top);
		this.thumbTop = (
			(this.scroll.top / this.scroll.maxTop) * (100 - this.thumbSize*100)
		);
		this.scrollbarTop = this.scroll.top;
		this.thumbLeft = (
			(this.scroll.left / this.scroll.maxLeft) * (100 - this.thumbSize*100)
		);
		this.scrollbarLeft = this.scroll.left;
		// это на случай паранойи
		if (this.options.orientation === 'vertical'){
			this.noMorePrev = this.thumbTop < 0.001;
			this.noMoreNext = (this.scroll.top / this.scroll.maxTop) > 0.999;
		} else {
			this.noMorePrev = this.thumbLeft < 0.001;
			this.noMoreNext = (this.scroll.left / this.scroll.maxLeft) > 0.999;
		}
	}

	bindDraggind(event: any){
		event.preventDefault();
		this.isDragging = true;
		this.animationFrame = 0;
		this.animationRunning = false;
		let bbox = event.target.getBoundingClientRect();
		this.dragStartOffset.top = event.clientY - bbox.top;
		this.dragStartOffset.left = event.clientX - bbox.left;
	}

	unbindDragging(){
		this.isDragging = false;
	}

	@HostListener('document:mousemove', ['$event'])
	handleMouseMove(event: MouseEvent){
		if (!this.isDragging) return;
		let bbox = this.scrollbar.getBoundingClientRect();
		// let bbox2 = this.scrollbar.getBoundingClientRect();
		let scrollHeght = this.content.scrollHeight;
		let top = Math.clamp(event.clientY - bbox.top - this.dragStartOffset.top, 0, bbox.height);
		let scrollWidth = this.content.scrollWidth;
		let left = Math.clamp(event.clientX - bbox.left - this.dragStartOffset.left, 0, bbox.width);
		this.setRects();
		if (this.options.orientation === "vertical"){
			this.scroll.top = Math.clamp(top / bbox.height * scrollHeght, 0, this.scroll.maxTop);
		} else {
			this.scroll.left = Math.clamp(left / bbox.width * scrollWidth, 0, this.scroll.maxLeft);
		}
		this.update();
	}

	@HostListener('document:mouseleave', ['$event'])
	handleMouseOut(){
		this.unbindDragging();
	}

	@HostListener('document:mouseup', ['$event'])
	handleMouseUp(){
		this.unbindDragging();
	}

	animate(){
		let frames = 10;

		this.scroll.top = Math.lerp(this.scrollFrom, this.scrollTarget, this.animationFrame/frames);
		this.scroll.left = Math.lerp(this.scrollFrom, this.scrollTarget, this.animationFrame/frames);
		this.update();

		this.animationFrame++;
		if (this.animationFrame > frames){
			this.animationRunning = false;
		}
		if (this.animationRunning){
			requestAnimationFrame(this.animate.bind(this));
		}
	}

	moveBy(scroll: number){
		if (this.options.orientation === "vertical"){
			this.scroll.top = Math.clamp(this.scroll.top + scroll, 0, this.scroll.maxTop);
		} else {
			this.scroll.left = Math.clamp(this.scroll.left + scroll, 0, this.scroll.maxTop);
		}
	}

	// moveBy(scroll: number){
	// 	if (this.options.orientation === "vertical"){
	// 		if (!this.animationRunning){
	// 			this.scrollFrom = this.scroll.top;
	// 			this.animationRunning = true;
	// 			this.animationFrame = 0;
	// 			this.animate();
	// 			this.scrollTarget = Math.clamp(this.scroll.top + scroll, 0, this.scroll.maxTop);
	// 		} else {
	// 			this.scrollFrom = this.scrollTarget;
	// 			this.animationFrame = 0;
	// 			this.scrollTarget = Math.clamp(this.scrollTarget + scroll, 0, this.scroll.maxTop);
	// 		}
	// 	} else {
	// 		if (!this.animationRunning){
	// 			this.scrollFrom = this.scroll.left;
	// 			this.animationRunning = true;
	// 			this.animationFrame = 0;
	// 			this.animate();
	// 			this.scrollTarget = Math.clamp(this.scroll.left + scroll, 0, this.scroll.maxLeft);
	// 		} else {
	// 			this.scrollFrom = this.scrollTarget;
	// 			this.animationFrame = 0;
	// 			this.scrollTarget = Math.clamp(this.scrollTarget + scroll, 0, this.scroll.maxLeft);
	// 		}
	// 	}
	// }

	movePrev(){
		this.moveBy(-100);
	}

	moveNext(){
		this.moveBy(100);
	}

	reset(){
		this.active = false;
		setTimeout(()=> {
			this.setRects();
			this.scroll.top = 0;
			this.scroll.left = 0;
			this.update();
			this.active = true;
		}, 210);
	}

}

interface ScrollDataInterface {
	left: number;
	top: number;
	maxTop: number;
	maxLeft: number;
};

export interface ScrollableOptions {
	orientation: string;
	buttons: boolean;
};