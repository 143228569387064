<form class="form" [formGroup]="form">

	<div class="row paddless-row">
		<div class="col-xs-3">
			<!-- type -->
			<label>Тип активности</label>
			<div class="form-group" [class.error]="ifError('type')" [class.success]="ifSuccess('type')">
				<i class="icon icon-drop-down input-icon"></i>
				<select class="input" formControlName="type">
					<option [value]="activity.key" *ngFor="let activity of activityTypes">{{activity.title}}</option>
				</select>
			</div>
		</div>
		<div class="col-xs-9">
			<!-- users -->
			<div class="form-group" [class.error]="ifError('users')" [class.success]="ifSuccess('users')">
				<label for="teacherSelect">Преподаватели</label>
				<i class="icon icon-drop-down input-icon"></i>
				<input type="hidden" class="input" id="teacherSelect" formControlName="users" (focus)="teachersTags.show()">
				<tags-select #teachersSelect [options]="teachersOptions" [size]="'large'" [output]="form.controls['users']" [max]="3" #teachersTags></tags-select>
			</div>
			<div class="alert" *ngIf="!teachersTags.getSelectedLength()">Выберите преподавателей</div>
			<div class="alert success" *ngIf="teachersTags.getSelectedLength()">Выбрано: {{teachersTags.getSelectedLength()}}</div>
		</div>
	</div>

<!-- 	<ul class="clear users-mini-list" *ngIf="teachersTags.getSelectedLength()">
		<li *ngFor="let teacher of getSelectedTeachers(teachersTags.getValues())">
			<img [src]="teacher.image || 'assets/image.png'">
			{{getName(teacher)}}
		</li>
	</ul> -->
	<!-- date -->
	<div class="row paddless-row offset-top offset-bottom">
		<div class="col-xs-3">
			<label class="label">Дата (от/до)</label>
			<div class="form-group collapsed" [class.error]="ifError('dateStart')" [class.success]="ifSuccess('dateStart')">
				<i class="icon icon-calendar input-icon"></i>
				<input type="text" readonly="true" class="input" id="dateStartSelect" formControlName="dateStart" (mousedown)="dateStartDropdown.show()" data-dropdown-toggler>
			</div>
			<!-- calendar -->
			<dropdown [hideButton]="true" #dateStartDropdown>
				<calendar #dayStartCalendar (onchange)="setDate(dayStartCalendar, $event, 'dateStart')"></calendar>
			</dropdown>
		</div>
		<div class="col-xs-3">
			<label class="label">&nbsp;</label>
			<div class="form-group collapsed" [class.error]="ifError('dateEnd')" [class.success]="ifSuccess('dateEnd')">
				<i class="icon icon-calendar input-icon"></i>
				<input type="datetime" readonly="true" class="input" id="dateEndSelect" formControlName="dateEnd" (mousedown)="dateEndDropdown.show()" data-dropdown-toggler>
			</div>
			<!-- calendar -->
			<dropdown [hideButton]="true" #dateEndDropdown>
				<calendar #dayEndCalendar (onchange)="setDate(dayEndCalendar, $event, 'dateEnd')"></calendar>
			</dropdown>
		</div>
		<div class="col-xs-3">
			<div class="row paddless-row">
				<!-- time from -->
				<div class="col-xs-6">
					<div class="form-group collapsed" [class.error]="ifError('hourFrom')" [class.success]="ifSuccess('hourFrom')">
						<label for="timeFrom">Время (от)</label>
						<input type="text" id="timeFrom" class="input paddless" maxlength="5" #hoursFrom formControlName="hourFrom" readonly value="08:00">
						<timepicker [node]="hoursFrom" [control]="form.controls['hourFrom']"></timepicker>
					</div>
				</div>
				<!-- time to -->
				<div class="col-xs-6">
					<div class="form-group collapsed" [class.error]="ifError('hourTo')" [class.success]="ifSuccess('hourTo')">
						<label for="timeTo">Время (до)</label>
						<input type="text" id="timeTo" class="input paddless" maxlength="5" #hoursTo formControlName="hourTo" readonly value="08:00">
						<timepicker [node]="hoursTo" [control]="form.controls['hourTo']"></timepicker>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- comment -->
	<div class="form-group" [class.error]="ifError('comment')" [class.success]="ifSuccess('comment')">
		<label for="lessonComment">Коментарий</label>
		<textarea class="input textarea" id="lessoncomment" formControlName="comment"></textarea>
	</div>
	<hr class="light">
	<!-- buttons -->
	
	<div class="btn-group align-right">
		<button class="btn" (click)="submit()"
			[class.btn-primary]="mode !== 'edit'"
			[class.btn-success]="mode === 'edit'"
		>
			<i class="icon icon-checked offset-right"></i>
			{{mode === "edit" ? "Сохранить" : "Добавить"}}
		</button>
<!-- 		<button class="btn" (click)="reset()">
			<i class="icon icon-trash offset-right"></i>
			Очистить
		</button> -->
	</div>
</form>