<div class="container-fluid">
	<div class="card" style="max-width: 1000px">
		<div class="heading">
			<div class="row">
				<div class="col-xs-4">
					<div class="h2-title collapsed">
						Список {{group === "admins" ? "админов" : group === "teachers" ? "преподавателей" : group === "clients" ? "клиентов" : "пользователей"}}
					</div>
				</div>
			</div>
		</div>
		<div class="body">

			<users-list [group]="group" *ngIf="!loading" #usersList></users-list>

		</div>
	</div>
</div>