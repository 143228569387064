import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef, SimpleChange
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectOption } from './../../interfaces/selectOption';
import { CONFIG } from './../../config';
import { Modal, Calendar, TagsSelect } from './../../components';
import { NotificatorService, OccupationsService } from './../../services';
import { Notification, NotificationType } from './../../interfaces/notification';

@Component({
	selector: 'lesson-timeframe',
	templateUrl: './template.html',
	providers: [ NotificatorService, OccupationsService ]
})
export class LessonTimeframe {

	@Input() rooms: any[] = [];
	@Input() occupations: any[] = [];
	@Input() model: FormGroup;
	@Input() calendar: Calendar;
	@Input() roomsTags: TagsSelect;

	@ViewChild('modal') modal: Modal;

	duration: string = '45';
	time: string = '00:00';
	durationOptions: SelectOption[] = [];

	timelineHeading: string[] = [];
	timeline: any[] = [];

	fromHour: number = 8;
	toHour: number = 21;
	// hours: number = 16;

	roomsIndexes: any = {};

	activeRow: number = -1;
	activeCell: number = -1;
	activeCount: number = 2;

	filter: any = {
		virtual: true,
		local: true
	};

	filteredRooms: any[] = [];

	constructor(
		private notificatorService: NotificatorService,
		private occupationsService: OccupationsService
	){

		this.time = (this.fromHour)+":00";
		for (let i = 0; i < 5; i++){
			this.durationOptions.push({
				value: 30 + 15 * i,
				label: `${30 + 15 * i} минут`,
				selected: false
			});
		}

	}

	ngOnInit(){
		// console.log(this.modal, this.model);

		this.createTimeline();

		// this.modal.show();
	}

	ngOnChanges(change: SimpleChange){
		if (change['rooms'] || change['occupations']){
			this.createTimeline();
		}
		if (change['model']){
			this.model.controls['duration'].valueChanges.subscribe( val => this.duration = val);
		}
	}

	changeRoomsType(){
		setTimeout(()=> {
			this.createTimeline();
		});
	}

	createTimeline(){
		let z = (v)=> { return v < 10 ? "0"+v : v };
		let timeline: any[] = [];
		this.timelineHeading = [];

		// console.log(this.rooms);
		this.filteredRooms = this.rooms.filter((room: any)=> {
			return (this.filter.local && room.type === "L") || (this.filter.virtual && room.type === "V");
		});
		this.filteredRooms.map((room: any, index: number)=> {
			// body
			let row: {room: any, cells: any[]} = {
				room: room,
				cells: []
			};
			this.roomsIndexes[room.pk] = index;
			for (let i = this.fromHour; i < this.toHour+1; i++){
				// headings
				if (index === 0){
					this.timelineHeading.push(z(i)+":00");
				}
				// body
				for (let t = 0; t < 60; t += 15){
					row.cells.push({value: 0, label: z(t).toString()});
				}
			}
			timeline.push(row);
		});

		this.timeline = timeline;
		this.fillExistingLessonsTime();
	}

	show(){
		this.modal.show();
	}

	hide(){
		this.modal.hide();
	}

	getActualDate(): string {
		if (!this.calendar.selection.start) return "";
		let day: Date = this.calendar.selection.start.clone();
		return `${day.getDate()} ${CONFIG.monthsNames[day.getMonth()]}, ${day.getFullYear()}`;
	}
	getActualTime(): string {
		return this.time;
	}

	hightlight(cell: any){
		cell._value = cell.value;
		cell.value = 2;
	}

	unhightlight(cell: any){
		cell.value = cell._value;
	}

	setActualTime(row: number, cell: number){
		let z = (v)=> { return v < 10 ? "0"+v : v };
		let r = this.timeline[row],
				c = r.cells[cell],
				hours = Math.floor(cell / 4) + (this.fromHour),
				minutes = cell % 4 * 15;

		// console.log(this.roomsTags);
		if (this.isCellAvailable(r.cells, cell)){
			this.time = `${z(hours)}:${z(minutes)}`;
			this.model.controls['startTime'].setValue(this.time);
			this.model.controls['duration'].setValue(this.duration);
			this.roomsTags.reset();
			this.roomsTags.selectOption(null, row);
			this.setTimeToCells(row, cell);
			this.notificatorService.push({
				title: "Расписание",
				text: "Время выставлено",
				type: NotificationType.Success
			});
			this.hide();
		} else {
			this.notificatorService.push({
				title: "Расписание",
				text: "Нельзя выбрать время, другое занятие назначено на это время",
				type: NotificationType.Error
			});
		}
	}

	private resetActiveCells(){
		this.activeRow = -1;
		this.activeCell = -1;
		this.activeCount = 2;
	}

	private setTimeToCells(row: number, cell: number){
		if (this.activeRow >= 0){
			this.timeline[this.activeRow].cells.map(c => c.value = c.value === 2 ? 0 : c.value);
		}
		this.resetActiveCells();

		let cells: any[] = this.timeline[row].cells,
				state: boolean = true,
				count: number = parseInt(this.duration) / 15;

		this.activeCount = count;
		this.activeRow = row;
		this.activeCell = cell;

		for (let i = 0; i < count; i++){
			cells[cell+i].value = 2;
		}
	}

	private isCellAvailable(cells: any[], index: number): boolean {
		let state: boolean = true,
				count = parseInt(this.duration) / 15;
		for (let i = 0; i < count; i++){
			if (index+count > cells.length || cells[index+i].value === 1){
				return false;
			}
		}
		return state;
	}

	private fillExistingLessonsTime(){
		if (!this.calendar || !this.calendar.selection.start || !this.rooms.length) return;
		let today = {
			start: this.calendar.selection.start.getDayStart().addHours(this.fromHour),
			end: this.calendar.selection.start.getDayEnd()
		};
		let existingLessons = this.occupations.slice();
		// existingLessons = existingLessons.filter((o: any)=> {
		// 	console.log(o);
		// 	let time = new Date().getTimeFromLesson(o);
		// 	// return time.isGreaterEqual(today.start) && time.isLessEqual(today.end);
		// 	return true;
		// });

		// console.log(this.occupations, existingLessons);
		existingLessons.map((o: any)=> {
			let row = this.roomsIndexes[o.room];
			let time = new Date().getTimeFromLesson(o).addHours(-(this.fromHour));
			let cell = Math.floor(time.getHours()*4) + Math.floor(time.getMinutes()/15);
			let count = Math.floor(o.duration / 15);
			// console.log(row, time, cell, count);
			for (let i = 0; i < count; i++){
				if (this.timeline[row]){
					this.timeline[row].cells[cell+i].value = 1;
				}
			}
			// console.log(this.roomsIndexes[o.room], o)
		});
	}

	setTimeFromLesson(o: any){
		let row = this.roomsIndexes[o.room];
		let time = new Date().getTimeFromLesson(o).addHours(-(this.fromHour));
		let cell = Math.floor(time.getHours()*4) + Math.floor(time.getMinutes()/15);
		let count = Math.floor(o.duration / 15);
		let hours = Math.floor(cell / 4) + (this.fromHour),
				minutes = cell % 4 * 15;
		let z = (v)=> { return v < 10 ? "0"+v : v };
		this.duration = o.duration;
		this.time = `${z(hours)}:${z(minutes)}`;
		this.setTimeToCells(row, cell);
		this.fillExistingLessonsTime();
		this.model.controls['startTime'].setValue(this.time);
		this.model.controls['duration'].setValue(this.duration);
		// console.log(count);
	}

}