<div class="card">
	<div class="heading">
		<div class="table-layout">
			<div class="column" style="width: 70px">
				<figure class="image-cover round">
					<img [src]="data.image || 'assets/image.png'">
				</figure>
			</div>
			<div class="column">
				<a routerLink="/rooms" class="h3-title collapsed">{{data.title}}</a>
				<div class="grey">{{data.address.addressLine}}</div>
			</div>
			<div class="column" style="width: 40px;">
				<dropdown [btn]="'btn-round btn-block btn-transparent'" [icon]="'dots-vertical'" [hideArrow]="true" class="inline small" data-origin="right">
					<ul class="clear links-list">
						<li><div class="link" (click)="edit()"><i class="icon icon-pencil"></i>Редактировать</div></li>
						<li><div class="link" (click)="delete()"><i class="icon icon-trash"></i>Удалить</div></li>
					</ul>
				</dropdown>
			</div>
		</div>
	</div>
	<div class="row paddless-row offset-bottom">
		<div class="col-xs-6">
			<div class="h3-title">Контакты</div>
			<ul class="clear single-line" *ngIf="data.contacts && data.contacts.departmentPhones.length">
				<li *ngFor="let phone of data.contacts.departmentPhones">
					<i class="icon icon-phone offset-right"></i>
					{{phone.number}}
				</li>
			</ul>
			<ul class="clear single-line" *ngIf="data.contacts && data.contacts.departmentEmails.length">
				<li *ngFor="let email of data.contacts.departmentEmails">
					<i class="icon icon-mail offset-right"></i>
					{{email.address}}
				</li>
			</ul>
			<div class="grey" *ngIf="!data.contacts || (!data.contacts.departmentEmails.length && !data.contacts.departmentPhones.length)">
				<small>Контакты не указаны</small>
			</div>
		</div>
		<div class="col-xs-6">
			<div class="h3-title">Время работы</div>
			<ul class="clear single-line" *ngIf="data.timetable.timetableHours && data.timetable.timetableHours.length">
				<li *ngFor="let day of data.timetable.timetableHours">
					<i class="icon icon-timer offset-right"></i>
					{{day.from_hour}} - {{day.to_hour}}
				</li>
			</ul>
			<div class="grey" *ngIf="!data.timetable.timetableHours || !data.timetable.timetableHours.length">
				<small>Время не указано</small>
			</div>
		</div>
	</div>
	<div class="row offset-top larger">
		<div class="col-xs-7">
			<div class="h3-title">Комнаты</div>
		</div>
		<div class="col-xs-5 align-right">
			<button type="button" class="btn btn-small" (click)="openRoomsModal()">
				<i class="icon icon-plus small"></i> Добавить
			</button>
		</div>
	</div>
	<ul class="clear rooms-list" *ngIf="data.roomSet.length">
		<li *ngFor="let room of data.roomSet; let i = index">
			<div class="image-cover">
				<img [src]="room.image || 'assets/image.png'">
			</div>
			<div class="content">
				<div class="title">{{room.title}}</div>
				<small>{{room.type === "L" ? "В здании" : "Виртуальная"}}</small>
			</div>
			<div class="overlay">
				<button type="button" class="btn btn-small" (click)="deleteRoom(room.pk, i)">
					<i class="icon icon-trash small"></i> Удалить
				</button>
			</div>
		</li>
	</ul>
	<div class="alert" *ngIf="!data.roomSet.length">Нет пока что комнат</div>
	<div class="offset-top">
		<small class="grey">Создан: {{getTime()}}</small>
	</div>
</div>