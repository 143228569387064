import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
	selector: 'dropdown',
	templateUrl: './template.html',
	host: {
		"[class.open]": "open",
		"[attr.did]": "did"
	}
})
export class Dropdown {

	@Input('open') open: boolean = false;
	@Input('hideArrow') hideArrow: boolean = false;
	@Input('hideButton') hideButton: boolean = false;

	@Input('text') text: string = "";
	@Input('icon') icon: string = "";
	@Input('btn') btn: string = "";
	@Input('counter') counter: string = "";

	static instances: Dropdown[] = [];
	static hostListener: boolean = false;
	static did: number = 0;
	private did: number = 0;

	constructor(){
		Dropdown.instances.push(this);
		this.did = Dropdown.did;
		Dropdown.did++;
		if (!Dropdown.hostListener){
			document.addEventListener('mousedown', (e: Event | any)=> {
				let closest = e.target.closest('dropdown');
				let input = e.target.closest('input');
				if (input){
					return;
				}
				for (let i = 0; i < Dropdown.instances.length; i++){
					if (!closest || closest.getAttribute('did') !== Dropdown.instances[i].did.toString()){
						Dropdown.instances[i].hide();
					}
				}
			});
			Dropdown.hostListener = true;
		}
	}

	hide(){
		this.open = false;
	}
	show(){
		this.open = true;
	}
	toggle(){
		!this.open ? this.show() : this.hide();
	}

}
