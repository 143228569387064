
		<div class="line" [style.background]="color"></div>
		<div class="fader" [style.background]="color"></div>
		<div class="counter" title="Сколько клиентов: {{data?.clients.length}}">
			<i class="icon icon-user" *ngIf="data?.clients.length <= 1"></i>
			<i class="icon icon-users" *ngIf="data?.clients.length > 1"></i>
			<span>{{data?.clients.length}}</span>
		</div>
		<div class="cycle">
			<i class="icon icon-reload" title="{{cycles[data?.cycle]}}"></i>
		</div>
		<ul class="clear clients" [class.flat]="data?.duration < 45">
			<li *ngFor="let client of data?.clients">{{getClientName(client)}}</li>
		</ul>
		<div class="type" title="{{data.occupation_type.title}}">{{data.occupation_type.title}}</div>
		<div class="time">
			<i class="icon icon-clock"></i> {{getTimeString()}}
		</div>
	