export * from './abstract-form';
export * from './room-add';
export * from './department-add';
export * from './occupation-add';
export * from './class-cost-add';
export * from './lesson-add';
export * from './lesson-timeframe';
export * from './quiz-add';
export * from './registration';
export * from './activity-add';
