import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef, SimpleChange
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from './../../services';


@Component({
	selector: 'users-list',
	templateUrl: './template.html',
	providers: [ UsersService ]
})
export class UsersList {

	// users | teachers | admins
	@Input() group: string = "admins";

	usersRaw: any[] = [];
	users: any[] = [];
	page: number = 0;
	length: number = 10;
	loading: boolean = true;
	public listLength: number = 0;

	constructor(
		private usersService: UsersService,
		private router: Router,
		private route: ActivatedRoute
	){

	}

	ngOnInit(){
		this.loading = true;
		this.fetchUsers();
	}

	private fetchUsers(){
		if (this.group === "admins") {
			this.usersService.getAdmins().subscribe(this.onUsersLoad.bind(this));
		} else if (this.group === "teachers") {
			this.usersService.getTeachers().subscribe(this.onUsersLoad.bind(this));
		} else if (this.group === "clients") {
			this.usersService.getClients().subscribe(this.onUsersLoad.bind(this));
		} else {
			this.usersService.getUsers().subscribe(this.onUsersLoad.bind(this));
		}
	}

	onUsersLoad(users: any[]) {
		this.usersRaw = users;
		this.listLength = this.usersRaw.length;
		this.filterUsers();
		console.log("Users: ", users);
		this.loading = false;
	}

	getDate(string: string){
		return new Date(string).parseDate('dd.mm.yyyy');
	}

	nextPage(){
		if (this.page < this.getPagesLength()-1){
			this.page++;
		}
		this.filterUsers();
	}

	prevPage(){
		if (this.page > 0){
			this.page--;
		}
		this.filterUsers();
	}

	getPagesLength(){
		return Math.ceil(this.usersRaw.length / this.length);
	}

	filterUsers(){
		this.users = this.usersRaw.slice(this.page*this.length, (this.page+1)*this.length);
	}

	goToUser(user: any){
		this.router.navigateByUrl('users/'+user.pk+'/info');
	}

	onLengthChange(){
		this.page = 0;
		setTimeout(()=> {
			this.filterUsers();
		});
	}

}