import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview
} from './../../utils';
import { InputGroup } from './../../components';
import { CONFIG } from './../../config';
import { SettingsService } from './../../services/settings';
import { Dialog } from './../../forms/dialog';
import { FormOccupationAdd } from './../../forms/occupation-add';


@Component({
	selector: 'occupation-type',
	templateUrl: './template.html',
	providers: [ SettingsService ]
})
export class OccupationType {

	subtypesConfig: any = {
		title: ['Подтип занятия', 'r', 'm|5', 'M|50']
	};

	@Input() data: any;
	@Input() editor: FormOccupationAdd;
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();
	@ViewChild('subtypesGroup') subtypesGroup: InputGroup;

	dialog: Dialog;
	editing: boolean = false;

	constructor(private settingsService: SettingsService){
		this.dialog = new Dialog();
	}

	ngOnInit(){
		// if (!this.data.occupationsubtype_set.length) {
		// 	this.editing = true;
		// }
	}

	delete(){
		this.dialog.show(`Действительно удалить тип занятия?`, ()=> {
			this.settingsService.deleteOccupationTypes(this.data.pk).subscribe((res: any)=> {
				this.onDelete.emit(this.data);
			});
		}, 'error');
	}

	edit(){
		if (!this.editor) return;
		this.editor.patch(this.data);
		this.editor.openWindow(this.data.pk);
	}

	handleSubtypesGroup(data: any){
		// console.log(data);
	}

	handleSubtypesGroupDelete(index: number){
		this.settingsService.deleteOccupationSubtype(this.data.occupationsubtype_set[index].pk).subscribe();
		this.data.occupationsubtype_set.splice(index, 1);
	}

	handleSubtypesGroupEdit(data: any){
		if (this.data.occupationsubtype_set[data.index]){
			this.settingsService.updateOccupationSubtype(data.value, this.data.occupationsubtype_set[data.index].pk).subscribe();
			this.data.occupationsubtype_set[data.index].subtitle = data.value;
		} else {
			this.settingsService.createOccupationSubtype(data.value, this.data.pk).subscribe((res)=> {
				this.data.occupationsubtype_set.push(res);
			});
		}
	}

	// submitSubtypes(){
	// 	validateAllFields(this.subtypesGroup.form);
	// 	if (this.subtypesGroup.form.valid){
	// 		for (let key in this.subtypesGroup.form.value){
	// 			let title = this.subtypesGroup.form.value[key];
	// 			this.settingsService.createOccupationSubtype(title, this.data.pk).subscribe((res)=> {
	// 				console.log(res);
	// 				this.data.occupationsubtype_set.push(res);
	// 			});
	// 		}
	// 	}
	// 	this.subtypesGroup.reset();
	// 	this.editing = false;
	// }

	enableEditing(){
		this.editing = true;
		setTimeout(()=> {
			this.subtypesGroup.min = 0;
			this.data.occupationsubtype_set.map((subtype: any)=> {
				this.subtypesGroup.add(subtype.subtitle);
			});
		});
	}


}