import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UsersService } from './../services';

@Component({
	selector: 'users-detail-page',
	templateUrl: './users-detail-page.html',
	providers: [ UsersService ]
})
export class UsersDetailPage {

	user: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private location: Location
	){
		this.usersService.getUser(this.route.params['value'].id).subscribe((user: any)=> {
			this.user = user;
			// console.log("Detail user: ", user);
		});
	}

	goBack(){
		this.location.back();
		// this.router.navigateByUrl('users');
	}

}