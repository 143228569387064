const host = "https://crm-dev.l-v.com.ua:8443/";

export const CONFIG = {
	token: "",
	api: {
		auth: host+"api/authentication/",
		users: host+"api/users/",
		contacts: host+"api/contacts/",
		departments: host+"api/departments/",
		settings: host+"api/settings/",
		occupations: host+"api/occupations/",
		admin: host+"admin/"
	},
	regexp: {
		password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{6,64}$"
	},
	timeouts: {
		notificationActive: 5000
	},
	monthsNamesFull: [
		'Январь','Февраль',
		'Март','Апрель','Май',
		'Июнь','Июль','Август',
		'Сентябрь','Октябрь','Ноябрь',
		'Декабрь'
	],
	monthsNamesFull2: [
		'Января','Февраля',
		'Марта','Апреля','Мая',
		'Июня','Июля','Августа',
		'Сентября','Октября','Ноября',
		'Декабря'
	],
	monthsNames: [
		'Янв','Фев', 'Мар','Апр','Май', 'Июн','Июл','Авг', 'Сен','Окт','Ноя', 'Дек'
	],
	dayNames: [ "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс" ],
	dayNamesFull: [ "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье" ]
}
