<div class="container-fluid">

	<div class="h1-title">Комнаты {{rooms.length ? '('+rooms.length+')' : ''}}</div>

	<div>
		<div class="preloader" [class.active]="roomsLoading"></div>
		<div *ngIf="!roomsLoading && !rooms.length">
			<hr>
			<div class="alert empty">
				Еще нет комнат. Нажмите "Создать" чтобы добавить новую комнату
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12 col-sm-6 col-md-4 offset-bottom" *ngFor="let room of rooms; let i = index">
			<room [data]="room" [editor]="roomAddForm"
				(onDelete)="handleDeleteRoom($event)"
			></room>
		</div>
	</div>

	<form-room-add class="offset-top"
		(onSubmit)="roomAddForm.mode == 'edit' ? handleEditRoom($event, roomAddForm.id) : handleAddRoom($event)"
		#roomAddForm
	></form-room-add>

</div>