import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import {
	createForm, validateAllFields, ifError, ifSuccess,
	createTooltip, isEqualsTo, createErrorText, fieldType,
	makeFlatValue, createCheckboxArray, createConfig,
	toCamelKeys, toDashKeys, renderImagePreview
} from './../../utils';
import { CONFIG } from './../../config';
import { DepartmentsService } from './../../services/departments';
import { Dialog } from './../../forms/dialog';
import { FormRoomAdd } from './../../forms/room-add';


@Component({
	selector: 'room',
	templateUrl: './template.html',
	providers: [ DepartmentsService ]
})
export class Room {

	@Input() data: any;
	@Input() editor: FormRoomAdd;
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	dialog: Dialog;

	constructor(private departmentsService: DepartmentsService){
		this.dialog = new Dialog();
	}

	delete(){
		this.dialog.show(`Действительно удалить комнату?`, ()=> {
			this.departmentsService.deleteRoom(this.data.pk).subscribe((res: any)=> {
				this.onDelete.emit(this.data);
			});
		}, 'error');
	}

	edit(){
		if (!this.editor) return;
		this.editor.patch(this.data);
		this.editor.openWindow(this.data.pk);
	}


}