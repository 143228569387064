import { Component, ViewChild, Input, SimpleChanges } from '@angular/core';
import { getDate } from './../../utils/datetime';

@Component({
	selector: 'sortable',
	templateUrl: './template.html'
})
export class Sortable {

	@Input('data') data: any[] = [];
	@Input('keys') keys: string[] = [];
	@Input('widths') widths: any[] = [];
	@Input('small') small: boolean = false;
	@Input('stripped') stripped: boolean = false;
	@Input('maxHeight') maxHeight: number = 200;

	sorting: Sorting = {
		key: '',
		dir: 'asc'
	};

	getDate: Function = getDate;

	constructor(){

	}

	ngOnChanges(changes: SimpleChanges){
		// console.log(changes);
		if (changes.keys && changes.keys.currentValue.length) {
			this.sorting.key = changes.keys.currentValue[0].key;
		}
	}

	getColWidth(index: number){
		if (!this.widths[index]) return 'auto';
		return this.widths[index]+(typeof this.widths[index] === 'string' ? 'px' : '%');
	}

	sortBy(key: string){
		if (this.sorting.key === key){
			this.reverseSortingDirection();
		} else {
			this.sorting.dir = "asc";
		}
		this.sorting.key = key;
		// console.log(this.sorting);
	}

	reverseSortingDirection(){
		if (this.sorting.dir === "asc"){
			this.sorting.dir = "desc";
		} else if (this.sorting.dir === "desc"){
			this.sorting.dir = "asc";
		}
	}

}

interface Sorting {
	key: string;
	dir: string;
}