<div class="card">
	<div class="heading">
		<div class="table-layout">
			<div class="column">
				<div class="h2-title collapsed">{{data.title}}</div>
			</div>
			<div class="column" style="width: 40px;">
				<dropdown [btn]="'btn-round btn-block btn-transparent'" [icon]="'dots-vertical'" [hideArrow]="true" class="inline small" data-origin="right">
					<ul class="clear links-list">
						<li><div class="link" (click)="edit()"><i class="icon icon-pencil"></i>Редактировать</div></li>
						<li><div class="link" (click)="delete()"><i class="icon icon-trash"></i>Удалить</div></li>
					</ul>
				</dropdown>
			</div>
		</div>
	</div>
	<div class="h3-title">Настройка подтипов к занятию</div>
	<div class="alert" *ngIf="!data.occupationsubtype_set.length && !editing">Пока что нет подтипов</div>
	<ul *ngIf="data.occupationsubtype_set.length && !editing" class="clear single-line">
		<li *ngFor="let subtype of data.occupationsubtype_set; let i = index">
			<i class="icon icon-users offset-right"></i>
			{{subtype.subtitle}}
		</li>
	</ul>
	<input-group *ngIf="editing"
		#subtypesGroup
		[config]="subtypesConfig"
		[max]="5"
		[min]="data.occupationsubtype_set.length ? 0 : 1"
		(onChange)="handleSubtypesGroup($event)"
		(onDelete)="handleSubtypesGroupDelete($event)"
		(onChangeSpecial)="handleSubtypesGroupEdit($event)"
		[placeholder]="'Название'"
	></input-group>
	<div class="btn-group offset-top">
		<button type="button" class="btn btn-small" (click)="enableEditing()"  *ngIf="!editing">Изменить</button>
		<button type="button" class="btn btn-small btn-primary" (click)="editing = false" *ngIf="editing">Ок</button>
		<!-- <button type="button" class="btn btn-small btn-primary" (click)="submitSubtypes()" *ngIf="editing">Изменить</button> -->
		<!-- <button type="button" class="btn btn-small" (click)="editing = false" *ngIf="editing">Отмена</button> -->
	</div>

</div>