import { Component } from '@angular/core';
import { CONFIG } from './../config';
import { SettingsService, NotificatorService } from './../services';
import { NotificationType } from './../interfaces';
import { Modal } from './../components';

@Component({
	selector: 'occupations-page',
	templateUrl: './occupations-page.html',
	providers: [  NotificatorService, SettingsService ]
})
export class OccupationsPage {

	occupationTypes: any[]  = [];

	constructor(
		private settingsService: SettingsService,
		private notificatorService: NotificatorService
	){

		this.fetchOccupations();

	}

	fetchOccupations(){
		this.settingsService.getOccupationTypes().subscribe((res: any)=> {
			this.occupationTypes = res.sort((a: any, b: any)=> {
				return new Date(b.changed.createdOn).getTime() - new Date(a.changed.createdOn).getTime();
			});
			console.log(this.occupationTypes);
		});
	}

	handleAddOccupation(data: any){
		this.settingsService.createOccupationType(data).subscribe((res: any)=> {
			this.occupationTypes.unshift(res);
			console.log(res);
		});
	}

	handleDeleteOccupation(data: any){
		this.notificatorService.push({
			title: "Occupation Types",
			text: `Тип занятия ${data.title} удален`,
			type: NotificationType.Error
		});
		this.fetchOccupations();
	}

	handleEditOccupation(data: any, id: number){
		this.settingsService.updateOccupationType(data, id).subscribe((res: any)=> {
			this.notificatorService.push({
				title: "Occupation Types",
				text: `Тип занятия ${data.title} обновлен`
			});
			this.fetchOccupations();
		});
	}

}
