<div class="container-fluid">

	<form [formGroup]="filter" (onreset)="onFilterReset()">
		<div class="row">
			<div class="col-xs-3 col-lg-3">
				<input type="hidden" formControlName="dates">
				<calendar class="small" [class.readonly]="type !== 'day'" #calendar (onchange)="onDayChange($event)" [months]="4" [showDay]="true"></calendar>
			</div>
			<div class="col-xs-7">
				<div class="card">
					<div class="row collapsed-row">
						<div class="col-xs-6">
							<div class="h3-title collapsed">Фильтр занятий</div>
						</div>
						<div class="col-xs-6 align-right">
							<!-- <button type="button" class="btn btn-transparent" (click)="resetFilters()">Сбросить</button> -->
						</div>
					</div>
					<hr class="light">
					<div class="row paddless-row bottom-xs">
						<div class="col-xs-3">
							<div class="form-group collapsed">
								<label for="filterOccupationType">Тип занятия</label>
								<i class="icon icon-drop-down input-icon"></i>
								<select id="filterOccupationType" class="input" formControlName="type">
									<option value="0" selected>Все</option>
									<option *ngFor="let type of types" [value]="type.pk">{{type.title}}</option>
								</select>
							</div>
						</div>
						<div class="col-xs-1">
							<div class="form-group collapsed">
								<label for="filterOccupationTimeFrom">Время (от)</label>
								<input type="text" id="filterOccupationTimeFrom" class="input paddless" maxlength="5" #hoursFrom formControlName="hourFrom" readonly value="08:00">
								<timepicker [node]="hoursFrom" [control]="filter.controls['hourFrom']"></timepicker>
							</div>
						</div>
						<div class="col-xs-1">
							<div class="form-group collapsed">
								<label for="filterOccupationTimeTo">Время (до)</label>
								<input type="text" id="filterOccupationTimeTo" class="input paddless" maxlength="5" #hoursTo formControlName="hourTo" readonly value="21:00">
								<timepicker [node]="hoursTo" [control]="filter.controls['hourTo']"></timepicker>
							</div>
						</div>
						<div class="col-xs-7">
							<div class="form-group collapsed">
								<label>Клиенты</label>
								<input type="hidden" formControlName="clients">
								<tags-select [options]="clientsOptions" [output]="filter.controls['clients']"></tags-select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xs-2">
				<div class="checkbox round">
					<input type="checkbox" id="filterLocalRooms" formControlName="realRooms"
						(change)="filter.controls['realRooms'].setValue($event.target.checked)"
					>
					<label for="filterLocalRooms">Комнаты для занятий</label>
				</div>
				<div class="checkbox round offset-top">
					<input type="checkbox" id="filterVirtualRooms" formControlName="virtualRooms"
						(change)="filter.controls['virtualRooms'].setValue($event.target.checked)"
					>
					<label for="filterVirtualRooms">Виртуальные комнаты</label>
				</div>
			</div>
		</div>
	</form>

	<div class="row middle-xs offset-bottom offset-top">
		<div class="col-xs-2">
			<div class="h2-title collapsed">Расписание</div>
		</div>
		<div class="col-xs-2">
			<div class="row collapsed-row middle-xs">
				<div class="col-xs-2">
					<button type="button" class="btn small btn-transparent btn-round" (click)="prevSchedule()">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="col-xs-8" class="schedule-title" [innerHTML]="getCalendarTitle()"></div>
				<div class="col-xs-2 align-right">
					<button type="button" class="btn small btn-transparent btn-round" (click)="nextSchedule()">
						<i class="icon icon-arrow-right"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="col-xs-6">
			<ul class="clear labels-list">
				<li><i class="icon icon-user"></i>Индивидуальное</li>
				<li><i class="icon icon-users blue"></i>Групповое</li>
				<li><i class="icon icon-arrows purple"></i>Замена</li>
				<li><i class="icon icon-reload green"></i>Циклическое</li>
				<li><i class="icon icon-dollar red"></i>Должник</li>
			</ul>
		</div>
		<div class="col-xs-2 align-right">
			<dropdown [text]="'Расписание на '+(type === 'month' ? 'месяц' : type === 'week' ? 'неделю' : 'день')+' '" [btn]="'btn-transparent'" class="fit inline">
				<ul class="clear links-list">
					<li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule">День</a></li>
					<li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule/week">Неделю</a></li>
					<li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/schedule/month">Месяц</a></li>
				</ul>
			</dropdown>
		</div>
	</div>
	<schedule #schedule [dates]="dates"></schedule>
</div>