import {
	Component, ViewChild, ViewChildren, Input,
	Output, EventEmitter, ElementRef
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AbstractForm, AbstractFormModes } from './../abstract-form';
import { OccupationsService, NotificatorService, UsersService } from './../../services';
import {
	makeFlatValue, toCamelKeys, toDashKeys, renderImagePreview,
	validateAllFields, ifSuccess, ifError, createForm
} from './../../utils';
import { Calendar, TagsSelect } from './../../components';
import { LessonTimeframe } from './../lesson-timeframe';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from './../../interfaces';

import { CONFIG } from './../../config';


@Component({
	selector: 'form-activity-add',
	templateUrl: './template.html',
	providers: [ OccupationsService, NotificatorService, UsersService ]
})
export class FormActivityAdd extends AbstractForm {

	config: any = {
		type: ['', 'r'],
		users: ['', 'r'],
		dateStart: ['', 'r'],
		dateEnd: ['', 'r'],
		hourTo: ['', 'r'],
		hourFrom: ['', 'r'],
		comment: ['', 'r']
	}

	form: FormGroup;

	teachers: any[] = [];
	teachersKeys: any = {};
	occupations: any[] = [];
	occupationsKeys: any = {};
	teachersOptions: any[] = [];

	clients: any[] = [];
	clientsOptions: any[] = [];
	clientsKeys: any = {};

	activityTypes: any[] = [
		{ key: 'meeting', title: 'Совещание'},
		// { key: 'teacher_council', title: 'Занятие'},
		{ key: 'dinner', title: 'Обед'},
		{ key: 'sick', title: 'Больничный'},
		{ key: 'business_trip', title: 'Командировка'},
		{ key: 'vacation', title: 'Отпуск'},
		{ key: 'day_off', title: 'Выходной'}
	];

	// data = {
	// 	        "type": "meeting",
 //        "users": [
 //            2
 //        ],
 //        "date_start": "2020-03-18T12:00:00Z",
 //        "date_end": "2020-03-18T13:00:00Z",
 //        "duration": "60",
 //        "comment": "Test"
	// }


	@ViewChild('dayStartCalendar') dayStartCalendar: Calendar;
	@ViewChild('dayEndCalendar') dayEndCalendar: Calendar;
	@ViewChild('teachersSelect') teachersSelect: TagsSelect;


	constructor(
		private occupationsService: OccupationsService,
		private notificatorService: NotificatorService,
		private usersService: UsersService,
	){
		super();

		this.usersService.getUsers().subscribe((res: any[])=> {
			// console.log('users', res);
			this.teachers = res.filter(u => u.role === "TEACHER");
			this.teachersOptions = this.teachers.map((t: any)=> {
				this.teachersKeys[t.pk] = t;
				return { value: t.pk, label: this.getName(t), image: t.image || 'assets/image.png' }
			});
			this.clients = res.filter(u => u.role === "CLIENT");
			this.clientsOptions = this.clients.map((c: any)=> {
				this.clientsKeys[c.pk] = c;
				return { value: c.pk, label: this.getName(c), image: c.image || 'assets/image.png' }
			});
		});
	}

	setDate(calendar: Calendar, event: Date, key: string){
		if (calendar.selection.start){
			this.form.controls[key].setValue(calendar.selection.start.toLocaleDateString());
		} else {
			this.form.controls[key].setValue("");
		}
	}

	reset(){
		this.form.reset();
		this.teachersSelect.reset();
		this.dayStartCalendar.reset();
		this.dayEndCalendar.reset();
		this.form.controls["hourFrom"].setValue("");
		this.form.controls["hourTo"].setValue("");
	}

	patch(activity: any, id?: string | number){
		this.reset();

		console.log(activity);

		this.id = id;
		["type", "comment"].map((key)=> {
			if (activity.data[key] && this.form.controls[key]){
				this.form.controls[key].setValue(activity.data[key]);
				this.form.controls[key].markAsUntouched();
			}
		});
		activity.data.users.map(id => this.teachersSelect.selectOptionByValue(id));
		this.dayStartCalendar.setDay(new Date(activity.data.date_start));
		this.dayEndCalendar.setDay(new Date(activity.data.date_end));

		this.form.controls["hourFrom"].setValue(new Date(activity.data.date_start).parseDate("h:m"));
		this.form.controls["hourTo"].setValue(new Date(activity.data.date_end).parseDate("h:m"));

	}


	getName(teacher: any): string {
		return teacher.first_name && teacher.last_name ? `${teacher.first_name} ${teacher.last_name}` : teacher.email;
	}

	getSelectedTeachers(ids: any[]){
		let result = [];
		ids.map((id: any)=> {
			if (this.teachersKeys[id]){
				result.push(this.teachersKeys[id]);
			}
		});
		return result;
	}

}